import React from 'react'
import { Paper } from '@material-ui/core'

function PanelError(props) {

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------

        <Paper className="error">
            <label>Error retrieving data </label>
            <span>Please try again or contact system administrator.</span>
        </Paper>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default PanelError