import React from 'react'
import { Card, CardContent, Grid, TextField, Button } from '@material-ui/core'

// Font Awesome imports.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far as FontAwesomeRegular } from '@fortawesome/pro-regular-svg-icons'
import { fal as FontAwesomeLight } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)
FontAwesomeLibrary.add(FontAwesomeLight)

function SearchPanel({
    placeholderText,
    searchText,
    setSearchText,
    onApplyClicked = (event) => { /* Do nothing */ }
}) {

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Card>
            <CardContent>

                <Grid container spacing={1} alignItems='center' wrap='nowrap'>
                    <Grid item xs={10}>
                        <TextField variant='outlined'
                            style={{ width: '100%' }}
                            inputProps={{
                                style: {
                                    background: '#5E81F405',
                                    border: '#EEEEEE',
                                    padding: '0.5em',
                                    height: '1em'
                                }
                            }}
                            placeholder={placeholderText}
                            value={searchText}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    onApplyClicked()
                                }
                              }}
                            onChange={(event) => {
                                let value = event.target.value
                                setSearchText(value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant='contained' color='primary'
                            style={{
                                minWidth: '2em',
                                width: '2em',
                                height: '2em',
                                fontSize: '1rem',
                            }}
                            onClick={onApplyClicked}
                        >
                            <FontAwesomeIcon icon={['fal', 'search']} />
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default SearchPanel
