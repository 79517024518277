import React, { useState } from 'react'
import { Typography, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import clsx from "clsx"
import pathMap from 'src/paths/PathMap_SCO_RateMgmt'
import Utils from './Utils'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import UpdateContractStatusDialog from './UpdateContractStatusDialog'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fal as FontAwesomeRegular } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)

const {
  table: { useDefaultNotification }
} = components

const menuStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiMenu': {
      '&-paper': {
        borderRadius: 10,
        // To adjust Menu Items show below More Button
        marginTop: 35,
        marginLeft: '-115px'
      },
      '&-list': {
        maxWidth: '60vw',
        padding: 2,
        '& .MuiListItem-root:not(.Mui-disabled)': {
          letterSpacing: 0.21,
          fontSize: '.7em',
          fontWeight: '600',
          padding: '8px 10px',
          minWidth: '13em',
          '&:hover': {
            backgroundColor:
              theme.palette.component.popoverMenu.listItemHoverFill
          }
        }
      }
    },
    '& .ng-mattbl-popover-checkbox': {
      padding: 0,
      paddingRight: theme.spacing(1)
    },
    '& .ng-mattbl-popover-title': {
      backgroundColor:
        theme.palette.component.textFormControl.dropdownMenuSelectedFill,
      borderRadius: 8,
      fontWeight: 900,
      color: theme.palette.text.navActive,
      padding: '10px 8px',
      marginBottom: theme.spacing(0.5)
    }
  },
  button_bg: {
    background: '#8996AF10',
    borderRadius: '20%',
    padding: '9px 9px',
    margin: '0',
    marginRight: '4px',
    '& .MuiSvgIcon-root': {
      fontSize: '15px'
    }

  }
}))

function ListActionMenu(props) {

  const menuClasses = menuStyles()

  const history = useHistory()

  const { details, showNotification, refreshList, selectedTab } = props

  let action = details.status ? Utils.getStatusByName(details.status).actions : []
  if(action.length>0){
    //if selectedTab != allowedTab, should not display action
    if(!Utils.getStatusByName(details.status).actionAllowedTab.includes(selectedTab)){
      action = [];
    }
  }

  const { createRecord } = useServices()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmAction, setConfirmAction] = useState(Utils.Action.delete) //need to initiable a value

  const [statusDialogOpen, setStatusDialogOpen] = useState(false)
  const [statusAction, setStatusAction] = useState(Utils.Action.issue) //need to initiable a value

  //menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const ConfirmMessage = [
    {
      action: Utils.Action.delete,
      title: "Do you want to delete this item?",
      content: "Items that you delete can't be restored.",
      okMsg: "Yes, delete",
      cancelMsg: "No, take me back"
    }
  ]

  const StatusTitle = [
    {
      action: Utils.Action.edit,
      title: "Edit",
      label: "Edit",
      iconName: "fa-pen-to-square",
      successNotification: "Go to Edit page"
    },
    {
      action: Utils.Action.delete,
      title: "Delete",
      label: "Delete",
      iconName: "fa-trash",
      successNotification: "Item deleted permanently."
    },
    {
      action: Utils.Action.issue,
      title: "Issue contract",
      label: "Issue",
      iconName: "fa-arrow-right-from-bracket",
      successNotification: "Item issued."
    },
    {
      action: Utils.Action.activate,
      title: "Activate contract",
      label: "Activate",
      iconName: "fa-circle-check",
      successNotification: "Item activated."
    },
    {
      action: Utils.Action.negotiate,
      title: "Request negotiate",
      label: "Negotiate",
      iconName: "fa-arrows-rotate",
      successNotification: "Item send for negotiation."
    },
    {
      action: Utils.Action.cancel,
      title: "Cancel contract",
      label: "Cancel",
      iconName: "fa-ban",
      successNotification: "Item cancelled."
    },
    {
      action: Utils.Action.requestRevocation,
      title: "Request revocation",
      label: "Request revocation",
      iconName: "fa-reply-clock",
      successNotification: "Item send for revocation."
    },
    {
      action: Utils.Action.accept,
      title: "Accept revocation",
      label: "Accept",
      iconName: "fa-circle-check",
      successNotification: "Item revocation accepted."
    },
    {
      action: Utils.Action.reject,
      title: "Reject revocation",
      label: "Reject",
      iconName: "fa-circle-xmark",
      successNotification: "Item revocation rejected."
    },
  ]

  function getDialogMessageByAction(actionVal) {
    return ConfirmMessage.find((b) => b.action == actionVal)
  }

  function getDetailsByAction(actionVal) {
    return StatusTitle.find((b) => b.action == actionVal)
  }

  const deleteRecord = () => {
    
    setConfirmAction(Utils.Action.delete)
    setConfirmDialogOpen(true)
  }

  const confirmDialog = () => {
    
    setConfirmDialogOpen(false) //close the dialog first
    proceedDelete()
  }

  const proceedDelete = () => {
    
    function onSuccess(response) {
      
      if (response.errorMessages != null && response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(getDetailsByAction(Utils.Action.delete).successNotification)
        refreshList()
      }
    }

    createRecord.execute(
      SCORateMgmtApiUrls.HDR_DELETE_CONTRACT,
      { contractId: details.id },
      onSuccess,
      onError
    )
  }

  const updateRecordStatus = (actionVal) => {
    
    setStatusAction(actionVal)
    setStatusDialogOpen(true)
  }

  const confirmUpdateStatusDialog = (confirmActionVal, reason, remarks) => {
    setStatusDialogOpen(false) //close the dialog first
    proceedUpdateStatus(confirmActionVal, reason, remarks)
  }

  const proceedUpdateStatus = (confirmActionVal, reason, remarks) => {
    
    function onSuccess(response) {

      if (response.errorMessages != null && response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(getDetailsByAction(confirmActionVal).successNotification)
        refreshList()
      }
    }

    createRecord.execute(
      SCORateMgmtApiUrls.HDR_UPDATE_CONTRACT_STATUS,
      {
        contractId: details.id,
        action: confirmActionVal,
        reasonId: reason,
        remarks: remarks,
        selectedTab: selectedTab
      },
      onSuccess,
      onError
    )
  }

  const gotoEdit = () => {
    const editUrl = pathMap.SCO_RATE_CONTRACT + "/edit/" + details.id;
    history.push(editUrl);

  }

  function onError(error) {
    console.log('connection-request error', error.message)
    showErrorNotification(error.message)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, actionVal) => {
    
    if (actionVal == Utils.Action.delete) {
      deleteRecord();
    } else if (actionVal == Utils.Action.edit) {
      gotoEdit();
    } else {
      updateRecordStatus(actionVal)
    }
    setAnchorEl(null);
  };

  return (
    <div>
      {action.length > 0 ?
        <IconButton
          id="basic-button"
          className={menuClasses.button_bg}
          onClick={handleClick}
        >
          <MoreIcon />
        </IconButton>
        :
        <></>
      }
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        className={clsx(menuClasses.menu)}
      >
        {action.map((actionObj) => (
          <MenuItem key={actionObj} onClick={(event) => handleMenuItemClick(event, actionObj)}>
            <Typography variant="inherit"><FontAwesomeIcon icon={['fal', getDetailsByAction(actionObj).iconName]} /> {getDetailsByAction(actionObj).label}</Typography>
          </MenuItem>
        ))}
      </Menu>

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={confirmDialog}
        content={getDialogMessageByAction(confirmAction).content}
        okMsg={getDialogMessageByAction(confirmAction).okMsg}
        cancelMsg={getDialogMessageByAction(confirmAction).cancelMsg}
        title={getDialogMessageByAction(confirmAction).title}
      />
      <UpdateContractStatusDialog
        isDialogOpen={statusDialogOpen}
        closeDialog={() => setStatusDialogOpen(false)}
        dialogTitle={getDetailsByAction(statusAction).title}
        proceedAction={confirmUpdateStatusDialog}
        statusAction={statusAction}
      />

    </div>
  )
}

export default ListActionMenu
