import { makeStyles, Menu, MenuItem, IconButton, ListItemIcon, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from 'react'
import clsx from "clsx"
import TranslationText from '../TranslationText'

const toolbarStyles = makeStyles((theme) => ({
    menu: {
        '& .MuiMenu': {
            '&-paper': { borderRadius: 10 },
            '&-list': {
                maxWidth: '60vw',
                padding: 2,
                '& .MuiListItem-root:not(.Mui-disabled)': {
                    letterSpacing: 0.21,
                    fontSize: '.9em',
                    padding: '8px 10px',
                    minWidth: '12em',
                    '&:hover': {
                        backgroundColor:
                            theme.palette.component.popoverMenu.listItemHoverFill
                    }
                }
            }
        },
        '& .ng-mattbl-popover-checkbox': {
            padding: 0,
            paddingRight: theme.spacing(1)
        },
        '& .ng-mattbl-popover-title': {
            backgroundColor:
                theme.palette.component.textFormControl.dropdownMenuSelectedFill,
            borderRadius: 8,
            fontWeight: 900,
            color: theme.palette.text.navActive,
            padding: '10px 8px',
            marginBottom: theme.spacing(0.5)
        }
    }
}))

function SortDropdown(props) {
    const translatedTextObject = TranslationText()
    const toolbarClasses = toolbarStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedSortType, setSelectedSortType] = React.useState(props.sortType ? props.sortType : 0);
    const options = [
        {
            name: translatedTextObject.sortUpdatedDateDesc,
            value: 'UPDATED_DATE_DESC'
        },
        {
            name: translatedTextObject.sortUpdatedDateAsc,
            value: 'UPDATED_DATE_ASC'
        },
        {
            name: translatedTextObject.sortValidFromDesc,
            value: 'VALID_FROM_DESC'
        },
        {
            name: translatedTextObject.sortValidFromAsc,
            value: 'VALID_FROM_ASC'
        },
        {
            name: translatedTextObject.sortValidUntilDesc,
            value: 'VALID_UNTIL_DESC'
        },
        {
            name: translatedTextObject.sortValidUntilAsc,
            value: 'VALID_UNTIL_ASC'
        },
        {
            name: translatedTextObject.sortIssuerAsc,
            value: 'ISSUER_ASC'
        },
        {
            name: translatedTextObject.sortIssuerDesc,
            value: 'ISSUER_DESC'
        },
        {
            name: translatedTextObject.sortCustomerAsc,
            value: 'CUSTOMER_ASC'
        },
        {
            name: translatedTextObject.sortCustomerDesc,
            value: 'CUSTOMER_DESC'
        },
        {
            name: translatedTextObject.sortReasonAsc,
            value: 'REASON_ASC'
        },
        {
            name: translatedTextObject.sortReasonDesc,
            value: 'REASON_DESC'
        },
        {
            name: translatedTextObject.sortRemarksAsc,
            value: 'REMARKS_ASC'
        },
        {
            name: translatedTextObject.sortRemarksDesc,
            value: 'REMARKS_DESC'
        },
    ];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (event, index) => {
        console.log(event, index)
        setSelectedSortType(index);
        setAnchorEl(null);
        props.setSortType(index);
    };

    return (
        <>
            <IconButton
                id="basic-button"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon={['fal', 'sort-alt']} size="xs" />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                className={clsx(toolbarClasses.menu)}
            >
                <MenuItem key={'SortBy'} className={clsx('ng-mattbl-popover-title')} disabled={true}>
                    {'Sort By'}
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.value} onClick={(event) => handleMenuItemClick(event, option.value)}>
                        <ListItemIcon>
                            {option.value === selectedSortType && <FontAwesomeIcon icon={['fas', 'check']} />}
                        </ListItemIcon>
                        <Typography variant="inherit">{option.name}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

export default SortDropdown