
import React from 'react'
import ContractDetailsComponents from './ContractDetailsComponents'
import ContractDocumentComponent from './ContractDocumentComponent'
import { components } from 'cng-web-lib'

// Moment library
import Moment from 'moment';
import 'moment-timezone';

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  }

} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  ...ContractDetailsComponents.initialValues,
  ...ContractDocumentComponent.initialValues,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

const DATETIME_FORMAT = 'DD MMM YYYY HH:mm'

function _formatDate(timestamp, timezone, format) {
    let moment = Moment(timestamp)

    if (timezone) {
        moment.tz(timezone)
    }

    return (moment.format(format))
}

function FormFields({ history, showNotification, loading, id, data }) {

  return (
    <>
      <ContractDetailsComponents.FormBody
        DATETIME_FORMAT={DATETIME_FORMAT}
        data={data}
        id={id}
      />
      <ContractDocumentComponent.FormBody
        id={id}
        showNotification={showNotification}
      />
    </>
  )
}

function toClientDataFormat(serverData) {

  let localData = { ...serverData }
  // valid date
  localData.validTo = _formatDate(serverData.validTo, null, DATETIME_FORMAT)
  localData.validFrom = _formatDate(serverData.validFrom, null, DATETIME_FORMAT)
  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const ContractInfoForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default ContractInfoForm
