import React from 'react'
import { Grid, Typography, Chip, Box, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import { fal as FontAwesomeRegular } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'
import Utils from './Utils'
import LogoWithTitleVertical from './LogoWithTitleVertical'
import HtmlTooltip from './HtmlTooltip'
import ListActionMenu from './ListActionMenu'
import { useHistory } from 'react-router-dom'
import pathMap from 'src/paths/PathMap_SCO_RateMgmt'

FontAwesomeLibrary.add(FontAwesomeRegular)

const {
    CngGridItem
} = components

const useStyles = makeStyles(theme => ({
    box_line: {
        border: '1px solid #F5F5FA',
        marginBottom: theme.spacing(1.5),
        padding: theme.spacing(0.75)
    },
    header: {
        backgroundColor: '#eff2f04d',
        width: '100%',
        height: '46px',
        marginRight: '-6px',
        paddingTop: '6px',
        paddingLeft: '16px',
        borderRadius: '8px',
        
    }, 
    header_cursor: {
        cursor: 'pointer'
    },
    header_button: {
        textAlign: 'right',
    }

}))

function ContractView_DetailRow({ details, showNotification, refreshList, selectedTab }) {
    const classes = useStyles()
    const history = useHistory()

    let color = Utils.getStatusByName(details.status).color

    let custShortName = details.customer_company_name
    let isCustShortNameCut = false
    if (details.customer_company_name && details.customer_company_name.length > 10) {
        custShortName = details.customer_company_name.substring(0, 10).trim() + '...'
        isCustShortNameCut = true
    }

    let issuShortName = details.issuer_company_name
    let isIssuShortNameCut = false
    if (details.issuer_company_name && details.issuer_company_name.length > 17) {
        issuShortName = details.issuer_company_name.substring(0, 17).trim() + '...'
        isIssuShortNameCut = true
    }


    const gotoView = () => {
        const viewUrl = pathMap.SCO_RATE_CONTRACT + "/view/" + details.id;
        history.push(viewUrl);
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <Grid item xs={12} sm={12} >
            <Card variant='outlined' className={classes.box_line} spacing={1}>
                <Grid container spacing={1} >
                    <div className={classes.header} >
                        <Grid container spacing={1} >
                            <CngGridItem item xs={11} sm={11} className={classes.header_cursor} onClick={gotoView}>
                                <Chip
                                    label={
                                        <Typography className={'boldLabel'} style={{ fontSize: 16, textTransform: 'uppercase' }}>
                                            {details.status}
                                        </Typography>
                                    }
                                    style={{ backgroundColor: color }}
                                />
                            </CngGridItem>
                            <CngGridItem item xs={1} sm={1} className={classes.header_button}>
                                <ListActionMenu details={details} showNotification={showNotification} refreshList={refreshList} selectedTab={selectedTab} />
                            </CngGridItem>
                        </Grid>
                    </div>
                    <CngGridItem xs={2} sm={2} pt={2}>
                        {/* TODO : Xihao said logo for cec company temporary hold first;
                                   reserved code section
                        */}
                        <LogoWithTitleVertical
                            logoSrc={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                            defaultImg={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
                            withTooltip={isCustShortNameCut}
                            longName={details.customer_company_name}
                            shortName={custShortName}
                        />
                    </CngGridItem>
                    <CngGridItem xs={3} sm={3}>
                        <Grid>
                            <Typography variant='caption' color='textSecondary'>Issuer</Typography>
                        </Grid>
                        <Grid>
                            <HtmlTooltip title={isIssuShortNameCut ? <Box>{details.issuer_company_name}</Box> : ''}>
                                <Typography variant='body2'>{issuShortName}</Typography>
                            </HtmlTooltip>
                        </Grid>
                        <br />
                        <Grid><Typography variant='caption' color='textSecondary'>Reason</Typography></Grid>
                        <Grid><Typography variant='body2'>{details.reason}</Typography></Grid>
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4}>
                        <Grid><Typography variant='caption' color='textSecondary'>Valid From</Typography></Grid>
                        <Grid><Typography variant='body2'>{details.valid_form}</Typography></Grid>
                        <br />
                        <Grid><Typography variant='caption' color='textSecondary'>Remarks</Typography></Grid>
                        <Grid><Typography variant='body2'>{details.remarks}</Typography></Grid>
                    </CngGridItem>
                    <CngGridItem xs={3} sm={3}>
                        <Grid><Typography variant='caption' color='textSecondary'>Valid until</Typography></Grid>
                        <Grid><Typography variant='body2'>{details.valid_to}</Typography></Grid>
                    </CngGridItem>
                    <CngGridItem xs={6} sm={6}>
                        <br />
                        <Grid>
                            <Typography variant='caption'>Contract no.: <b>{details.contract_no}</b></Typography>
                        </Grid>
                    </CngGridItem>
                    <CngGridItem xs={6} sm={6} style={{ textAlign: 'right' }}>
                        <br />
                        <Grid>
                            <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                                Last modified on: <b>{details.last_updated_date}</b>
                            </Typography>
                        </Grid>
                    </CngGridItem>
                </Grid>
            </Card>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

ContractView_DetailRow.propTypes = {
    title: PropTypes.string,
}

export default ContractView_DetailRow