import { Grid, Typography, Chip, Card } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewServiceRateCardPriceDetailsComponents from './ViewServiceRateCardPriceDetailsComponents'

const {
      CngGridItem,
} = components


const FormBody = props => {
      const classes = props.classes;
      const attrIndex = props.attrIndex;
      const rateComp = props.rateComp;

      return (
            <Card variant='outlined' className={classes.box_line} spacing={2}>
                  <Grid container spacing={1} >
                        <div className={classes.header}>
                              <Grid container spacing={1} >
                                    <span>Rate - {attrIndex + 1}</span>
                              </Grid>
                        </div>
                        <Grid container style={{ margin: 10 }}>
                              <CngGridItem xs={4} sm={4} style={{ paddingLeft: 5 }}>
                                    <Grid><Typography variant='caption' color='textSecondary'>Rate code</Typography></Grid>
                                    <Grid><Typography variant='body2'>{rateComp.componentCode}</Typography></Grid>
                                    <br />
                                    <Grid><Typography variant='caption' color='textSecondary'>Unit of measurement</Typography></Grid>
                                    <Grid><Typography component={'span'} variant='body2'><Chip label={<b>{rateComp.componentUOMCode}</b>} size='small' />{rateComp.componentUOMName}</Typography></Grid>
                                    <br />
                                    {rateComp.diffTax
                                          ?
                                          <>

                                                <Grid><Typography variant='caption' color='textSecondary'>Differentiated tax code</Typography></Grid>
                                                <Grid><Typography component={'span'} variant='body2'>
                                                      <Chip label={<b>
                                                            {
                                                                  (() => {
                                                                        if (rateComp.diffTaxPercentage == null || rateComp.diffTaxPercentage == 0) {
                                                                              return ('0')
                                                                        } else {
                                                                              return (rateComp.diffTaxPercentage * 100).toFixed(0)
                                                                        }
                                                                  })()
                                                            } %
                                                      </b>} size='small' />{rateComp.diffTaxName}</Typography></Grid>
                                                <br />
                                          </>
                                          :
                                          <>
                                                <Grid><Typography variant='caption' color='textSecondary'>Rate description</Typography></Grid>
                                                <Grid><Typography variant='body2'>{rateComp.componentDescription}</Typography></Grid>
                                          </>
                                    }

                              </CngGridItem>
                              <CngGridItem xs={4} sm={4}>
                                    <Grid><Typography variant='caption' color='textSecondary'>Rate name</Typography></Grid>
                                    <Grid><Typography variant='body2'>{rateComp.componentName}</Typography></Grid>
                                    <br />
                                    <Grid><Typography variant='caption' color='textSecondary'>Calculation method</Typography></Grid>
                                    <Grid><Typography component={'span'} variant='body2'><Chip label={<b>{rateComp.componentCalMetCode}</b>} size='small' />{rateComp.componentCalMetName}</Typography></Grid>
                                    <br />
                                    {(() => {
                                          if (rateComp.diffTax && rateComp.diffInclTax) {
                                                return (
                                                      <>
                                                            <br />
                                                            <Grid><Typography variant='body2'>Price inclusive of differentiated tax</Typography></Grid>
                                                      </>
                                                )
                                          } else if (rateComp.diffTax && !rateComp.diffInclTax) {
                                                return (
                                                      <>
                                                            <br />
                                                            <Grid><Typography variant='body2'>Price exclusive of differentiated tax</Typography></Grid>
                                                      </>
                                                )
                                          } else {
                                                return (
                                                      <>
                                                      </>
                                                )
                                          }
                                    })()}

                              </CngGridItem>
                              <CngGridItem xs={4} sm={4}>
                                    <Grid><Typography variant='caption' color='textSecondary'>Calculated by</Typography></Grid>
                                    <Grid><Typography component={'span'} variant='body2'><Chip label={<b>{rateComp.componentCalByCode}</b>} size='small' />{rateComp.componentCalByName}</Typography></Grid>
                                    <br />
                                    {(() => {
                                          if (rateComp.diffTax) {
                                                return (
                                                      <>
                                                            <Grid><Typography variant='body2'> <FontAwesomeIcon icon={['fal', 'fa-circle-check']} style={{ color: 'blue', marginRight: 2 }} /> Differentiated tax</Typography></Grid>
                                                            <br /><br />
                                                            <Grid><Typography variant='caption' color='textSecondary' >Rate description</Typography></Grid>
                                                            <Grid><Typography variant='body2'>{rateComp.componentDescription}</Typography></Grid>
                                                      </>
                                                )
                                          } else {
                                                return (
                                                      <>
                                                            <Grid><Typography variant='body2'> <FontAwesomeIcon icon={['fal', 'fa-circle-xmark']} style={{ color: 'red', marginRight: 2 }} /> Differentiated tax</Typography></Grid>
                                                            <br />
                                                      </>
                                                )
                                          }
                                    })()}

                              </CngGridItem>

                              <hr style={{ borderTop: '1px solid #f4f4fc', width: '100%' }} />

                              {rateComp.rate.map((ratePrice) => {
                                    return (
                                          <ViewServiceRateCardPriceDetailsComponents.FormBody
                                                ratePrice={ratePrice}
                                          />
                                    )
                              })}
                        </Grid>

                  </Grid>
            </Card>
      )
}

const ViewServiceRateCardDetailsComponents = Object.freeze({
      FormBody: FormBody
})

export default ViewServiceRateCardDetailsComponents