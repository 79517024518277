import React, { useState, useEffect, useMemo } from 'react'
import { components, useServices } from 'cng-web-lib'
import {
      Grid, Button, Box, Card, Step, Divider, CardContent
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { CngStep } from 'src/components/stepper'
import ContractInfoForm from './add-page/ContractInfoForm'
import ProvideRateForm from './add-page/ProvideRateForm'
import FinaliseDetailsForm from './add-page/FinaliseDetailsForm'
import FinishForm from './add-page/FinishForm'
import DiscardButton from 'src/components/button/DiscardButton'
import pathMap from 'src/paths/PathMap_SCO_RateMgmt'
import { useFormContext } from 'react-hook-form'
import { ContractInfoValidateSchema } from '../validateSchemas/ContractInfoValidateSchema'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import UpdateContractStatusDialog from './landing-page/UpdateContractStatusDialog'
import Utils from './landing-page/Utils'

const {
      form: {
            adapter: {
                  useFormAdapter: { useFormikContext }
            },
            CngEditForm, CngAddForm },
      table: { useDefaultNotification },
      stepper: { CngStepper },
      button: { CngPrimaryButton },
} = components


function AddPage({ showNotification }) {
      const { fetchRecords } = useServices();
      const { createRecord } = useServices()
      const [loading, setLoading] = useState(false)
      const history = useHistory()
      const [id, setId] = useState(null)
      const [data, setData] = useState({})
      const [isDraft, setIsDraft] = useState(false)

      const {
            success: showSuccessNotification,
            error: showErrorNotification
      } = useDefaultNotification(showNotification)

      function onSubmit(submitedData) {
            let url = SCORateMgmtApiUrls.HDR_CREATE_CONTRACT;

            if (id != null) {
                  url = SCORateMgmtApiUrls.HDR_UPDATE_CONTRACT
            }

            function onSuccess(response) {

                  if (response.errorMessages != null && response.errorMessages.length > 0) {
                        showErrorNotification(response.errorMessages)
                        setLoading(false)
                  } else {
                        if (activeStep == 0) {
                              setId(response.id)
                              if (submitedData.pendingContractDocuments != null && submitedData.pendingContractDocuments.length > 0) {
                                    uploadDocument(response.id, submitedData);
                              } else {
                                    showSuccessNotification('Saved successfully')
                                    { isDraft ? history.push(pathMap.SCO_RATE_CONTRACT_LIST_VIEW) : toNextPage() }
                              }
                        } else {
                              showSuccessNotification('Saved successfully')
                              { isDraft ? history.push(pathMap.SCO_RATE_CONTRACT_LIST_VIEW) : toNextPage() }
                        }

                  }
            }

            function onError(error) {
                  console.log(error)
                  setLoading(false)
                  console.log('connection-request error', error.response.data.errorMessages)
                  showErrorNotification(error.response.data.errorMessages)
            }

            // creation API
            createRecord.execute(
                  url,
                  submitedData,
                  onSuccess,
                  onError
            )

      }

      function issueContract(issuedData) {
            setStatusDialogOpen(true)
      }

      function uploadDocument(respId, docData) {
            var formData = new FormData();
            console.log(respId)
            docData.pendingContractDocuments.map((file, i) => {
                  var fileBlob = file.file
                  formData.append("fileContents", fileBlob)
                  formData.append("docType", docData.pendingContractDocuments[i].fields[0].value)
                  formData.append("contractId", respId)
            })

            function onSuccess(response) {
                  if (response.length > 0 && response.errorMessages != null) {
                        console.log('error >> ')
                        showErrorNotification(response.errorMessages)
                  } else {
                        let contractDocuments = docData.contractDocuments || []
                        if (response && response.length > 0) {
                              console.log('this is response >>>> ' + JSON.stringify(response))
                              response.map((file, i) => {
                                    let contractDocu = {
                                          docType: docData.pendingContractDocuments[i].fields[0].value,
                                          fileName: file.fileName,
                                          id: file.id,
                                          fileSize: file.fileSize,
                                          docTypeId: file.docTypeId
                                    }
                                    contractDocuments.push(contractDocu)
                              })
                        }
                        data.contractDocs = contractDocuments;
                        showSuccessNotification('Saved successfully')
                        { isDraft ? history.push(pathMap.SCO_RATE_CONTRACT_LIST_VIEW) : toNextPage() }
                  }
            }

            function onError(error) {
                  showErrorNotification(error.message)
            }

            createRecord.execute(
                  SCORateMgmtApiUrls.DOCUMENT_UPLOAD,
                  formData,
                  onSuccess,
                  onError
            )
      }

      const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
      const [confirmAction, setConfirmAction] = useState(Utils.Action.discard) //need to initiable a value

      const ConfirmMessage = [
            {
                  action: Utils.Action.discard,
                  title: "Do you want to discard this item?",
                  content: "All progress will be lost and can't be restored.",
                  okMsg: "Yes, discard",
                  cancelMsg: "No, take me back"
            },
            {
                  action: Utils.Action.back,
                  title: "Do you want to leave this page?",
                  content: "All progress will be lost and can't be restored.",
                  okMsg: "Yes, leave this page",
                  cancelMsg: "No, stay on this page"
            }
      ]
      function getDialogMessageByAction(actionVal) {
            return ConfirmMessage.find((b) => b.action == actionVal)
      }

      const discardRecord = () => {
            setConfirmAction(Utils.Action.discard)
            setConfirmDialogOpen(true)
      }

      const backAction = () => {
            setConfirmAction(Utils.Action.back)
            setConfirmDialogOpen(true)
      }

      const confirmDialog = () => {
            setConfirmDialogOpen(false) //close the dialog first
            if (confirmAction === Utils.Action.discard) {
                  history.push(pathMap.SCO_RATE_CONTRACT_LIST_VIEW)
            }
            if (confirmAction === Utils.Action.back) {
                  history.push(pathMap.SCO_RATE_CONTRACT + "/edit/" + id)
            }
      }

      const [statusDialogOpen, setStatusDialogOpen] = useState(false)
      const [statusAction] = useState(Utils.Action.issue) //need to initiable a value
      const StatusTitle = [
            {
                  action: Utils.Action.issue,
                  title: "Issue contract",
                  label: "Issue",
                  iconName: "fa-arrow-right-from-bracket",
                  successNotification: "Item issued."
            }
      ]

      function getDetailsByAction(actionVal) {
            return StatusTitle.find((b) => b.action == actionVal)
      }

      const confirmUpdateStatusDialog = (confirmActionVal, reason, remarks) => {
            setStatusDialogOpen(false) //close the dialog first
            proceedUpdateStatus(confirmActionVal, reason, remarks)
      }

      const proceedUpdateStatus = (confirmActionVal, reason, remarks) => {

            function onSuccess(response) {
                  if (response.errorMessages != null && response.errorMessages.length > 0) {
                        showErrorNotification(response.errorMessages)
                  } else {
                        showSuccessNotification(getDetailsByAction(confirmActionVal).successNotification)
                        toNextPage();
                  }
            }


            function onError(error) {
                  console.log('connection-request error', error.message)
                  showErrorNotification(error.message)
            }

            createRecord.execute(
                  SCORateMgmtApiUrls.HDR_UPDATE_CONTRACT_STATUS,
                  {
                        contractId: id,
                        action: confirmActionVal,
                        reasonId: reason,
                        remarks: remarks,
                        selectedTab: Utils.Tab.CONTRACT
                  },
                  onSuccess,
                  onError
            )
      }

      const [activeStep, setActiveStep] = useState(0)

      const handleStep = values => {
            issueContract(values)
      }

      const toNextPage = () => {
            let nextStep = activeStep + 1;
            setActiveStep(nextStep);
            window.scrollTo(0, 0)
      }


      useEffect(() => {

            if (id != undefined && id != null) {
                  if (activeStep == 0 || activeStep == 1) {
                        getData()
                  } else {
                        getDataView()
                  }
            }

            return () => {
                  fetchRecords.abort()
            }

      }, [activeStep])

      const getData = () => {
            const onSuccess = (response) => {
                  setData(response)
                  setLoading(false);
            }

            const onError = (error) => {
                  setLoading(false);
                  showErrorNotification('fetch error')
            }

            fetchRecords.execute(
                  SCORateMgmtApiUrls.HDR_GET_CONTRACT,
                  {
                        customData: {},
                        filters: [
                              {
                                    field: 'id',
                                    operator: 'EQUAL',
                                    value: id
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }

      const getDataView = () => {
            const onSuccess = (response) => {
                  setData(response)
                  setLoading(false);
            }

            const onError = (error) => {
                  setLoading(false);
                  showErrorNotification('fetch error')
            }

            fetchRecords.execute(
                  SCORateMgmtApiUrls.HDR_GET_CONTRACT,
                  {
                        customData: {},
                        filters: [
                              {
                                    field: 'id',
                                    operator: 'EQUAL',
                                    value: id
                              },
                              {
                                    field: 'view',
                                    operator: 'EQUAL',
                                    value: true
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }

      const STEPS = [{
            label: 'Set up contract',
            Component:
                  <>
                        <CngAddForm
                              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                              toClientDataFormat={ContractInfoForm.toClientDataFormat}
                              toServerDataFormat={ContractInfoForm.toServerDataFormat}
                              formikProps={{
                                    ...ContractInfoForm.formikProps,
                                    validationSchema: ContractInfoValidateSchema[activeStep],
                                    onSubmit: onSubmit
                              }}
                              renderBodySection={() => <>
                                    <CardContent >
                                          <ContractInfoForm.FormFields
                                                id={id}
                                                data={data}
                                                showNotification={showNotification}
                                          />
                                    </CardContent>
                              </>}
                              renderButtonSection={() =>
                                    <>
                                          <Divider />
                                          <Box mx={3} my={2}>
                                                <Grid container spacing={1}>
                                                      <Grid item xs>
                                                            <DiscardButton
                                                                  onClick={() => discardRecord()}
                                                            />
                                                      </Grid>
                                                      <Grid item>
                                                            <SaveDraftButton
                                                                  {...nextButtonProps}
                                                                  showErrorNotification={showErrorNotification}
                                                                  onStep={handleStep}
                                                            />

                                                            <NextButton
                                                                  {...nextButtonProps}
                                                                  showErrorNotification={showErrorNotification}
                                                                  onStep={handleStep}
                                                            />
                                                      </Grid>

                                                </Grid>
                                          </Box>

                                    </>
                              }
                        />

                  </>


      }, {
            label: 'Provide rate',
            Component: <>
                  <CngEditForm
                        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                        fetch={{
                              url: `${SCORateMgmtApiUrls.HDR_GET_CONTRACT}`
                        }}
                        id={id}
                        toClientDataFormat={ProvideRateForm.toClientDataFormat}
                        toServerDataFormat={ProvideRateForm.toServerDataFormat}
                        formikProps={{
                              ...ProvideRateForm.formikProps,
                              validationSchema: ContractInfoValidateSchema[activeStep],
                              onSubmit: onSubmit
                        }}
                        renderBodySection={() => <>
                              <CardContent >
                                    <ProvideRateForm.FormFields
                                          id={id}
                                          data={data}
                                          showNotification={showNotification}
                                    />
                              </CardContent>
                        </>}
                        renderButtonSection={() =>
                              <>
                                    <Divider />
                                    <Box mx={3} my={2}>
                                          <Grid container spacing={1}>
                                                <Grid item xs>
                                                      <DiscardButton
                                                            onClick={() => discardRecord()}
                                                      />
                                                </Grid>
                                                {activeStep > 0 &&
                                                      <Grid item>
                                                            <CngPrimaryButton
                                                                  onClick={() => backAction()}
                                                            >
                                                                  Back
                                                            </CngPrimaryButton>
                                                      </Grid>
                                                }
                                                <Grid item>
                                                      <SaveDraftButton
                                                            {...nextButtonProps}
                                                            showErrorNotification={showErrorNotification}
                                                            onStep={handleStep}
                                                      />

                                                      <NextButton
                                                            {...nextButtonProps}
                                                            showErrorNotification={showErrorNotification}
                                                            onStep={handleStep}
                                                      />
                                                </Grid>
                                          </Grid>
                                    </Box>
                              </>
                        }
                  />,
            </>

      }, {
            label: 'Finalise details',
            Component:
                  <CngEditForm
                        fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                        fetch={{
                              url: `${SCORateMgmtApiUrls.HDR_GET_CONTRACT}`,
                              body: {
                                    'view': true
                              }
                        }}
                        id={id}
                        toClientDataFormat={FinaliseDetailsForm.toClientDataFormat}
                        toServerDataFormat={FinaliseDetailsForm.toServerDataFormat}
                        formikProps={{
                              ...FinaliseDetailsForm.formikProps,
                              validationSchema: ContractInfoValidateSchema[activeStep],
                              onSubmit: issueContract
                        }}
                        renderBodySection={() =>
                              <>
                                    <CardContent >
                                          <FinaliseDetailsForm.FormFields
                                                id={id}
                                                data={data}
                                                page={'Finalise'}
                                                showNotification={showNotification}
                                          />
                                    </CardContent>
                              </>
                        }

                        renderButtonSection={() =>
                              <>
                                    <Divider />
                                    <Box mx={3} my={2}>
                                          <Grid container spacing={1}>
                                                <Grid item xs>
                                                      <DiscardButton
                                                            onClick={() => discardRecord()}
                                                      />
                                                </Grid>
                                                {activeStep > 0 &&
                                                      <Grid item>
                                                            <CngPrimaryButton
                                                                  onClick={() => setActiveStep(activeStep - 1)}
                                                            >
                                                                  Back
                                                            </CngPrimaryButton>
                                                      </Grid>
                                                }
                                                <Grid item>
                                                      <CngPrimaryButton onClick={() => { history.push(pathMap.SCO_RATE_CONTRACT_LIST_VIEW) }}>
                                                            Close draft
                                                      </CngPrimaryButton>

                                                      <NextButton
                                                            {...nextButtonProps}
                                                            showErrorNotification={showErrorNotification}
                                                            onStep={handleStep}
                                                      />
                                                </Grid>
                                          </Grid>
                                    </Box>
                              </>
                        }
                  />

      }, {
            label: 'Finish',
            Component:
                  <CngEditForm
                        fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                        fetch={{
                              url: `${SCORateMgmtApiUrls.HDR_GET_CONTRACT}`,
                              body: {
                                    'view': true
                              }
                        }}
                        id={id}
                        toClientDataFormat={FinishForm.toClientDataFormat}
                        toServerDataFormat={FinishForm.toServerDataFormat}
                        formikProps={{
                              ...FinishForm.formikProps,
                              validationSchema: ContractInfoValidateSchema[activeStep],
                              onSubmit: {}
                        }}
                        renderBodySection={() =>
                              <>
                                    <CardContent >
                                          <FinishForm.FormFields
                                                id={id}
                                                data={data}
                                                page={'Finish'}
                                                showNotification={showNotification}
                                          />
                                    </CardContent>
                              </>
                        }

                        renderButtonSection={() =>
                              <>
                                    <Divider />
                                    <Box mx={3} my={2}>
                                          <Grid container spacing={1}>
                                                <Grid item xs>

                                                </Grid>
                                                <Grid item>
                                                      <CngPrimaryButton
                                                            onClick={() => history.push(pathMap.SCO_RATE_CONTRACT_LIST_VIEW)}
                                                      >Back to contracts listing
                                                      </CngPrimaryButton>
                                                </Grid>
                                          </Grid>
                                    </Box>
                              </>
                        }
                  />,

      }]

      const nextButtonProps = useMemo(() => {
            switch (activeStep) {
                  case 0:
                        return {
                              step: 0,
                              setIsDraft: setIsDraft,
                              children: 'Save & continue'
                        };
                  case 1:
                        return {
                              step: 1,
                              setIsDraft: setIsDraft,
                              children: 'Save & finalise'
                        };
                  case 2:
                        return {
                              step: 2,
                              setIsDraft: setIsDraft,
                              children: 'Issue',
                              id: id

                        };
            }
      }, [activeStep, loading])

      return (
            <Card>
                  <Box mx={3}>
                        <CngStepper activeStep={activeStep}>
                              {STEPS.map(({ label }, i) => <Step key={i}>
                                    <CngStep label={label} />
                              </Step>)}
                        </CngStepper>
                  </Box>
                  <Divider />
                  {STEPS[activeStep].Component}
                  <ConfirmDialog
                        isConfirmDialogOpen={confirmDialogOpen}
                        closeDialog={() => setConfirmDialogOpen(false)}
                        confirmDialog={confirmDialog}
                        content={getDialogMessageByAction(confirmAction).content}
                        okMsg={getDialogMessageByAction(confirmAction).okMsg}
                        cancelMsg={getDialogMessageByAction(confirmAction).cancelMsg}
                        title={getDialogMessageByAction(confirmAction).title}
                  />
                  <UpdateContractStatusDialog
                        isDialogOpen={statusDialogOpen}
                        closeDialog={() => setStatusDialogOpen(false)}
                        dialogTitle={getDetailsByAction(statusAction).title}
                        proceedAction={confirmUpdateStatusDialog}
                        statusAction={statusAction}
                  />

            </Card >
      )
}

function getObjectIndex(obj) {
      let keyList = Object.keys(obj[Object.keys(obj)])
      let valueList = Object.values(obj[Object.keys(obj)])
      let objectIndex = 0;

      //do not return index if value === undefined
      for (let i = 0; i < keyList.length; i++) {
            if (valueList[i] === undefined) {
                  continue;
            }
            objectIndex = keyList[i]
      }
      return objectIndex
}

function getObjectName(obj) {
      let k = Object.keys(obj);
      let name;
      for (let i = 0; i < k.length; i++) {
            if (obj[Object.keys(obj)[i]] === undefined) {
                  continue;
            }
            name = Object.keys(obj[Object.keys(obj)[i]]);
            break;
      }
      if (name instanceof Array && name.length > 0) {
            return name[0];
      } else if (name.includes(',')) {
            let nameSplit = name.split(',')
            return nameSplit[0]
      } else {
            return name;
      }

}

function getErrorComponentName(errors, childErrors, firstComponentName) {
      //To check if array component
      if (childErrors instanceof Array) {
            //To determine array index  
            let indexofFirstObj = getObjectIndex(errors)
            let childErrorObjName = getObjectName(childErrors)
            firstComponentName = firstComponentName + '[' + indexofFirstObj + '].' + childErrorObjName

            let errors2 = childErrors[indexofFirstObj]
            if (errors2 != undefined) {
                  //nested array
                  let nestChildErrorObj = errors2[Object.keys(errors2)]
                  firstComponentName = getErrorComponentName(errors2, nestChildErrorObj, firstComponentName)
            }
      }
      return firstComponentName;
}

export const SaveDraftButton = ({ step, id, onStep, setIsDraft, showErrorNotification, ...props }) => {
      const { formState: { errors }, ...form } = useFormContext()
      const { submitForm } = useFormikContext()
      const [isClicked, setIsClicked] = useState(0)

      const handleClick = () => {
            setIsDraft(true)
            setIsClicked(isClicked + 1)
      }

      useEffect(() => {
            if(isClicked > 0){
                  form.trigger().then(isValid => {
                        if (!isValid) {
                              console.log('!valid')
                              console.log(errors)
                              //  Target to convert { errors } of formState to input name
                              // {serviceItems: [empty, {1: {categoryId: 'Required/Mandatory'}}]} to >>> serviceItems[1].categoryId

                              const firstErrorObjName = Object.keys(errors).reduce((field, a) => {
                                    return !!errors[field] ? field : a;
                              }, null);

                              if (firstErrorObjName) {
                                    let firstComponentName = firstErrorObjName;
                                    let childErrorObj = errors[Object.keys(errors)]

                                    firstComponentName = getErrorComponentName(errors, childErrorObj, firstComponentName);

                                    let element = document.getElementsByName(firstComponentName);
                                    if (element && element[0]) {
                                          element[0].parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                    } else {
                                          showErrorNotification("Please complete the form")
                                    }
                              }

                              return;
                        }
                        submitForm()
                  })
            }
            
      }, [isClicked]);

      return (
            <CngPrimaryButton onClick={handleClick}>
                  Save draft & close
            </CngPrimaryButton>
      )
}

export const NextButton = ({ step, id, onStep, setIsDraft, showErrorNotification, ...props }) => {
      const { formState: { errors }, ...form } = useFormContext({ shouldFocusError: true })
      const { submitForm } = useFormikContext()
      const [isClicked, setIsClicked] = useState(0)

      const handleClick = () => {
            setIsDraft(false)
            setIsClicked(isClicked + 1)
      }
      useEffect(() => {
            if(isClicked > 0){
                  if (step == 0 || step == 1) {
                        form.trigger().then(isValid => {
                              if (!isValid) {
                                    console.log('!valid')
                                    console.log(errors)
                                     //  Target to convert { errors } of formState to input name
                                    // {serviceItems: [empty, {1: {categoryId: 'Required/Mandatory'}}]} to >>> serviceItems[1].categoryId

                                    const firstErrorObjName = Object.keys(errors).reduce((field, a) => {
                                          return !!errors[field] ? field : a;
                                    }, null);

                                    if (firstErrorObjName) {
                                          let firstComponentName = firstErrorObjName;
                                          let childErrorObj = errors[Object.keys(errors)]

                                          firstComponentName = getErrorComponentName(errors, childErrorObj, firstComponentName);

                                          let element = document.getElementsByName(firstComponentName);
                                          if (element && element[0]) {
                                                element[0].parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                          } else {
                                                showErrorNotification("Please complete the form")
                                          }

                                    }

                                    return;
                              }
                              submitForm()
                        })
                  } else {
                        onStep(2)
                  }
            }
           
      }, [isClicked]);


      return (
            <Button
                  {...props}
                  type='button'
                  variant="contained"
                  className="button-light-green originalText"
                  style={{ minHeight: '42px', borderRadius: '8px', marginLeft: '5px' }}
                  onClick={handleClick}

            />
      )
}
export default AddPage