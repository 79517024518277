import React from 'react'
import { Card, CardContent, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import TranslationText from '../../TranslationText'

function FilterPanel({
    onClearAllClicked = (event) => { /* Do nothing */ },
    onApplyClicked = (event) => { /* Do nothing */ },
    ...props
}) {
    //* Styles -----------------------------------------------------------------
    const useStyles = makeStyles({
        filterButton: {
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0
        },
        filterCancelButton: {
            "background-color": "#8996AF0F !important"
        }
    });

    const classes = useStyles()
    const translatedTextObject = TranslationText()

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Card>
            <CardContent>
                <Grid container alignItems='center'>
                    <Grid item xs={6} >
                        <h3 style={{ marginBottom: 0, fontWeight: 'bold' }}>
                            {translatedTextObject.filterTitle}
                        </h3>
                    </Grid>
                    <Grid item xs={12} xl={6} container spacing={1} alignItems='center'>
                        <Grid item xs={6}>
                            <Button variant='contained'
                                classes={{ root: 'ng-button-filled-secondary' }}
                                className={`${classes.filterButton} ${classes.filterCancelButton}`}
                                onClick={onClearAllClicked}
                            >
                                {translatedTextObject.btnClear}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant='contained'
                                classes={{ root: 'ng-button-filled-secondary' }}
                                className={classes.filterButton}
                                onClick={onApplyClicked}
                            >
                                {translatedTextObject.btnApply}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            {
                props.children
            }
        </Card>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default FilterPanel
