import {
      Grid, Typography, Chip, Card, Link, Avatar
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { components, useServices } from 'cng-web-lib'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import TranslationText from '../TranslationText'
import { fal as FontAwesomeRegular, faShareSquare, faRoad, faPlane, faShip, faQuestion, faGlobe  } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'
import Moment from 'moment';
import 'moment-timezone';
import ServiceRateCardDetailsComponents from './ServiceRateCardDetailsComponents'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import Utils from 'src/views/common/utils/Utils'
import ServiceTncDialog from './ServiceTncDialog'
import CountryTooltip from './CountryTooltip'
import ContractDocumentActionMenu from '../add-page/ContractDocumentActionMenu'

FontAwesomeLibrary.add(FontAwesomeRegular)

const {
      CngGridItem,
} = components

const initialValues = Object.freeze({
      contractId: "",
      contractNo: "",
      issuerName: "",
      customerName: "",
      validFrom: "",
      validTo: "",
      timezoneStr: ""
})

function _formatDate(timestamp, timezone, format) {
      let moment = Moment(timestamp)

      if (timezone) {
            moment.tz(timezone)
      }

      return (moment.format(format))
}
const DATETIME_FORMAT = 'DD MMM YYYY HH:mm'

const useStyles = makeStyles(theme => ({
      box_line: {
            border: '1px solid #F5F5FA',
            marginBottom: theme.spacing(1.5),
            padding: theme.spacing(0.75)
      },
      header: {
            backgroundColor: '#f4f4fc',
            //#eff2f04d
            width: '102%',
            height: '46px',
            marginRight: '-6px',
            paddingTop: '16px',
            paddingLeft: '16px',
            borderRadius: '8px',
            fontWeight: 'bolder'
      },
      header_right: {
            textAlign: 'right',
            marginLeft: '-10px'
      },
      body: {
            padding: '15px'
      },
      break: {
            backgroundColor: '#f4f4fc',
            //#eff2f04d
            width: '102%',
            height: '40px',
            marginRight: '-6px',
            paddingTop: '16px',
            paddingLeft: '16px',
            marginBottom: '15px'

      },
      flags: {
            height: '1em', width: '1em',
            display: 'inline-block',
            marginRight: '10px', marginLeft: '10px'
      },

}))

const FormBody = props => {
      const translatedTextsObject = TranslationText()
      const { fetchRecords, securedSendRequest } = useServices()
      const classes = useStyles()
      const details = props.data.content[0];
      const [uploadedDocs, setUploadedDocs] = useState([])
      const [isOpen, setIsOpen] = useState(false);
      const [serviceTnc, setServiceTnc] = useState();

      function getIconByTransportMode(code) {
            switch (code) {
                  case 'AIR': return (
                        <FontAwesomeIcon icon={faPlane} size='sm' className='icon-circle-border bg-white mr-5 ml-5 mb-2' />);
                  case 'LAND': return (
                        <FontAwesomeIcon icon={faRoad} size='sm' className='icon-circle-border bg-white mr-5 ml-5 mb-2' />);
                  case 'SEA': return (
                        <FontAwesomeIcon icon={faShip} size='sm' className='icon-circle-border bg-white mr-5 ml-5 mb-2' />);
                  default: return (
                        <FontAwesomeIcon icon={faQuestion} size='xs' className='icon-circle-border bg-white mr-5 ml-5 ' />)
            }
      }

      function getFromLocationAvatar(service) {
            if (service.fromCountry != null && service.fromLoc != null) {
                  return (<>
                        <Avatar className={classes.flags}
                              src={`${process.env.PUBLIC_URL}/static/images/countries/${service.fromCountry}.svg`}
                              alt={`${service.fromCountryName} flag`}
                        ></Avatar>
                        <span style={{ verticalAlign: 'text-bottom' }}>{service.fromCountryName} <span className='MuiTypography-colorTextSecondary'>{service.fromLoc} </span></span> 
                  </>)
            } else if (service.fromCountry != null && service.fromLoc == null) {
                  return (<>
                        <Avatar className={classes.flags}
                              src={`${process.env.PUBLIC_URL}/static/images/countries/${service.fromCountry}.svg`}
                              alt={`${service.fromCountryName} flag`}
                        ></Avatar>
                        <span style={{ verticalAlign: 'text-bottom' }}>{service.fromCountryName} <span className='MuiTypography-colorTextSecondary'>- </span></span> 
                  </>)
            } else {
                  return (<>
                        <FontAwesomeIcon icon={faGlobe} size='sm' className='icon-circle-border mr-5 ml-5 p-1' />
                        <span style={{ verticalAlign: 'text-bottom' }}>- <span className='MuiTypography-colorTextSecondary'>- </span></span> 
                  </>)
            }
      }

      function getToLocationAvatar(service) {
            if (service.toCountry != null && service.toLoc != null) {
                  return (<>
                        <Avatar className={classes.flags}
                              src={`${process.env.PUBLIC_URL}/static/images/countries/${service.toCountry}.svg`}
                              alt={`${service.toCountryName} flag`}
                        ></Avatar>
                        <span style={{ verticalAlign: 'text-bottom' }}>{service.toCountryName} <span className='MuiTypography-colorTextSecondary'>{service.toLoc} </span></span> 

                  </>
                  )
            } else if (service.toCountry != null && service.toLoc == null) {
                  return (<>
                        <Avatar className={classes.flags}
                              src={`${process.env.PUBLIC_URL}/static/images/countries/${service.toCountry}.svg`}
                              alt={`${service.toCountryName} flag`}
                        ></Avatar>
                        <span style={{ verticalAlign: 'text-bottom' }}>{service.toCountryName} <span className='MuiTypography-colorTextSecondary'>- </span></span> 

                  </>
                  )
            } else {
                  return (<>
                        <FontAwesomeIcon icon={faGlobe} size='sm' className='icon-circle-border mr-5 ml-5 p-1' />
                        <span style={{ verticalAlign: 'text-bottom' }}>- <span className='MuiTypography-colorTextSecondary'>- </span></span> 
                  </>)
            }
      }

      function fetchDocuments() {
            fetchRecords.execute(
                  SCORateMgmtApiUrls.DOCUMENT_GETBYCONTRACTID,
                  { customData: { id: props.id } },
                  (data) => {
                        let uploadedDocList = [];
                        if (uploadedDocList != null) {
                              let uploadedDocListWithEditable = data.map((v) => ({ ...v, editable: false }))
                              setUploadedDocs(uploadedDocListWithEditable)
                        }
                  }
            )
      }

      const downloadUploadedRowClick = id => {
            downloadFile(uploadedDocs[id])
      }

      function downloadFile(doc) {
            securedSendRequest.execute(
                  "POST",
                  SCORateMgmtApiUrls.DOCUMENT_DOWNLOAD,
                  {
                        id: doc.id
                  },
                  (data) => {

                        var link = data.data.content[0].fileUrl
                        window.open(link, '_blank').focus()
                  },
                  (error) => {
                        console.log(error)
                  },

            )
      }

      useEffect(() => {
            fetchDocuments();
      }, [])

      const openServiceTncDialog = (tnc) => {
            setServiceTnc(tnc);
            setIsOpen(true);
      }

      function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      }

      return (
            <Grid item xs={12} sm={12} >
                  <Card variant='outlined' className={classes.box_line} spacing={2}>
                        <Grid container spacing={1} >
                              <div className={classes.header}>
                                    <Grid container spacing={1} >
                                          <span>{translatedTextsObject.contractInfoSubTitle}</span>
                                    </Grid>
                              </div>
                              <CngGridItem xs={4} sm={4} style={{ paddingLeft: 5 }}>
                                    <Grid><Typography variant='caption' color='textSecondary'>Contract no</Typography></Grid>
                                    <Grid><Typography variant='body2'>{details.contractNo}</Typography></Grid>
                                    <br />
                                    <Grid><Typography variant='caption' color='textSecondary'>Valid From</Typography></Grid>
                                    <Grid><Typography variant='body2'>{_formatDate(details.validForm, null, DATETIME_FORMAT)}</Typography></Grid>
                              </CngGridItem>
                              <CngGridItem xs={4} sm={4}>
                                    <Grid><Typography variant='caption' color='textSecondary'>Issuer</Typography></Grid>
                                    <Grid><Typography variant='body2'>{details.issuerName}</Typography></Grid>
                                    <br />
                                    <Grid><Typography variant='caption' color='textSecondary'>Valid Until</Typography></Grid>
                                    <Grid><Typography variant='body2'>{_formatDate(details.validTo, null, DATETIME_FORMAT)}</Typography></Grid>
                              </CngGridItem>

                              <CngGridItem xs={4} sm={4}>
                                    <Grid><Typography variant='caption' color='textSecondary'>Customer name</Typography></Grid>
                                    <Grid><Typography variant='body2'>{details.customerName}</Typography></Grid>
                                    <br />
                                    <Grid><Typography variant='caption' color='textSecondary'>Timezone</Typography></Grid>
                                    <Grid><Typography variant='body2'>{details.timezoneStr}</Typography></Grid>
                              </CngGridItem>
                        </Grid>
                  </Card>

                  <Card variant='outlined' className={classes.box_line} spacing={2}>
                        <Grid container spacing={1} >
                              <div className={classes.header}>
                                    <Grid container spacing={1} >
                                          <span>{translatedTextsObject.contractDocSubTitle}</span>
                                    </Grid>
                              </div>
                              {uploadedDocs.map((elem, docIndex) => {
                                    return (
                                          <>
                                                <CngGridItem xs={1} sm={1} style={{ padding: 20 }}>
                                                      <FileCopyIcon />
                                                </CngGridItem>
                                                <CngGridItem xs={3} sm={3} style={{ marginTop: '-13px', marginBottom: '-9px' }}>
                                                      <DocumentInfoDecoratorComponent name={Utils.truncLongFileName(elem.fileName + '.' + elem.extension)} size={elem.fileSize} />
                                                </CngGridItem>
                                                <CngGridItem xs={7} sm={7}>
                                                      <Grid><Typography variant='caption' color='textSecondary'>File type</Typography></Grid>
                                                      <Grid><Typography variant='body2'>{elem.docTypeName}</Typography></Grid>

                                                </CngGridItem>
                                                <CngGridItem xs={1} sm={1}>
                                                      <ContractDocumentActionMenu
                                                            id={docIndex}
                                                            downloadRowClick={downloadUploadedRowClick}
                                                            hideEdit
                                                            hideDelete
                                                            hideDownloadLog
                                                      />
                                                </CngGridItem>
                                                {docIndex > 0 ?
                                                      <hr style={{ borderTop: '1px solid #f4f4fc', width: '100%' }} />
                                                      : <></>}

                                          </>
                                    )


                              })}


                        </Grid>
                  </Card>

                  <Card variant='outlined' className={classes.box_line} spacing={2}>
                        <Grid container spacing={1} >
                              <div className={classes.header}>
                                    <Grid container spacing={1} >
                                          <span>{translatedTextsObject.contractRateCardSubTitle}</span>
                                    </Grid>
                              </div>

                              <CngGridItem xs={12} sm={12} style={{ padding: 10 }}>
                                    {details.serviceItems.map((service, index) => {
                                          return (
                                                <Card variant='outlined' className={classes.box_line} spacing={2}>
                                                      <Grid container spacing={1} >
                                                            <div className={classes.header}>
                                                                  <Grid container spacing={1} >
                                                                        <CngGridItem item xs={3} sm={3}>
                                                                              <h6>Services - {index + 1}</h6>
                                                                        </CngGridItem>
                                                                        <CngGridItem item xs={9} sm={9} className={classes.header_right}>
                                                                        <Typography component={'span'} variant='body1'>
                                                                                    {props.page == 'Finish'
                                                                                          ?
                                                                                          <>
                                                                                                <span style={{ verticalAlign: 'text-bottom' }}>{service.serviceUUID}
                                                                                                      <span className='MuiTypography-colorTextSecondary'>.UUID</span> | </span>
                                                                                          </>
                                                                                          :
                                                                                          <></>
                                                                                    }
                                                                                    <CountryTooltip
                                                                                          title={
                                                                                                <Grid container>
                                                                                                      <CngGridItem xs={5} sm={5} >
                                                                                                            Transport mode
                                                                                                            <br />
                                                                                                            Origin
                                                                                                            <br />
                                                                                                            Destination
                                                                                                      </CngGridItem>
                                                                                                      <CngGridItem xs={7} sm={7} >
                                                                                                            : {service.transportMode != null ? capitalizeFirstLetter(service.transportMode) : '-'}
                                                                                                            <br />
                                                                                                            : {service.fromCountryName != null ? service.fromCountryName : '-'}, {service.fromLoc != null ? service.fromLoc : '-'}
                                                                                                            <br />
                                                                                                            : {service.toCountryName != null ? service.toCountryName : '-'}, {service.toLoc != null ? service.toLoc : '-'}
                                                                                                      </CngGridItem>
                                                                                                </Grid>
                                                                                          }>
                                                                                          <span>
                                                                                                {getFromLocationAvatar(service)}
                                                                                                {getIconByTransportMode(service.transportMode)}
                                                                                                {getToLocationAvatar(service)}
                                                                                          </span>
                                                                                    </CountryTooltip>

                                                                              </Typography>
                                                                              
                                                                        </CngGridItem>
                                                                  </Grid>

                                                            </div>
                                                            <CngGridItem xs={4} sm={4} style={{ paddingLeft: 5 }}>
                                                                  <Grid><Typography variant='caption' color='textSecondary'>Service code</Typography></Grid>
                                                                  <Grid><Typography variant='body2'>{service.serviceCode}</Typography></Grid>
                                                                  <br />
                                                                  <Grid><Typography variant='caption' color='textSecondary'>Currency</Typography></Grid>
                                                                  <Grid><Typography component={'span'} variant='body2'><Chip label={<b>{service.currencyCode}</b>} size='small' />{service.currencyName}</Typography></Grid>
                                                                  <br />
                                                                  <Grid><Typography variant='caption' color='textSecondary'>Service description</Typography></Grid>
                                                                  <Grid><Typography variant='body2'>{service.serviceDesc}</Typography></Grid>
                                                                  <br />
                                                            </CngGridItem>
                                                            <CngGridItem xs={4} sm={4}>
                                                                  <Grid><Typography variant='caption' color='textSecondary'>Service name</Typography></Grid>
                                                                  <Grid><Typography variant='body2'>{service.serviceName}</Typography></Grid>
                                                                  <br />
                                                                  <Grid><Typography variant='caption' color='textSecondary'>Tax code</Typography></Grid>
                                                                  <Grid><Typography component={'span'} variant='body2'><Chip label={<b>{service.taxPercentage}</b>} size='small' />{service.taxName}</Typography></Grid>
                                                                  <br /><br />
                                                                  <Grid><Typography variant='body2'>Service terms & conditions
                                                                        <Link
                                                                              style={{ color: 'blue', cursor: 'pointer' }}
                                                                              className="ml-2"
                                                                              underline='hover'
                                                                              onClick={() => openServiceTncDialog(details.serviceItems[index].serviceTnc)}>
                                                                              View <FontAwesomeIcon icon={faShareSquare} />
                                                                        </Link>
                                                                  </Typography></Grid>
                                                                  <br />
                                                            </CngGridItem>
                                                            <CngGridItem xs={4} sm={4}>
                                                                  <Grid><Typography variant='caption' color='textSecondary'>Category</Typography></Grid>
                                                                  <Grid><Typography component={'span'} variant='body2'><Chip label={<b>{service.categoryCode}</b>} size='small' />{service.categoryName}</Typography></Grid>
                                                                  <br />
                                                                  {service.inclTax ?
                                                                        <Grid><Typography variant='body2'>Price inclusive of tax</Typography></Grid>
                                                                        :
                                                                        <Grid><Typography variant='body2'>Price exclusive of tax</Typography></Grid>
                                                                  }
                                                                  <br />
                                                            </CngGridItem>



                                                            <div className={classes.break}>
                                                                  <Grid container spacing={1} >
                                                                        <Typography variant='h6' color='textSecondary'>Rates</Typography>
                                                                  </Grid>
                                                            </div>
                                                            <br />
                                                            <CngGridItem xs={12} sm={12} style={{ padding: 10 }}>
                                                                  {service.rateComponent.map((rateComp, attrIndex) => {
                                                                        return (
                                                                              <ServiceRateCardDetailsComponents.FormBody
                                                                                    rateComp={rateComp}
                                                                                    attrIndex={attrIndex}
                                                                                    classes={classes}
                                                                                    {...props}

                                                                              />
                                                                        )
                                                                  })}
                                                            </CngGridItem>
                                                      </Grid>
                                                </Card>
                                          )
                                    })}
                              </CngGridItem>

                        </Grid >
                        <ServiceTncDialog
                              isOpen={isOpen}
                              serviceTnc={serviceTnc}
                              setIsOpen={setIsOpen}
                        />
                  </Card >



            </Grid >
      )

}

const ServiceDetailsComponents = Object.freeze({
      FormBody: FormBody,
      initialValues: initialValues
})

export default ServiceDetailsComponents