import {
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import HtmlTooltip from './HtmlTooltip'
import React from 'react'

const LogoWithTitleVertical = (props) => {
  const { withTooltip, longName, shortName } = props

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      direction='column'
      justify='space-between'
      alignItems='center'
      style={{ padding: 5 }}
    >
      <Box pt={6}>
        {/* TODO : Xihao said logo for cec company temporary hold first;
                   reserved code section
        */}
      </Box>
      <HtmlTooltip title={withTooltip ? <Box>{longName}</Box> : ''}>
        <Typography
          style={{
            textAlign: 'center', fontSize: '12px'
          }}
        >
          {shortName}
        </Typography>
      </HtmlTooltip>
    </Grid>
  )
}

export default LogoWithTitleVertical
