import React, { useState, useEffect, useRef } from 'react'
import { Grid, makeStyles, Typography, CircularProgress, TextField, Button } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PanelLoading from '../landing-page/PanelLoading'
import PanelError from '../landing-page/PanelError'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import LoadMoreObserver from 'src/components/misc/LoadMoreObserver'
import SortDropdown from './SortDropdown'
import ExistingServiceDetailsComponent from './ExistingServiceDetailsComponent'
import Utils from '../landing-page/Utils'

const {
    CngGridItem
} = components


const useStyles = makeStyles(theme => ({
    text: {
        fontSize: '20px',
        fontWeight: '700'
    },
    header: {
        padding: theme.spacing(1),
        fontSize: '.85em',
        display: 'flex',
        alignItems: 'center',
        color: '#8996AF'
    },
    title: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        '& > svg': { margin: theme.spacing(0, 3) }
    },
    load_more: {
        width: '100%',
        margin: '1em 0',
        textAlign: 'center'
    },
    textColor: {
        color: '#8996AF'
    },

    // #8996AF
}))

function ExistingServiceComponent({
    isOpen,
    selectedTab,
    updateSelectedTab,
    setTotal,
    setTotalPre,
    companyId,
    onChange,
    chosenRate,
    ...props
}) {

    const classes = useStyles()
    const { securedSendRequest } = useServices();
    const [transactionResult, setTransactionResult] = useState(null)

    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: false,
        isInitialLoadingError: false
    })
    const [loadingMoreState, setLoadingMoreState] = useState({
        isLoadingMore: false,
        isLoadingMoreError: false,
        isNoMoreData: false
    })
    const [items, setItems] = useState(0);
    const fetchRow = 10;
    const offSetRow = useRef();
    const page = useRef();
    const PublicRateData = useRef([])

    const [searchText, setSearchText] = useState('')
    const [sortType, setSortType] = useState('UPDATED_DATE_DESC')

    function refreshList() {
        callGetPublicRateApi({
            onBeforeStart: () => {
                // reset the ref data
                offSetRow.current = 0
                page.current = 0;
                PublicRateData.current = []

                // reset the load more data
                setLoadingMoreState({
                    isLoadingMore: false,
                    isLoadingMoreError: false,
                    isNoMoreData: false
                })

                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: true })
            },
            onSuccess: (response) => {
                console.debug(response)
                processResponse(response.data)
                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: false })
            },
            onError: (error) => {
                console.error(error)

                setInitialLoadingState({
                    ...initialLoadingState,
                    isInitialLoading: false,
                    isInitialLoadingError: true
                })
            },
            onComplete: () => { /* Do nothing */ },
            selectedTab,

        })
    }

    //** Retrieve total count to display result number in tab 
    function fetchTotalCount(fetchTab) {
        callGetPublicRateApi({
            onBeforeStart: () => { /* Do nothing */ },
            onSuccess: (response) => {
                if (fetchTab === Utils.PublicRateTab.PUBLIC_RATE) {
                    setTotal(response.data.filtered_total_records ? response.data.filtered_total_records : 0);
                } else {
                    setTotalPre(response.data.filtered_total_records ? response.data.filtered_total_records : 0);
                }
            },
            onError: (error) => {
                console.error(error)
            },
            onComplete: () => { /* Do nothing */ },
            fetchTab
        })
    }

    //** Initial load effect. Will also be triggered by the sort option onChange.
    useEffect(() => {
        refreshList()

        return () => {
            securedSendRequest.abort()
        }
    }, [sortType])

    useEffect(() => {
        //fetch another tab for total count 
        if (isOpen) {
            if (selectedTab === Utils.PublicRateTab.PREFERENTIAL_RATE) {
                fetchTotalCount(Utils.PublicRateTab.PUBLIC_RATE);
            } else {
                fetchTotalCount(Utils.PublicRateTab.PREFERENTIAL_RATE);
            }
        }

        return () => {
            securedSendRequest.abort()
        }
    }, [selectedTab])

    useEffect(() => {
        //run once to set selected tab 
        if (isOpen) {
            updateSelectedTab(selectedTab);
        }
        return () => {
            securedSendRequest.abort()
        }
    }, [])

    function processResponse(response) {

        if (response.service_items != null) {
            let newPublicRateData = [...PublicRateData.current, ...response.service_items]
            PublicRateData.current = newPublicRateData

            setTransactionResult(response)

            offSetRow.current = offSetRow.current + fetchRow
            page.current = page.current + 1;
        }

        if (selectedTab == undefined || selectedTab == Utils.PublicRateTab.PUBLIC_RATE) {
            setTotal(response.filtered_total_records ? response.filtered_total_records : 0);
        } else {
            setTotalPre(response.filtered_total_records ? response.filtered_total_records : 0);
        }
        setItems(response.filtered_total_records ? response.filtered_total_records : 0);
    }

    function callGetPublicRateApi({ onBeforeStart, onSuccess, onError, onComplete, fetchTab }) {

        if (onBeforeStart) { onBeforeStart() }

        let url = SCORateMgmtApiUrls.HDR_GET_PUBLIC_RATE
        let data = {
            offSetRow: offSetRow.current,
            fetchRow: fetchRow,
            page: page.current,
            sortOrder: 'asc',
            sortType: sortType,
            selectedTab: fetchTab || selectedTab,
            searchText: searchText,
            companyId: companyId
        }

        securedSendRequest.execute("POST", url, data,
            (response) => {
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                console.error(error)
                if (onError) { onError(error) }
            },
            () => { if (onComplete) { onComplete() } }
        )


    }

    //** the infinite scroll
    const handleInfiniteScroll = (entries, observer) => {

        if (initialLoadingState.isInitialLoading) {
            //** do nothing. it is still initial loading.
            return;
        }

        if (loadingMoreState.isLoadingMore) {
            //** Is still loading more.
            return;
        }

        if (loadingMoreState.isNoMoreData) {
            //** do nothing also. There is no more data to load.
            return;
        }

        callGetPublicRateApi({
            onBeforeStart: () => {
                setLoadingMoreState({ ...loadingMoreState, isLoadingMore: true })

            },
            onSuccess: (response) => {
                console.debug(response)
                if ((response) && (response.data) && response.data.service_items != null) {
                    let newPublicRateData = [...PublicRateData.current, ...response.data.service_items]
                    PublicRateData.current = newPublicRateData

                    offSetRow.current = offSetRow.current + fetchRow
                    page.current = page.current + 1;

                    setLoadingMoreState({ ...loadingMoreState, isLoadingMore: false })
                }
                else {
                    //** no more data.
                    setLoadingMoreState({
                        ...loadingMoreState,
                        isLoadingMore: false,
                        isNoMoreData: true
                    })
                }

            },
            onError: (error) => {
                console.error(error)

                setLoadingMoreState({
                    ...loadingMoreState,
                    isLoadingMore: false,
                    isLoadingMoreError: true
                })
            },
            onComplete: () => { /* Do nothing */ },
            selectedTab
        })
    }
    //* ------------------------------------------------------------------------
    //* Return JSX - Loading...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoading) {
        return (<PanelLoading />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Error...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoadingError) {
        return (<PanelError />)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={1} alignItems='center' key={'publicRate'}>
            {/* The header */}
            <Grid item xs={6}>
                <Typography component='div' className={classes.textColor}>
                    {items>0 ? items + ' Item(s)' :'No record found'}
                </Typography>
            </Grid>
            {/* Search bar */}
            <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Grid container spacing={1} alignItems='center' style={{ textAlign: 'right' }}>
                    <Grid item xs={12}>
                        <TextField variant='outlined'
                            style={{ width: '80%', verticalAlign: 'middle', padding: '0.5em'}}
                            inputProps={{
                                style: {
                                    background: '#5E81F405',
                                    border: '#EEEEEE',
                                    padding: '0.5em',
                                    height: '1em'
                                }
                            }}
                            placeholder={'Search in your entries'}
                            value={searchText}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    refreshList()
                                }
                            }}
                            onChange={(event) => {
                                let value = event.target.value
                                setSearchText(value)
                            }}
                        />
                        <Button variant='contained' color='default'
                            style={{
                                minWidth: '2em',
                                width: '2em',
                                height: '2em',
                                fontSize: '1rem',
                            }}
                            onClick={() => refreshList()}
                        >
                            <FontAwesomeIcon icon={['fal', 'search']} />
                        </Button>
                        <SortDropdown setSortType={setSortType} sortType={sortType} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={1} style={{ backgroundColor: '#eff2f04d', padding: '5px 20px' }}>
                <CngGridItem xs={2} sm={2}>
                    <Typography variant='body2' className={classes.textColor}>SERVICE CODE</Typography>
                </CngGridItem>
                <CngGridItem xs={3} sm={3}>
                    <Typography variant='body2' className={classes.textColor}>SERVICE NAME</Typography>
                </CngGridItem>
                <CngGridItem xs={5} sm={5}>
                    <Typography variant='body2' className={classes.textColor}>UOM / RATE CARD / RANGE & UNIT PRICE ( MIN. & MAX. )</Typography>
                </CngGridItem>
                <CngGridItem xs={2} sm={2}>
                    <Typography variant='body2' className={classes.textColor}>LAST MODIFIED DATE</Typography>
                </CngGridItem>
            </Grid>
            <Grid item xs={12} container spacing={0}>
                {
                    PublicRateData.current.map((transaction, index) => {
                        return (
                            <>

                                <ExistingServiceDetailsComponent
                                    index={index}
                                    details={transaction}
                                    showNotification={props.showNotification}
                                    refreshList={refreshList}
                                    selectedTab={selectedTab}
                                    onChange={onChange}
                                    chosenRate={chosenRate}
                                />
                            </>

                        )
                    })
                }
                <Grid className={classes.load_more} item xs={12}>
                    {(
                        () => { // use annon function to return if-else condition.
                            if (loadingMoreState.isNoMoreData) {
                                return (<span>No more data to load.</span>)
                            }
                            else if (loadingMoreState.isLoadingMoreError) {
                                return (<span>Error loading more data.</span>)
                            }
                            else {
                                return (
                                    <LoadMoreObserver onInView={handleInfiniteScroll}>
                                        {
                                            loadingMoreState.isLoadingMore && // show spinner only when isLoadingMore
                                            <CircularProgress />
                                        }
                                    </LoadMoreObserver>
                                )
                            }
                        }
                    )()}
                </Grid>
            </Grid>
        </Grid >
        //* End JSX ------------------------------------------------------------
    );
    //* End of function --------------------------------------------------------
}

export default ExistingServiceComponent
