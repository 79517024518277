import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import UpdateContractStatusDialogContext from './UpdateContractStatusDialogContext.js'
import UpdateContractStatusDialogForm from './UpdateContractStatusDialogForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function UpdateContractStatusDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  dialogTitle,
  proceedAction,
  statusAction
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  return (
    <UpdateContractStatusDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        proceedAction,
        statusAction,
        form: {
          isSubmitting,
          setSubmitting
        },

      }}
    >
      <CngDialog
        customDialogContent={
          <Paper spacing={2}>
            <div style={{ overflow: 'hidden' }}>
              <UpdateContractStatusDialogForm />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            <Typography variant='h5' className='font-bolder' style={{padding:'8px'}}>
              {dialogTitle}
            </Typography>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </UpdateContractStatusDialogContext.Provider>
  )
}

export default UpdateContractStatusDialog
