
import {
      Grid
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import React, { useState, useEffect } from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import TranslationText from '../TranslationText'
import { components, constants } from 'cng-web-lib'
import ExistingServiceDialog from './ExistingServiceDialog'
import ProvideRateServiceComponents from './ProvideRateServiceComponents'
import FooterAddComponent from './FooterAddComponent'

const {
      CngGridItem,
} = components

const {
      filter: { EQUAL }
} = constants

const initialValues = {
      serviceItems: [
            {
                  id: -1,
                  refId: uuid(),
                  parentServiceId: '',
                  serviceCode: '',
                  serviceName: '',
                  categoryId: '',
                  currencyCode: '',
                  taxCode: '',
                  serviceDesc: '',
                  serviceTnc: '',
                  inclTax: '',
                  rateComponent: [],

            }
      ],
      deletedServiceId: []
}

const FormBody = props => {
      const { remove } = useFieldArray({ name: "serviceItems" })
      const { trigger, setValue } = useFormContext()

      const serviceItemForm = useWatch({ name: 'serviceItems' })
      const deletedServiceIdList = useWatch({ name: 'deletedServiceId' })

      const translatedTextsObject = TranslationText()

      const [isOpen, setIsOpen] = useState(false);
      const [companyId, setCompanyId] = useState();

      // RateCardComponentPriceDTO 
      const rate = {
            id: uuid(),
            priceId: '',
            startRange: '',
            unitPrice: ''
      }

      // RateCardComponentDTO
      const rateItem = {
            id: uuid(),  // for react reference.
            componentId: '',
            componentCode: '',
            componentName: '',
            componentCalById: '',
            componentUOMId: '',
            componentCalMetId: '',

            diffTax: false,
            diffTaxCode: '',
            diffTaxPercentage: '',
            diffInclTax: false,

            componentDescription: '',
            rate: [rate],

            rateItemsExpanded: false,
            accordionId: uuid()

      }

      // RateCardDTO
      const serviceItem = {
            id: -1,
            refId: uuid(),
            serviceId: '',
            parentServiceId: '',
            serviceCode: '',
            serviceName: '',
            categoryId: '',
            currencyCode: '',
            taxCode: '',
            serviceDesc: '',
            serviceTnc: '',
            inclTax: false,
            rateComponent: [rateItem],
            transportMode: '',
            fromCountry: '',
            toCountry: '',
            fromLoc: '',
            toLoc: '' 

      }

      useEffect(() => {
            const cont = { ...serviceItem }
            setValue('serviceItems', [cont])
            setValue("deletedServiceId", [])

            if (props.data != null && props.data.content != null) {
                  if (props.data.content[0].issuingCompanyId != null) {
                        setCompanyId(props.data.content[0].issuingCompanyId);
                  }
                  if (props.data.content[0].serviceItems != null
                        && props.data.content[0].serviceItems.length > 0) {
                        setValue("serviceItems", props.data.content[0].serviceItems)
                  }
            }
      }, [])

      const handleRateCardDetailsExpand = (index, attrIndex, name) => {
            
            const list = [...serviceItemForm]
            const boolean = list[index].rateComponent[attrIndex][name];
            list[index].rateComponent[attrIndex][name] = !boolean

            setValue('serviceItems', list)
      };

      /** Service Handling **/
      // handle input change
      const handleInputChange = (e, index) => {

            const { name, value } = e.target
            const list = [...serviceItemForm]
            list[index][name.split('.')[1]] = value
            setValue('serviceItems', list)
      };

      // handle text editor change
      const handleTNCChange = (value, index, name) => {
            const list = [...serviceItemForm]
            if (value != undefined && value != null) {
                  let string = JSON.stringify(value)
                  list[index][name] = string
                  setValue('serviceItems', list)
            }else{
                  list[index][name] = ''
                  setValue('serviceItems', list)
            }

      };

      // handle select dropdown and autocomplete
      const handleDropdownChange = (e, index, name) => {
            if (e) {
                  const value = e
                  const list = [...serviceItemForm]
                  list[index][name] = value
                  setValue('serviceItems', list)
            }
      };

      // handle checkbox change
      const handleCheckboxChange = (e, index, name) => {
            if (e) {
                  const list = [...serviceItemForm]
                  const boolean = list[index][name];
                  list[index][name] = !boolean
                  setValue('serviceItems', list)
            }
      };

      const handleAddService = (index) => {
            const addServiceItem = { ...serviceItem }
            addServiceItem.id = null
            addServiceItem.refId = uuid()
            setValue('serviceItems', [...serviceItemForm, addServiceItem])
      };

      const handleDeleteService = (index) => {
            const list = [...serviceItemForm]
            const toDelete = [...deletedServiceIdList]
            if (list[index].serviceId != null && list[index].serviceId != '') {
                  toDelete.push(list[index].serviceId)
                  setValue('deletedServiceId', toDelete)
            }
            const deletedList = [...list.slice(0, index), ...list.slice(index + 1)]

            if(deletedList.length==0){
                  const addServiceItem = serviceItem 
                  
                  addServiceItem.serviceId = null
                  addServiceItem.refId = uuid()
                 
                  deletedList.push(addServiceItem)

                  setValue('serviceItems', [...deletedList])
            }else{
                  setValue('serviceItems', [...deletedList])
            }
           

      }

      const handleCopyService = (index) => {
            const list = [...serviceItemForm]

            const copy = list.map(item => ({ ...item }))
            const copyService = copy[index]
            copyService.serviceId = null
            copyService.refId = uuid()
            const copyRateComponent = copyService.rateComponent.map(i => ({ ...i }))

            copyRateComponent.forEach(r => {
                  r.id = uuid();

                  //To reset rate price uuid
                  let copyRatePrice = r.rate.map(i => ({ ...i }));
                  copyRatePrice.forEach(p => p.id = uuid());
                  r.rate = copyRatePrice;
            })

            copyService.rateComponent = copyRateComponent
            setValue('serviceItems', [...serviceItemForm, copyService])
            
      };

      const handleCopyPublicService = (newServiceList) => {

            const list = [...serviceItemForm]
            const newList = newServiceList.map(item => ({ ...item }))
            const copy = list.map(item => ({ ...item }))
            newList.forEach(copyService => {
                  if (copyService.serviceId != null) {
                        copyService.parentServiceId = copyService.serviceId;
                  }

                  copyService.serviceId = null
                  copyService.refId = uuid()
                  let copyRateComponent = copyService.rateComponent.map(i => ({ ...i }))

                  copyRateComponent.forEach(r2 => {
                        r2.id = uuid();
                        //To reset rate price uuid
                        let copyRatePrice = r2.rate.map(i => ({ ...i }));
                        copyRatePrice.forEach(p => p.id = uuid());
                        r2.rate = copyRatePrice;
                  })
                  
                  copyService.rateComponent = copyRateComponent
                  copy.push(copyService)
                  
            })

            setValue('serviceItems', copy)

      };

      const checkToRemoveEmpty = () => {
            const list = [...serviceItemForm]
            if(list.length>0){
                  const firstArray = list[0]
                  if(firstArray.serviceCode=='' && firstArray.serviceName==''){
                        const elmUl = document.getElementById('deleteService0')
                        elmUl.click();
                  }
            }
           
      }

      /** Rate Component Handling **/
      // handle input change
      const handleRateInputChange = (e, index, attrIndex) => {
            const { name, value } = e.target
            const list = [...serviceItemForm]
            list[index].rateComponent[attrIndex][name.split('.')[2]] = value
            setValue('serviceItems', list)
      };

      // handle rate component select dropdown and autocomplete
      const handleRateDropdownChange = (e, index, attrIndex, name) => {
            if (e) {
                  const value = e.value
                  const list = [...serviceItemForm]

                  if (name == 'diffTaxCode') {
                        //update diff percentage 
                        const percentage = e.data.taxPercent
                        const diffTaxPercentage = 'diffTaxPercentage'
                        list[index].rateComponent[attrIndex][diffTaxPercentage] = percentage
                  }

                  list[index].rateComponent[attrIndex][name] = value
                  setValue('serviceItems', list)
            }
      };

      // handle rate component select dropdown and autocomplete
      const handleRateTaxPercentageChange = (e, index, attrIndex, name) => {
            const value = e.value
            const list = [...serviceItemForm]
            list[index].rateComponent[attrIndex][name] = value
            setValue('serviceItems', list)
      };

      // handle rate component checkbox change
      const handleRateCheckboxChange = (e, index, attrIndex, name) => {
            if (e) {
                  const list = [...serviceItemForm]
                  const boolean = list[index].rateComponent[attrIndex][name];

                  list[index].rateComponent[attrIndex][name] = !boolean

                  if (name == 'diffTax' && !boolean) {
                        //Upon uncheck diff tax, update different tax info to initial value
                        list[index].rateComponent[attrIndex]['diffTaxCode'] = ''
                        list[index].rateComponent[attrIndex]['diffTaxPercentage'] = ''
                        list[index].rateComponent[attrIndex]['diffInclTax'] = false
                  }
                  setValue('serviceItems', list)
            }
      };

      const handleAddRateCard = (index, existingRateCard) => {
            const rateCardList = existingRateCard
            const addNewRateCard = rateItem
            rateCardList.push(addNewRateCard)

            const list = [...serviceItemForm]
            list[index].rateComponent = rateCardList
            setValue('serviceItems', list)
      };

      const handleDeleteRateCard = (index, attrIndex) => {
            const list = [...serviceItemForm]
            list[index].rateComponent.splice(attrIndex, 1);
            remove(list[index].rateComponent[attrIndex])
            setValue("serviceItems", list)
      };

      const handleCopyRateCard = (index, existingRateCard, attrIndex) => {
            const arr = [...existingRateCard]
            const copy = arr.map(item => ({ ...item, id: uuid() }))
            const copyRatePrice = copy[attrIndex].rate.map(i => ({ ...i }))
            copyRatePrice.forEach(r => r.id = uuid())
            copy[attrIndex].rate = copyRatePrice

            const rateCardList = [...existingRateCard]
            rateCardList.push(copy[attrIndex])

            const list = [...serviceItemForm]
            list[index].rateComponent = rateCardList
            setValue("serviceItems", list)
      };

      /** Rate Price Handling */
      // handle input change
      const handleRatePriceInputChange = (e, index, attrIndex, priceIndex) => {
            const { name, value } = e.target
            const list = [...serviceItemForm]
            list[index].rateComponent[attrIndex].rate[priceIndex][name.split('.')[3]] = value
            setValue('serviceItems', list)
      };

      const handleAddRatePrice = (index, attrIndex, existingRatePrice) => {
            const rateCardPriceList = existingRatePrice
            const addNewRateCardPrice = rate
            rateCardPriceList.push(addNewRateCardPrice)

            const list = [...serviceItemForm]
            list[index].rateComponent[attrIndex].rate = rateCardPriceList
            setValue("serviceItems", list)
      };

      const handleDeleteRatePrice = (index, attrIndex, priceIndex) => {
            const list = [...serviceItemForm]
            list[index].rateComponent[attrIndex].rate.splice(priceIndex, 1);
            remove(list[index].rateComponent[attrIndex].rate[priceIndex])
            setValue("serviceItems", list)
      };

      const handleCopyRatePrice = (index, attrIndex, priceIndex, existingRatePrice) => {
            const arr = [...existingRatePrice]
            const copy = arr.map(item => ({ ...item }))
            copy[priceIndex].id = uuid()
            const rateCardPriceList = [...existingRatePrice]
            rateCardPriceList.push(copy[priceIndex])

            const list = [...serviceItemForm]
            list[index].rateComponent[attrIndex].rate = rateCardPriceList
            setValue("serviceItems", list)
      };

      const openExistingServiceDialog = () => {
            setIsOpen(true);
      }

      return (
            <Grid container style={{ padding: 15, display: 'block' }} key={'comp'}>
                  <AccordionHeaderComponent title={translatedTextsObject.contractRateCardSubTitle} />

                  {serviceItemForm != undefined && serviceItemForm.length > 0
                        ?
                        <>
                              {serviceItemForm.map((service, index) => {

                                    return (<>
                                          <ProvideRateServiceComponents
                                                key={service.serviceId == null ? service.refId : service.serviceId}
                                                service={service}
                                                index={index}
                                                handleCopyService={handleCopyService}
                                                handleDeleteService={handleDeleteService}

                                                handleAddRateCard={handleAddRateCard}
                                                handleCopyRateCard={handleCopyRateCard}
                                                handleDeleteRateCard={handleDeleteRateCard}

                                                //Service Props
                                                handleInputChange={handleInputChange}
                                                handleTNCChange={handleTNCChange}
                                                handleDropdownChange={handleDropdownChange}
                                                handleCheckboxChange={handleCheckboxChange}

                                                //Rate Component Props
                                                handleRateInputChange={handleRateInputChange}
                                                handleRateDropdownChange={handleRateDropdownChange}
                                                handleRateCheckboxChange={handleRateCheckboxChange}
                                                handleRateTaxPercentageChange={handleRateTaxPercentageChange}

                                                //Rate Components Price Props
                                                handleRatePriceInputChange={handleRatePriceInputChange}
                                                handleAddRatePrice={handleAddRatePrice}
                                                handleCopyRatePrice={handleCopyRatePrice}
                                                handleDeleteRatePrice={handleDeleteRatePrice}

                                                handleRateCardDetailsExpand={handleRateCardDetailsExpand}

                                                setValue={setValue}
                                          />
                                          <ExistingServiceDialog
                                                isOpen={isOpen}
                                                handleCopyPublicService={handleCopyPublicService}
                                                setIsOpen={setIsOpen}
                                                companyId={companyId}
                                                showNotification={props.showNotification}
                                                checkToRemoveEmpty={checkToRemoveEmpty}
                                          />
                                    </>
                                    )
                              })
                              }
                        </>
                        : <></>
                  }


                  <br />
                  <Grid container style={{ marginRight: '-10px', marginLeft: '-10px' }}>
                        <CngGridItem xs={12} sm={6} >
                              <FooterAddComponent
                                    handleAddChild={openExistingServiceDialog}
                                    footerText='Add from existing service'
                                    copy={true}
                              />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={6}>
                              <FooterAddComponent
                                    handleAddChild={handleAddService}
                                    footerText='Create new blank service'
                              />
                        </CngGridItem>

                  </Grid>


            </Grid>

      )

}

const ProvideRateComponents = Object.freeze({
      FormBody: FormBody,
      initialValues: initialValues
})

export default ProvideRateComponents