import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import ServiceDetailsComponents from './ServiceDetailsComponents'
// Moment library
import Moment from 'moment';
import 'moment-timezone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  ...ServiceDetailsComponents.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function _formatDate(timestamp, timezone, format) {
  let moment = Moment(timestamp)

  if (timezone) {
    moment.tz(timezone)
  }

  return (moment.format(format))
}

const DATETIME_FORMAT = 'DD MMM YYYY HH:mm'
const DATE_FORMAT = 'DD MMM YYYY'
const TIME_FORMAT = 'HH:mm'

function FormFields({ history, showNotification, loading, id, data, page }) {

  const date = new Date();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  return (
    <>
      <Grid container style={{ padding: 15 }}>
        <Grid item xs={12} sm={12} >
          <h4>Contract issued <FontAwesomeIcon icon={['fal', 'fa-circle-check']} style={{color: 'green'}}/></h4>
          <h6>Issued on <b>{_formatDate(date, null, DATE_FORMAT)}, {_formatDate(date, null, TIME_FORMAT)}</b></h6>
          <br/>
        </Grid>

        <ServiceDetailsComponents.FormBody
          showNotification={showNotification}
          data={data}
          id={id}
          page={page}
        />

      </Grid>


    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }
  // valid date
  localData.validTo = _formatDate(serverData.validTo, null, DATETIME_FORMAT)
  localData.validFrom = _formatDate(serverData.validFrom, null, DATETIME_FORMAT)

  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FinishForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FinishForm
