
import {
      Card,
      Grid,
      Chip,
      IconButton,
      InputAdornment
} from '@material-ui/core'
import React from 'react'
import TranslationText from '../TranslationText'
import { components, constants } from 'cng-web-lib'
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AddBoxIcon from '@material-ui/icons/AddBox'
const {
      form: {
            adapter: {
                  useFormAdapter: { useField, useFormikContext }
            },
            field: {
                  CngTextField
            }
      },
      CngGridItem,
} = components

const {
      filter: { EQUAL }
} = constants

const ProvideRateServiceRateCardPriceComponents = (props) => {
      const index = props.index;
      const attrIndex = props.attrIndex;
      const translatedTextsObject = TranslationText()

      return (
            props.rateComponent.rate.map((price, priceIndex) => {
                  return (
                        <Grid container spacing={2} style={{ marginTop: 5 }} key={'price_' + priceIndex}>
                              <CngGridItem xs={12} sm={12}>
                                    <Card>
                                          <Grid container spacing={2} style={{ padding: 15 }}>
                                                <CngGridItem xs={12} sm={4}>
                                                      <CngTextField
                                                            key={`serviceItems[${index}].rateComponent[${attrIndex}].rate[${priceIndex}].startRange`}
                                                            name={`serviceItems[${index}].rateComponent[${attrIndex}].rate[${priceIndex}].startRange`}
                                                            label={translatedTextsObject.startRange}
                                                            onChange={(e) => props.handleRatePriceInputChange(e, index, attrIndex, priceIndex)}
                                                            required
                                                      />
                                                </CngGridItem>
                                                <CngGridItem xs={12} sm={5}>
                                                      <CngTextField
                                                            key={`serviceItems[${index}].rateComponent[${attrIndex}].rate[${priceIndex}].unitPrice`}
                                                            name={`serviceItems[${index}].rateComponent[${attrIndex}].rate[${priceIndex}].unitPrice`}
                                                            label={translatedTextsObject.unitPrice}
                                                            onChange={(e) => props.handleRatePriceInputChange(e, index, attrIndex, priceIndex)}
                                                            InputProps={{
                                                                  startAdornment: (<InputAdornment position="start"><Card><Chip label={<b>SGD</b>} size='small' /> </Card></InputAdornment>)
                                                            }}

                                                            required
                                                      />
                                                </CngGridItem>
                                                <CngGridItem xs={4} sm={3}  >
                                                      <Grid container justify="flex-end">
                                                            {priceIndex == 0
                                                                  ?
                                                                  <></>
                                                                  :
                                                                  <IconButton aria-label="remove" disabled={false}
                                                                        style={{ backgroundColor: 'white', padding: 10, borderRadius: 4 }}
                                                                        onClick={() => props.handleDeleteRatePrice(index, attrIndex, priceIndex)}>
                                                                        <DeleteIcon />
                                                                  </IconButton>
                                                            }

                                                            <IconButton aria-label="copy" disabled={false}
                                                                  style={{ backgroundColor: 'white', padding: 10, borderRadius: 4, marginRight: 5 }}
                                                                  onClick={() => props.handleCopyRatePrice(index, attrIndex, priceIndex, props.rateComponent.rate)}>
                                                                  <FileCopyIcon />
                                                            </IconButton>
                                                            <IconButton aria-label="add" disabled={false}
                                                                  style={{ backgroundColor: 'white', padding: 10, borderRadius: 4, marginRight: 5 }}
                                                                  onClick={() => props.handleAddRatePrice(index, attrIndex, props.rateComponent.rate)}>
                                                                  <AddBoxIcon />
                                                            </IconButton>
                                                      </Grid>
                                                </CngGridItem>
                                          </Grid>
                                    </Card>
                              </CngGridItem>
                        </Grid>
                  )
            })

      )

}

export default ProvideRateServiceRateCardPriceComponents