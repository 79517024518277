import React, { useState, useEffect } from 'react'
import { CardContent, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import TranslationText from '../../TranslationText'

function DateFilter({
    clearAllFilterClickedTime,
    onChange,
    dateFilter
}) {
  
    const translatedTextObject = TranslationText()
    const DEFAULT_VALUE = 'ALL'
    const [value, setValue] = useState(dateFilter ? dateFilter : DEFAULT_VALUE)

    const handleChange = (event) => {
        setValue(event.target.value)

        if (onChange) {
            onChange(event.target.value)

        }
    }

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setValue(DEFAULT_VALUE)
        if (onChange) {
            onChange(DEFAULT_VALUE)
        }
    }, [clearAllFilterClickedTime])

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container>
            <Grid item xs={12}>
                <CardContent style={{
                    background: '#8996AF10',
                    padding: '1em',
                    marginTop: '1em',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                 
                }}>
                    {translatedTextObject.filterDateTitle}
                </CardContent>
            </Grid>
            <Grid item xs={12} >
                <CardContent>
                    <RadioGroup value={value} onChange={handleChange}>
                        <FormControlLabel value='ALL' control={<Radio />} label={translatedTextObject.filterLabelAll} />
                        <FormControlLabel value='DAYS_15' control={<Radio />} label={translatedTextObject.filterLabel15} />
                        <FormControlLabel value='DAYS_30' control={<Radio />} label={translatedTextObject.filterLabel30} />
                        <FormControlLabel value='DAYS_60' control={<Radio />} label={translatedTextObject.filterLabel60} />
                    </RadioGroup>
                </CardContent>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default DateFilter
