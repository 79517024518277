import {
      Grid, Typography, Card
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { components, useServices } from 'cng-web-lib'
import TranslationText from '../TranslationText'
import 'moment-timezone';


const {
      CngGridItem,
} = components

const initialValues = Object.freeze({
      contractId: "",
      contractNo: "",
      issuerName: "",
      customerName: "",
      validFrom: "",
      validTo: "",
      timezoneStr: ""
})


const useStyles = makeStyles(theme => ({
      box_line: {
            border: '1px solid #F5F5FA',
            marginBottom: theme.spacing(1.5),
            padding: theme.spacing(0.75)
      },
      headerTitle: {
            // backgroundColor: '#f4f4fc',
            //#eff2f04d
            width: '102%',
            height: '46px',
            marginRight: '-6px',
            paddingTop: '16px',
            paddingLeft: '16px',
            borderRadius: '8px',
            fontSize: 'medium',
            fontWeight: 'bolder'
      },
      header: {
            backgroundColor: '#f4f4fc',
            //#eff2f04d
            width: '100%',
            height: '46px',
            marginRight: '-6px',
            paddingTop: '16px',
            paddingLeft: '16px',
            borderRadius: '8px',

      },
      header_right: {
            textAlign: 'right',
            marginLeft: '-10px'
      },
      body: {
            padding: '15px'
      },
      break: {
            backgroundColor: '#f4f4fc',
            //#eff2f04d
            width: '102%',
            height: '40px',
            marginRight: '-6px',
            paddingTop: '16px',
            paddingLeft: '16px',
            marginBottom: '15px'

      },

}))



const FormBody = props => {
      const translatedTextsObject = TranslationText()
      const { fetchRecords } = useServices()
      const classes = useStyles()
      const details = props.data.content[0];
      const [userList, setUserList] = useState([]);

      useEffect(() => {
            fetchRecords.execute(
                  `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`,
                  undefined,
                  (data) => {
                        
                        setUserList(data.content)
                  },
                  (error) => {
                        console.log(error)
                  }
            )

      }, [])

      function getUsername(userId) {
            let userObj = userList.filter((u) => u.id == userId)
            if (userObj.length > 0) {
                  return userObj[0].userProfile.name1 + ' ' + userObj[0].userProfile.name3
            }
      }

      return (
            <Grid item xs={12} sm={12} >
                  <Card variant='outlined' className={classes.box_line} spacing={2}>
                        <Grid container spacing={1} >
                              <div className={classes.headerTitle}>
                                    <Grid container spacing={1} >
                                          <span>{translatedTextsObject.contractHistorySubTitle}</span>
                                    </Grid>
                              </div>
                              <Grid container style={{ margin: 10 }}>
                                    <CngGridItem xs={4} sm={4} >
                                          <Typography variant='body1' color='textSecondary'>Status</Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}>
                                          <Typography variant='body1' color='textSecondary'>Updated by</Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4} >
                                          <Typography variant='body1' color='textSecondary'>Changes</Typography>
                                    </CngGridItem>
                                    <hr style={{ borderTop: '1px solid #f4f4fc', width: '100%' }} />

                                    {details.history.map((hist, index) => {
                                          return (
                                                <>
                                                      <CngGridItem xs={4} sm={4} >
                                                            <Typography variant='body2'>{hist.contractStatus}</Typography>
                                                      </CngGridItem>
                                                      <CngGridItem xs={4} sm={4} >
                                                            <Typography variant='body2' >{getUsername(hist.createdBy)}</Typography>
                                                            <Typography variant='caption' color='textSecondary'>{hist.company}</Typography>
                                                      </CngGridItem>
                                                      <CngGridItem xs={4} sm={4} >
                                                            <Typography variant='body2' >Status change</Typography>
                                                            <Typography variant='caption' color='textSecondary'>{props.formatDate(hist.createdDate, null, props.DATETIME_FORMAT)}</Typography>
                                                      </CngGridItem>
                                                      <hr style={{ borderTop: '1px solid #f4f4fc', width: '100%' }} />  
                                                </>
                                          )
                                    })}

                                    {/* Draft Created Date */}
                                    <CngGridItem xs={4} sm={4} >
                                          <Typography variant='body2'>Draft</Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4} >
                                          <Typography variant='body2' >{getUsername(details.createdBy)}</Typography>
                                          <Typography variant='caption' color='textSecondary'>{details.issuerName}</Typography>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4} >
                                          <Typography variant='body2' >New Contract</Typography>
                                          <Typography variant='caption' color='textSecondary'>{props.formatDate(details.createdDate, null, props.DATETIME_FORMAT)}</Typography>
                                    </CngGridItem>
                                  
                              </Grid>
                        </Grid>
                  </Card>

            </Grid >
      )

}

const ViewHistoryComponents = Object.freeze({
      FormBody: FormBody,
      initialValues: initialValues
})

export default ViewHistoryComponents