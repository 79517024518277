import { Box, Grid, Paper, Typography, Divider } from '@material-ui/core'
import { components } from 'cng-web-lib'
import draftToHtml from 'draftjs-to-html'
import React from 'react'

const {
    CngDialog,
    button: { CngPrimaryButton }

} = components

const ServiceTncDialog = ({ isOpen, serviceTnc, setIsOpen, showNotification }) => {

    const jsonObj = tryParseJSONObject(serviceTnc)

    function tryParseJSONObject(jsonString) {
        try {
            var o = JSON.parse(jsonString);

            if (o && typeof o == 'object') {
                return o;
            }
        } catch (e) { }
  
        return false
    }

    return (
        <CngDialog
            open={isOpen}
            fullWidth
            maxWidth='xl'
            customDialogContent={
                <Paper>
                    <Box m={3} mt={3}>
                        <Typography variant="h5" className='font-bolder mb-2'>Service term & conditions</Typography>

                        <Divider />
                        <Box mt={2}>
                            <Box my={2} mx={2}>
                                <Typography variant='body1' color='textSecondary'>Term & conditions</Typography>
                                <br />
                                {jsonObj 
                                    ?
                                    <div
                                        dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(serviceTnc)) }}
                                    />
                                    :
                                    serviceTnc}
                            </Box>
                        </Box>
                        <Divider />
                        <Box my={1}>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                </Grid>
                                <Grid item>
                                    <CngPrimaryButton
                                        onClick={() => setIsOpen(false)}>
                                        {"Okay"}
                                    </CngPrimaryButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                </Paper>
            }

        />
    );
}

export default ServiceTncDialog;