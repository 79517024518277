import { Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import TranslationText from './TranslationText'
import Utils from './landing-page/Utils'
import FilterPanel from './landing-page/filter-panel/FilterPanel'
import DateFilter from './landing-page/filter-panel/DateFilter'
import StatusFilter from './landing-page/filter-panel/StatusFilter'
import ContractView_LeftPanel from './landing-page/ContractView_LeftPanel'
import SearchPanel from './landing-page/filter-panel/SearchPanel'
import CustomerFilter from './landing-page/filter-panel/CustomerFilter'

const {
      CngTabs
} = components

function ContractLandingPage(props) {
      const {
            showNotification
      } = props

      const translatedTextObject = TranslationText()

      const [clearAllFilterClickedTime, setClearAllFilterClickedTime] = useState(new Date())
      const [dateFilter, setDateFilter] = useState('ALL')
      const [statusFilter, setStatusFilter] = useState(['ALL'])
      const [customerFilter, setCustomerFilter] = useState(['ALL'])
      const [searchText, setSearchText] = useState('')

      const [selectedTab, setSelectedTab] = useState(Utils.Tab.CONTRACT)
      const [totalCont, setTotalCont] = useState(0);
      const [totalContRec, setTotalContRec] = useState(0);

      const [filterData, setFilterData] = useState({
            dateFilter: 'ALL',
            statusFilter: ['ALL'],
            customerFilter: ['ALL']
      })

      const applyFilterClickHandler = (event) => {

            let newFilterData = {
                  dateFilter: dateFilter,
                  statusFilter: statusFilter,
                  customerFilter: customerFilter,
            }

            setFilterData(newFilterData)
            return (false);
      }

      function updateSelectedTab(value) {
            setSelectedTab(value);
      }

      useEffect(() => {
            const className =  document.getElementsByName('back-to-top-anchor');
            if(className){
                  className[0].parentElement.parentElement.scrollIntoView(true)
            }
        
      }, [])


      return (

            <Grid container spacing={3} name="back-to-top-anchor">
                  <Grid item xs={9}>
                        <CngTabs
                              headerColor='primary'
                              tabs={[
                                    {
                                          tabName: translatedTextObject.tabMyContracts + ' (' + totalCont + ') ',
                                          tabContent: (
                                                <>
                                                      <ContractView_LeftPanel
                                                            searchText={searchText}
                                                            filterData={filterData}
                                                            selectedTab={Utils.Tab.CONTRACT}
                                                            updateSelectedTab={updateSelectedTab}
                                                            setTotal={setTotalCont}
                                                            setTotalRec={setTotalContRec}
                                                            showNotification={showNotification}
                                                      />
                                                </>
                                          )

                                    },
                                    {
                                          tabName: translatedTextObject.tabContractReceived + ' (' + totalContRec + ') ',
                                          tabContent: (
                                                <>
                                                      <ContractView_LeftPanel
                                                            searchText={searchText}
                                                            filterData={filterData}
                                                            selectedTab={Utils.Tab.CONTRACT_REC}
                                                            updateSelectedTab={updateSelectedTab}
                                                            setTotal={setTotalCont}
                                                            setTotalRec={setTotalContRec}
                                                            showNotification={showNotification}

                                                      />
                                                </>
                                          )
                                    }
                              ]}
                        />
                  </Grid>

                  {/* Right Filter Panel */}
                  <Grid item xs={3} container spacing={3} alignContent='flex-start'>
                        <Grid item xs={12}>
                              <SearchPanel
                                    placeholderText={translatedTextObject.searchPlaceholder}
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    onApplyClicked={applyFilterClickHandler}
                              />
                        </Grid>
                        <Grid item xs={12}>
                              <FilterPanel
                                    onClearAllClicked={(event) => {
                                          setClearAllFilterClickedTime(new Date())
                                          return (false)
                                    }}
                                    onApplyClicked={applyFilterClickHandler}
                              >
                                    <DateFilter
                                          title={translatedTextObject.filterDateTitle}
                                          clearAllFilterClickedTime={clearAllFilterClickedTime}
                                          onChange={(value) => {
                                                setDateFilter(value)
                                          }}
                                          dateFiler={dateFilter}
                                    />
                                    <StatusFilter
                                          clearAllFilterClickedTime={clearAllFilterClickedTime}
                                          selectedTab={selectedTab}
                                          onChange={(value) => {
                                                setStatusFilter(value)
                                          }}
                                    />
                                    <CustomerFilter
                                          clearAllFilterClickedTime={clearAllFilterClickedTime}
                                          selectedTab={selectedTab}
                                          onChange={(value) => {
                                                setCustomerFilter(value)
                                          }}
                                    />
                              </FilterPanel>
                        </Grid>
                  </Grid>
            </Grid>
      )
}

export default ContractLandingPage