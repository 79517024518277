import { Grid, CircularProgress } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import ViewDetailsForm from './view-page/ViewDetailsForm'
import Utils from './landing-page/Utils'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import { useParams, useHistory } from 'react-router-dom'

const {
      table: { useDefaultNotification },
      button: { CngPrimaryButton }
} = components

function ViewPage(props) {
      const {
            showNotification
      } = props

      const {
            error: showErrorNotification
      } = useDefaultNotification(showNotification)

      const { id } = useParams()
      const { fetchRecords } = useServices();
      const history = useHistory()
      const [data, setData] = useState(null)

      useEffect(() => {
            if (id != null) {
                  getDataView()
            }
            return () => {
                  fetchRecords.abort()
            }

      }, [])

      function refreshList() {
            getDataView()
      }

      const getDataView = () => {
            const onSuccess = (response) => {
                  setData(response)
            }

            const onError = (error) => {
                  showErrorNotification('fetch error')
            }

            fetchRecords.execute(
                  SCORateMgmtApiUrls.HDR_GET_CONTRACT,
                  {
                        customData: {},
                        filters: [
                              {
                                    field: 'id',
                                    operator: 'EQUAL',
                                    value: id
                              },
                              {
                                    field: 'view',
                                    operator: 'EQUAL',
                                    value: true
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }


      return (

            <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>

                        {data != null ?
                              <ViewDetailsForm.FormFields
                                    history={history}
                                    id={id}
                                    data={data}
                                    page={'Finalise'}
                                    showNotification={showNotification}
                                    refreshList={refreshList}
                                    selectedTab={Utils.Tab.CONTRACT}
                              /> :
                              <><CircularProgress /></>

                        }

                  </Grid>
            </Grid>
      )
}

export default ViewPage