import {
  Tooltip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const CountryTooltip = withStyles((theme) => ({
  tooltip: {
    color: '#fcfcfc',
    background: '#1C1D21B7',
    opacity: 1,
    width: 250,
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(11),
    border: '1px solid #dadde9',
    margin: '0px 0px 0px 20px'
  }
}))(Tooltip);

export default CountryTooltip

