import {
  Tooltip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    color: '#fcfcfc',
    background: '#1C1D21B7',
    opacity: 1,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default HtmlTooltip