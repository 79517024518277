import React, { useState, useEffect } from 'react'
import { Checkbox, Typography, CardContent, Grid, FormControlLabel } from '@material-ui/core'
import { useServices } from 'cng-web-lib'
import { makeStyles } from '@material-ui/styles'
import LoadingSpinnerLabel from 'src/components/label/LoadingSpinnerLabel'
import ErrorRetrievingDataLabel from 'src/components/label/ErrorRetrievingDataLabel'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'
import TranslationText from '../../TranslationText'
import Utils from '../Utils'

function CustomerFilter({
    clearAllFilterClickedTime,
    onChange,
    selectedTab
}) {
     //* Styles -----------------------------------------------------------------
     const useStyles = makeStyles({
        truncate: { 
            width: '100% !important',
            '& .MuiTypography-body1':{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }
    });
    const classes = useStyles()
    const translatedTextObject = TranslationText()

    const { securedSendRequest } = useServices()

    const DEFAULT_CHECKED_VALUES = { ALL: true }
    const [checkedValues, setCheckedValues] = useState({ ...DEFAULT_CHECKED_VALUES })

    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: true,
        isInitialLoadingError: false
    })
    const [customerOptions, setCustomerOptions] = useState(false)

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setCheckedValues({ ...DEFAULT_CHECKED_VALUES })
    }, [clearAllFilterClickedTime])

    // The 'ALL' checkbox need special handling.
    const allCheckboxChangeHandler = (event) => {
        let currentChecked = checkedValues.ALL

        // Uncheck everything else, and change the current.
        let newCheckedValues = { ...DEFAULT_CHECKED_VALUES }
        newCheckedValues.ALL = !(currentChecked)
        setCheckedValues(newCheckedValues)
    }

    const checkboxChangeHandler = (event) => {
        let checkboxValue = event.target.value

        let currentChecked = (checkedValues[checkboxValue])

        let newCheckedValues = { ...checkedValues }
        newCheckedValues[checkboxValue] = !(currentChecked)
        setCheckedValues(newCheckedValues)
    }

    // Initial load effect.
    useEffect(() => {

        // On page load, call Ajax to get the list of event code options.
        let url = CecAccountApiUrls.GET_ACCOUNT_CUSTOMER
        if(selectedTab == 'CONTRACT_REC'){
            url = CecAccountApiUrls.GET_ACCOUNT_ISSUER
        }
        
        let data = {}

        securedSendRequest.execute('POST', url, data,
            (response) => {

                // success
                setInitialLoadingState({
                    isInitialLoading: false,
                    isInitialLoadingError: false
                })

                setCustomerOptions(response.data.content)
            },
            (error) => {
                // error
                console.error(error)
                setInitialLoadingState({
                    isInitialLoading: false,
                    isInitialLoadingError: true
                })
            },
            () => {
                // complete
            }
        )
    }, [selectedTab])

    useEffect(() => {

        // Trigger the onChange when checkedValues are changed.
        if (onChange) {
            let selectedValues = []

            for (let [key, value] of Object.entries(checkedValues)) {
                if (value) {
                    selectedValues.push(key)
                }
            }

            onChange(selectedValues)
        }
    }, [checkedValues])

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setCheckedValues({ ...DEFAULT_CHECKED_VALUES })
    }, [clearAllFilterClickedTime])

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container>
            <Grid item xs={12}>
                <CardContent style={{
                    background: '#8996AF10',
                    padding: '1em',
                    marginTop: '1em',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }}>
                    {selectedTab == Utils.Tab.CONTRACT ?
                     translatedTextObject.filterCustomerTitle
                     :
                     translatedTextObject.filterIssuerTitle
                     }
                </CardContent>
            </Grid>
            <Grid item xs={12}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={
                                    <>
                                        <Typography color='textPrimary' display='inline'>{translatedTextObject.filterLabelAll}</Typography>
                                        
                                    </>
                                }
                                control={
                                    <Checkbox
                                        checked={checkedValues.ALL}
                                        onChange={allCheckboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>

                        {
                            (initialLoadingState.isInitialLoading) &&
                            (
                                /* Show a loading spinner during initial loading. */
                                <Grid item xs={12}>
                                    <LoadingSpinnerLabel />
                                </Grid>
                            )
                        }

                        {
                            (initialLoadingState.isInitialLoadingError) &&
                            (
                                /* Show error if failed to initial load. */
                                <Grid item xs={12}>
                                    <ErrorRetrievingDataLabel />
                                </Grid>
                            )
                        }

                        {
                            (customerOptions) &&
                            (
                                customerOptions.map((option, index) => {
                                    let key = 'CustomerOption_' + index;

                                    return (
                                        <Grid item xs={12} xl={6} key={key} >
                                            <FormControlLabel
                                                className={classes.truncate} 
                                                disabled={(checkedValues.ALL)}
                                                label={
                                                    <>
                                                        <Typography color='textPrimary' display='inline'>{option.displayName}</Typography>
                                                    </>
                                                }
                                                control={
                                                    <Checkbox value={option.accountNumber}
                                                        checked={(checkedValues.ALL) || (checkedValues[option.accountNumber])}
                                                        onChange={checkboxChangeHandler}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    )
                                })
                            )
                        }
                    </Grid>
                </CardContent>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

export default CustomerFilter
