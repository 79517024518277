import { Grid, Typography, Chip } from '@material-ui/core'
import React from 'react'
import TranslationText from '../TranslationText'
import { components } from 'cng-web-lib'

const {
     CngGridItem,
} = components

const FormBody = props => {
      const translatedTextsObject = TranslationText()
      return (
            <>
                  <CngGridItem xs={4} sm={4}>
                        <Grid><Typography variant='caption' color='textSecondary'>{translatedTextsObject.startRange}</Typography></Grid>
                        <Grid><Typography variant='body2'>{props.ratePrice.startRange}</Typography></Grid>
                        <br />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                        <Grid><Typography variant='caption' color='textSecondary'>{translatedTextsObject.unitPrice}</Typography></Grid>
                        <Grid><Typography component={'span'} variant='body2'><Chip label={<b>SGD</b>} size='small' />{props.ratePrice.unitPrice}</Typography></Grid>
                        <br />
                  </CngGridItem>
            </>

      )

}

const ServiceRateCardPriceDetailsComponents = Object.freeze({
      FormBody: FormBody
})

export default ServiceRateCardPriceDetailsComponents