import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import TranslationText from '../TranslationText'
import { v4 as uuid } from 'uuid'
import { useFieldArray } from 'react-hook-form'
import ContractDocumentDetailsComponent from './ContractDocumentDetailsComponents'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'


const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem,
    dropzone: { CngFileUpload, HelperTextArea },
    table: { useDefaultNotification }
} = components

const initialValues = {
    contractDocs: {
        description: '',
        docType: ''
    }
}
const {
    filter: { EQUAL }
} = constants



const FormBody = (props) => {

    const { fields: pendingDocField } = useFieldArray({
        name: 'pendingContractDocuments'
    })

    const { fields: docField } = useFieldArray({
        name: 'contractDocuments'
    })

    const translatedTextsObject = TranslationText();

    const { fetchRecords, securedSendRequest } = useServices()


    const [pendingDocs, setPendingDocs] = useState([])  //for the file list below drop zone
    const [uploadedDocs, setUploadedDocs] = useState([]) //for the file list above the drop zone
    const [deletedDocs, setDeletedDocs] = useState([])
    const [fieldConfig, setFieldConfig] = useState([])
    const [fieldDict] = useState([]) //for display that fits the wireframe more in uploadedDocs section
    const { errors, touched, setFieldValue } = useFormikContext()
    const docTypeKeyRef = useRef(uuid())

    const { error: showErrorNotification } = useDefaultNotification(props.showNotification)
    const [shouldRender, setShouldRender] = useState(false)

    const removeUploadedRowClick = (id) => {
        let jobDocId = uploadedDocs[id].id
        const deletedDocList = [...deletedDocs]
        deletedDocList.push(jobDocId)
        setDeletedDocs(deletedDocList)
        setFieldValue('deletedDocIds', deletedDocList)

        uploadedDocs.splice(id, 1);
        const newFiles = [].concat([], uploadedDocs)
        setUploadedDocs(newFiles)
        setFieldValue('contractDocuments', newFiles)
        docTypeKeyRef.current = uuid()
    }

    const handleUploadedInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...uploadedDocs]
        list[index][name.split('.')[1]] = value
        setUploadedDocs(list)
        setFieldValue('contractDocuments', list)
    }

    const handleUploadedDropdownChange = (val, evt, index, name) => {
        if (evt) {
            const { value } = evt
            const list = [...uploadedDocs]
            list[index][name] = value
            setUploadedDocs(list)
            setFieldValue('contractDocuments', list)
        }
    }

    const editRowClick = (id) => {
        const newFiles = [].concat([], uploadedDocs)
        newFiles[id].editable = true
        setUploadedDocs(newFiles)
        setFieldValue('contractDocuments', newFiles)
    }

    const downloadUploadedRowClick = id => {
        downloadFile(uploadedDocs[id])
    }

    function downloadFile(doc) {
        securedSendRequest.execute(
            "POST",
            SCORateMgmtApiUrls.DOCUMENT_DOWNLOAD,
            {
                id: doc.id
            },
            (data) => {
            
                var link = data.data.content[0].fileUrl
                window.open(link, '_blank').focus()
            },
            (error) => {
                console.log(error)
            },
          
        )
    }

    const getFieldError = (itemIndex, fieldPath) => {
        if (errors) {
            let pathArr = fieldPath.split('.')

            if (
                errors[pathArr[0]] &&
                errors[pathArr[0]][itemIndex] &&
                errors[pathArr[0]][itemIndex][pathArr[1]]
            ) {
                if (touched) {
                    if (
                        touched[pathArr[0]] &&
                        touched[pathArr[0]][itemIndex] &&
                        touched[pathArr[0]][itemIndex][pathArr[1]]
                    ) {
                        return errors[pathArr[0]][itemIndex][pathArr[1]]
                    } else {
                        return null
                    }
                }
            }
        }
        return null
    }

    const showErrorMessages = (errorMessages) => {
        showErrorNotification(errorMessages[0])
    }

    const moreActions = [
        {
            action: 'remove',
            name: 'Delete',
            icon: ['fal', 'trash']
        }
    ]

    function fetchDocuments() {
        fetchRecords.execute(
            SCORateMgmtApiUrls.DOCUMENT_GETBYCONTRACTID,
            { customData: { id: props.id } },
            (data) => {
                setPendingDocs(pendingDocField)
                let uploadedDocList = docField
                if (uploadedDocList != null) {
                    let uploadedDocListWithEditable = data.map((v) => ({ ...v, editable: false }))
                    setUploadedDocs(uploadedDocListWithEditable)
                    setFieldValue('contractDocuments', uploadedDocListWithEditable)
                }
            }
        )
    }



    useEffect(() => {
        fetchRecords.execute(
            SCOAdminApiUrls.GET_CODE_MASTER_LIST,
            {
                filters: [
                    {
                        field: 'codeType',
                        operator: EQUAL,
                        value: 'CEC_CON_DOC_TYPE'
                    },
                    {
                      field: 'status',
                      operator: EQUAL,
                      value: true
                    }]
            },
            onLoadDDLType
        )

        return () => {
            fetchRecords.abort()
        }

    }, [props.id])

    function onLoadDDLType(data) {
        let docTypeOptionList = []
        if (data.content.length > 0) {
            data.content.map((singleDocTypeOption) => {
                var detailedOption = {
                    value: singleDocTypeOption.code,
                    label: singleDocTypeOption.name
                }
                docTypeOptionList.push(detailedOption)
            })
        }


        let currentFieldConfig = [
            {
                fieldType: 'dropDown',
                fieldName: 'docType',
                label: 'File type',
                mandatory: true,
                error: false,
                errorMessage: 'Required',
                validationRule: '',
                dropDownLov: docTypeOptionList
            }
            
        ]

        setFieldConfig(currentFieldConfig)
        if (props.id != undefined && props.id != null) {
            setPendingDocs(pendingDocField)
            fetchDocuments()
            setShouldRender(true)
        } else {
            setShouldRender(true)
        }
    }

    const setSelectedFiles = (file) => {
        //long file name
        let processedFileObjs = []
        file.forEach((item) => {
            processedFileObjs.push(item)
        })
        setFieldValue('pendingContractDocuments', processedFileObjs)
    }

    if (!shouldRender) {
        return null
    }



    return (
        <Grid container style={{ padding: 15, display: 'block' }} >
            <Card>
                <Accordion expanded={true} style={{ marginBottom: 20 }}>
                    <AccordionSummary
                        aria-controls='contractDocumentContent'
                        id='contractDocumentComponentHeader'>
                        <Grid container>
                            <Grid container spacing={2} >
                                <CngGridItem xs={12} sm={12}>
                                    <AccordionHeaderComponent title={translatedTextsObject.contractDocSubTitle} hideMandatory={true}/>
                                </CngGridItem>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Grid container spacing={2}>
                            <CngGridItem xs={12} sm={12} shouldHide={uploadedDocs.length > 0 ? false : true}>
                                <ContractDocumentDetailsComponent
                                    tile={uploadedDocs}
                                    removeRowClick={removeUploadedRowClick}
                                    handleInputChange={handleUploadedInputChange}
                                    handleDropdownChange={handleUploadedDropdownChange}
                                    editRowClick={editRowClick}
                                    downloadRowClick={downloadUploadedRowClick}
                                    getFieldError={getFieldError}
                                    docTypeKeyRef={docTypeKeyRef.current}
                                    fieldDict={fieldDict}
                                />
                            </CngGridItem>
                            <CngGridItem  xs={12} sm={12}>
                                <CngFileUpload
                                    accept={[
                                        '.pdf',
                                        '.xls',
                                        '.xlsx',
                                        '.doc',
                                        '.docx',
                                        '.png',
                                        '.jpg',
                                        '.jpeg',
                                        '.ppt',
                                        '.pptx'
                                    ]}
                                    maxSize={10485760}
                                    maxFiles={3}
                                    onFileSelect={setSelectedFiles}
                                    showFormFields={true}
                                    files={pendingDocs}
                                    fieldConfig={fieldConfig}
                                    moreActions={moreActions}
                                    onDropRejected={(error) => showErrorMessages(error)}
                                    renderHelperText={() => {
                                        return (
                                          <HelperTextArea
                                            accept={[
                                                '.pdf',
                                                '.xls',
                                                '.xlsx',
                                                '.doc',
                                                '.docx',
                                                '.png',
                                                '.jpg',
                                                '.jpeg',
                                                '.ppt',
                                                '.pptx'
                                            ]}
                                            maxFiles={3}
                                            maxSize={1048576}
                                          />
                                        )
                                    }}
                                />
                            </CngGridItem>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                
            </Card >
        </Grid>
    )
}


const ContractDocumentComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default ContractDocumentComponent