import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, makeStyles, Typography, CircularProgress } from '@material-ui/core'
import { useServices } from 'cng-web-lib'
import AddButton from 'src/components/button/AddButton'
import PanelLoading from './PanelLoading'
import PanelError from './PanelError'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import LoadMoreObserver from 'src/components/misc/LoadMoreObserver'
import ContractView_DetailRow from './ContractView_DetailRow'
import SortDropdown from './SortDropdown'
import TranslationText from '../TranslationText'

import pathMap from 'src/paths/PathMap_SCO_RateMgmt'
import Utils from './Utils'

const useStyles = makeStyles(theme => ({
    text: {
        fontSize: '20px',
        fontWeight: '700'
    },
    header: {
        padding: theme.spacing(1),
        fontSize: '.85em',
        display: 'flex',
        alignItems: 'center',
        color: '#8996AF'
    },
    title: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        '& > svg': { margin: theme.spacing(0, 3) }
    },
    load_more: {
        width: '100%',
        margin: '1em 0',
        textAlign: 'center'
    }
}))

function ContractView_LeftPanel({
    filterData,
    selectedTab,
    updateSelectedTab,
    setTotal,
    setTotalRec,
    searchText,
    ...props
}) {

    const history = useHistory()
    const classes = useStyles()
    const translatedTextObject = TranslationText()

    const { securedSendRequest } = useServices();

    const [transactionResult, setTransactionResult] = useState(null)

    const [initialLoadingState, setInitialLoadingState] = useState({
        isInitialLoading: false,
        isInitialLoadingError: false
    })
    const [loadingMoreState, setLoadingMoreState] = useState({
        isLoadingMore: false,
        isLoadingMoreError: false,
        isNoMoreData: false
    })
    const [items, setItems] = useState(0);
    const fetchRow = 10;
    const offSetRow = useRef();
    const page = useRef();
    const ContractListData = useRef([])

    const [sortType, setSortType] = useState('UPDATED_DATE_DESC')

    function refreshList() {
        callGetContractListApi({
            onBeforeStart: () => {
                // reset the ref data
                offSetRow.current = 0
                page.current = 0;
                ContractListData.current = []

                // reset the load more data
                setLoadingMoreState({
                    isLoadingMore: false,
                    isLoadingMoreError: false,
                    isNoMoreData: false
                })

                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: true })
            },
            onSuccess: (response) => {
                console.debug(response)

                processResponse(response.data)
                setInitialLoadingState({ ...initialLoadingState, isInitialLoading: false })
            },
            onError: (error) => {
                console.error(error)

                setInitialLoadingState({
                    ...initialLoadingState,
                    isInitialLoading: false,
                    isInitialLoadingError: true
                })
            },
            onComplete: () => { /* Do nothing */ },
            selectedTab
        })
    }

    //** Retrieve total count to display result number in tab 
    function fetchTotalCount(fetchTab) {
        callGetContractListApi({
            onBeforeStart: () => { /* Do nothing */ },
            onSuccess: (response) => {
                if(fetchTab === Utils.Tab.CONTRACT){
                    setTotal(response.data.filtered_total_records?response.data.filtered_total_records:0);
                }else{
                    setTotalRec(response.data.filtered_total_records?response.data.filtered_total_records:0);
                }
            },
            onError: (error) => {
                console.error(error)
            },
            onComplete: () => { /* Do nothing */ },
            fetchTab
        })
    }

    //** Initial load effect. Will also be triggered by the sort option onChange.
    useEffect(() => {
        refreshList()
    }, [filterData, sortType])

    useEffect(() => {
        //fetch another tab for total count 
        if(selectedTab === Utils.Tab.CONTRACT_REC){
            fetchTotalCount(Utils.Tab.CONTRACT);
        }else{
            fetchTotalCount(Utils.Tab.CONTRACT_REC);
        }
    }, [filterData])

    useEffect(() => {
        //run once to set selected tab 
        updateSelectedTab(selectedTab);
    }, [])

    function processResponse(response) {
        
        if (response.contract_records != null) {
            let newContractListData = [...ContractListData.current, ...response.contract_records]
            ContractListData.current = newContractListData

            setTransactionResult(response)

            offSetRow.current = offSetRow.current + fetchRow
            page.current = page.current + 1;
        }

        if(selectedTab == undefined || selectedTab == Utils.Tab.CONTRACT){
            setTotal(response.filtered_total_records?response.filtered_total_records:0);
        }else{
            setTotalRec(response.filtered_total_records?response.filtered_total_records:0);
        }
        setItems(response.filtered_total_records?response.filtered_total_records:0);
    }

    function callGetContractListApi({ onBeforeStart, onSuccess, onError, onComplete, fetchTab }) {

        if (onBeforeStart) { onBeforeStart() }

        let url = SCORateMgmtApiUrls.GET_CONTRACT_LIST_DETAILS
        let data = {
            offSetRow: offSetRow.current,
            fetchRow: fetchRow,
            page: page.current,
            statusFilter: filterData.statusFilter,
            dateFilter: filterData.dateFilter,
            customerFilter: filterData.customerFilter,
            sortOrder: 'asc',
            sortType: sortType,
            selectedTab: fetchTab || selectedTab,
            searchText: searchText
        }

        securedSendRequest.execute("POST", url, data,
            (response) => {
                console.debug(response)
                if (onSuccess) { onSuccess(response) }
            },
            (error) => {
                console.error(error)
                if (onError) { onError(error) }
            },
            () => { if (onComplete) { onComplete() } }
        )
    }

    //** the infinite scroll
    const handleInfiniteScroll = (entries, observer) => {

        if (initialLoadingState.isInitialLoading) {
            //** do nothing. it is still initial loading.
            return;
        }

        if (loadingMoreState.isLoadingMore) {
            //** Is still loading more.
            return;
        }

        if (loadingMoreState.isNoMoreData) {
            //** do nothing also. There is no more data to load.
            return;
        }

        callGetContractListApi({
            onBeforeStart: () => {
                setLoadingMoreState({ ...loadingMoreState, isLoadingMore: true })

            },
            onSuccess: (response) => {
                console.debug(response)
                if ((response) && (response.data) && response.data.contract_records != null) {
                    let newContractListData = [...ContractListData.current, ...response.data.contract_records]
                    ContractListData.current = newContractListData

                    offSetRow.current = offSetRow.current + fetchRow
                    page.current = page.current + 1;

                    setLoadingMoreState({ ...loadingMoreState, isLoadingMore: false })
                }
                else {
                    //** no more data.
                    setLoadingMoreState({
                        ...loadingMoreState,
                        isLoadingMore: false,
                        isNoMoreData: true
                    })
                }

            },
            onError: (error) => {
                console.error(error)

                setLoadingMoreState({
                    ...loadingMoreState,
                    isLoadingMore: false,
                    isLoadingMoreError: true
                })
            },
            onComplete: () => { /* Do nothing */ }
        })
    }
    //* ------------------------------------------------------------------------
    //* Return JSX - Loading...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoading) {
        return (<PanelLoading />)
    }

    //* ------------------------------------------------------------------------
    //* Return JSX - Error...
    //* ------------------------------------------------------------------------
    if (initialLoadingState.isInitialLoadingError) {
        return (<PanelError />)
    }

    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <Grid container spacing={1} alignItems='center'>
            {/* The header */}
            <Grid item xs={6}>
                <Typography component='div' color='textSecondary'>
                   {transactionResult ? items +' Item(s)' : <CircularProgress size='1em' />}
                </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
                <SortDropdown setSortType={setSortType} sortType={sortType} />
                <AddButton label={translatedTextObject.btnCreateContract}
                    style={{ marginLeft: '1em' }}
                    onClick={() => {
                        history.push(pathMap.SCO_RATE_CONTRACT_ADD_PAGE)
                    }}
                />
            </Grid>

            <Grid item xs={12} container spacing={0}>
                {
                    ContractListData.current.map((transaction, index) => {
                        let key = "rec_" + index;

                        return (
                            <ContractView_DetailRow
                                key={key}
                                details={transaction}
                                showNotification={props.showNotification}
                                refreshList={refreshList}
                                selectedTab={selectedTab}
                            />
                        )
                    })
                }
                <Grid className={classes.load_more} item xs={12}>
                    {(
                        () => { // use annon function to return if-else condition.
                            if (loadingMoreState.isNoMoreData) {
                                return (<span>No more data to load.</span>)
                            }
                            else if (loadingMoreState.isLoadingMoreError) {
                                return (<span>Error loading more data.</span>)
                            }
                            else {
                                return (
                                    <LoadMoreObserver onInView={handleInfiniteScroll}>
                                        {
                                            loadingMoreState.isLoadingMore && // show spinner only when isLoadingMore
                                            <CircularProgress />
                                        }
                                    </LoadMoreObserver>
                                )
                            }
                        }
                    )()}
                </Grid>
            </Grid>
        </Grid >
        //* End JSX ------------------------------------------------------------
    );
    //* End of function --------------------------------------------------------
}

export default ContractView_LeftPanel
