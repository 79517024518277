import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useState, useEffect } from 'react'
import { components, constants } from 'cng-web-lib'
import DocumentInfoDecoratorComponent from 'src/views/common/ui/DocumentInfoDecoratorComponent'
import ContractDocumentActionMenu from './ContractDocumentActionMenu'
import Utils from 'src/views/common/utils/Utils'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'

const {
  form: {
    field: {
      CngLookupAutocompleteField
    },
  },
} = components

const {
  filter: { EQUAL }
} = constants


const ContractDocumentDetailsComponents = (props) => {

  const [files, setFiles] = useState([])

  useEffect(() => {

    if (props.tile !== null || props.tile.length <= 0) {
      setFiles(props.tile)
    }
  }, [props.tile])

  if (files !== null || files.length <= 0) {
    return (
      <TableContainer component={Paper}>
        <Table id="table">
          <TableBody>
            {files.map((elem, i) => {
              return (<TableRow key={`row-${i}`}>
                <TableCell > <FileCopyIcon /></TableCell>
                <TableCell>
                  <DocumentInfoDecoratorComponent name={Utils.truncLongFileName(elem.fileName)} size={elem.fileSize} /> </TableCell>

                <TableCell>
                  <CngLookupAutocompleteField
                    name={`contractDocuments[${i}].docType`}
                    label={'File Type'}
                    required
                    onChange={(val, evt) => props.handleDropdownChange(val, evt, i, 'docType')}
                    disabled={!elem.editable}
                    key={props.docTypeKeyRef}
                    error={props.getFieldError(i, 'contractDocuments.docType')}
                    helpertext={props.getFieldError(i, 'contractDocuments.docType')}
                    lookupProps={{
                      url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                      label: 'name',
                      value: 'code',
                      filters: [
                        {
                          field: 'codeType',
                          operator: EQUAL,
                          value: 'CEC_CON_DOC_TYPE'
                        },
                        {
                          field: 'status',
                          operator: EQUAL,
                          value: true
                        }
                      ]
                    }}
                  />
                </TableCell>
               
                <TableCell>
                  <ContractDocumentActionMenu id={i}
                    hideDownloadLog
                    removeRowClick={props.removeRowClick}
                    editRowClick={props.editRowClick}
                    downloadRowClick={props.downloadRowClick}
                    hideEdit={true}
                    hideDelete={true}
                  />
                </TableCell>
              </TableRow>)
            })
            }
          </TableBody >
        </Table>
      </TableContainer>

    )
  } else {
    return (<br />)
  }

}

export default ContractDocumentDetailsComponents
