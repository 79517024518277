import {
    Box,
    Button,
    Grid
} from '@material-ui/core'
import React, { useState } from 'react'
import AddBoxIcon from '@material-ui/icons/AddBox'
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp'

function FooterAddComponent(props) {

    const [captionDisplay, setCaptionDisplay] = useState(false)
    let isCopy = props.copy!=null?props.copy:false

    const handleMouseOverOn = () => {
        setCaptionDisplay(true);
    };

    const handleMouseOverOff = () => {
        setCaptionDisplay(false);
    };

    return (
        <Grid item onClick={props.handleAddChild} style={{marginLeft:10, marginRight:10}}>
            <Button
                variant="contained"
                color="default"
                fullWidth
                startIcon={isCopy?<FileCopySharpIcon />:<AddBoxIcon />}
                className={'accordion-footer-add-component'}
                onMouseEnter={handleMouseOverOn}
                onMouseLeave={handleMouseOverOff}
            >
                <Box style={{ textAlign: 'left' }}>
                    <Box mt={captionDisplay ? 0 : 1} fontWeight={600} fontSize={13}>
                        {props.footerText}
                    </Box>
                    <Box mt={captionDisplay ? 0 : -1}
                        fontSize={11}
                        visibility={captionDisplay ? 'visible' : 'hidden'}
                    >
                        {" Or clone the previous one "}
                    </Box>
                </Box>
            </Button>
        </Grid>
    )
}

export default FooterAddComponent