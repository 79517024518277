import React from 'react'
import { components } from 'cng-web-lib'
import { Grid, Typography, Chip, Card, CardContent } from '@material-ui/core'
import ViewServiceDetailsComponents from './ViewServiceDetailsComponents'
import ViewHistoryComponents from './ViewHistoryComponents'
import Utils from '../landing-page/Utils'
import pathMap from 'src/paths/PathMap_SCO_RateMgmt'
import ViewActionList from './ViewActionList'
// Moment library
import Moment from 'moment';
import 'moment-timezone';

const {
  CngGridItem,
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  ...ViewServiceDetailsComponents.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function _formatDate(timestamp, timezone, format) {
  let moment = Moment(timestamp)

  if (timezone) {
    moment.tz(timezone)
  }

  return (moment.format(format))
}

const DATETIME_FORMAT = 'DD MMM YYYY HH:mm'
const DAYDATE_FORMAT = 'dddd, DD MMM YYYY'
const TIME_FORMAT = 'HH:mm'

function FormFields({ history, showNotification, loading, id, data, refreshList, selectedTab }) {

  let color = Utils.getStatusByName(data.content[0].status).color

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={1} >
            <CngGridItem item xs={12} sm={12}>
              <Chip
                label={
                  <Typography className={'boldLabel'} style={{ fontSize: 16, textTransform: 'uppercase' }}>
                    {data.content[0].status}

                  </Typography>
                }
                style={{
                  backgroundColor: color, width: '102%',
                  margin: '-10px 0px 10px -10px', justifyContent: 'flex-start',
                  borderRadius: '7px'
                }}
              />
            </CngGridItem>

          </Grid>
          <Grid item xs={12} sm={12} >
            <br />
            <p>The following contract was updated on 
              <b> {_formatDate(data.content[0].updatedDate, null, DAYDATE_FORMAT)} at {_formatDate(data.content[0].updatedDate, null, TIME_FORMAT)} </b> </p>
          </Grid>

          <ViewServiceDetailsComponents.FormBody
            showNotification={showNotification}
            data={data}
            id={id}

          />

          <ViewHistoryComponents.FormBody
            showNotification={showNotification}
            data={data}
            id={id}
            formatDate={_formatDate}
            DATETIME_FORMAT={DATETIME_FORMAT}

          />
          <hr style={{ borderTop: '1px solid #f4f4fc', width: '100%' }} />

          <Grid container spacing={1}>
            <Grid item xs>
              <CngSecondaryButton
                onClick={() => history.push(pathMap.SCO_RATE_CONTRACT_LIST_VIEW)}>
                Back to contracts listing
              </CngSecondaryButton>
            </Grid>
            <Grid item>
              <ViewActionList
                key={'action'}
                showNotification={showNotification}
                details={data.content[0]}
                refreshList={refreshList}
                selectedTab={selectedTab}
              />

            </Grid>
          </Grid>

        </CardContent>
      </Card>

    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }
  // valid date
  localData.validTo = _formatDate(serverData.validTo, null, DATETIME_FORMAT)
  localData.validFrom = _formatDate(serverData.validFrom, null, DATETIME_FORMAT)

  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const ViewDetailsForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default ViewDetailsForm
