import { Box, Grid, Paper, Typography, Divider } from '@material-ui/core'
import { components, constants, Yup } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import TranslationText from '../TranslationText'
import Utils from '../landing-page/Utils'
import ExistingServiceComponent from './ExistingServiceComponent'

const { FormState } = constants
const {
    form: {
        adapter: {
            useFormAdapter: { useFormikContext }
        },
        CngForm
    },
    CngDialog,
    CngTabs,
    button: { CngPrimaryButton, CngSecondaryButton }

} = components

const ExistingServiceDialog = ({ isOpen, handleCopyPublicService, setIsOpen, showNotification, companyId, checkToRemoveEmpty }) => {

    const requiredMessage = 'Please select';
   
    const onSubmit = (values) => {
        handleCopyPublicService(values.chosenRate)
        setIsOpen(false);
        checkToRemoveEmpty();
    };

    return (
        <CngDialog
            open={isOpen}
            fullWidth
            maxWidth='xl'
            customDialogContent={
                <Paper>
                    <Box m={3} mt={3}>
                        <Typography variant="h5" className='font-bolder'>Add from existing service</Typography>
                        <br/>
                        <Box mt={2}>

                            <CngForm
                                formikProps={{
                                    initialValues: { chosenRate: [] },
                                    validationSchema: Yup.object({ chosenRate: Yup.string().required(requiredMessage) }),
                                    onSubmit: onSubmit
                                }}
                                formState={FormState.COMPLETED}
                                renderButtonSection={() => <>
                                </>}
                                renderBodySection={() =>
                                    <>
                                        <Grid >
                                            <FormFields
                                                isOpen={isOpen}
                                                setIsOpen={setIsOpen}
                                                showNotification={showNotification}
                                                companyId={companyId}
                                            />
                                        </Grid>


                                    </>
                                }
                            />
                        </Box>
                    </Box>
                </Paper>
            }

        />
    );
}


const FormFields = ({ isOpen, setIsOpen, showNotification, companyId }) => {
    const { submitForm, setFieldValue } = useFormikContext()

    const [chosenRate, setChosenRate] = useState([]);
    const translatedTextObject = TranslationText();
    const [selectedTab, setSelectedTab] = useState(Utils.PublicRateTab.PUBLIC_RATE);
    const [totalCont, setTotalCont] = useState(0);
    const [totalContPre, setTotalContPre] = useState(0);

    function updateSelectedTab(value) {
        setSelectedTab(value);
    }

    useEffect(() => {
        setChosenRate([])
    }, [])

    function onChange(e, details) {
        let value = details;
        if (e.target.checked) {
            const list = [...chosenRate]
            var toAdd = true;
            list.forEach(rec => {
                if (rec.serviceId == value.serviceId) {
                    toAdd = false;
                }
            })
            if (toAdd) {
                setChosenRate([...list, value])
                setFieldValue('chosenRate', [...list, value], true)
            }

        } else {
            const list = [...chosenRate]
            var index = list.findIndex(obj => obj.serviceId == value.serviceId)
            list.splice(index, 1)

            setChosenRate(list)
            setFieldValue('chosenRate', list, true)

        }
    }

    return (
        <Grid container >
            <Grid container alignItems='center' justify='center' spacing={1} 
                style={{ height: '90% !important', maxHeight: '67vh', overflowX: 'clip', overflowY: 'scroll' }}>
                <Grid item xs={12}>
                    <CngTabs
                        headerColor='primary'
                        tabs={[
                            {
                                tabName: translatedTextObject.tabPublicRate + ' (' + totalCont + ') ',
                                tabContent: (
                                    <>
                                        <ExistingServiceComponent
                                            key={'publicRateForm'}
                                            isOpen={isOpen}
                                            selectedTab={Utils.PublicRateTab.PUBLIC_RATE}
                                            updateSelectedTab={updateSelectedTab}
                                            setTotal={setTotalCont}
                                            setTotalPre={setTotalContPre}
                                            showNotification={showNotification}
                                            companyId={companyId}
                                            onChange={onChange}
                                            chosenRate={chosenRate}
                                        />
                                    </>
                                )

                            },
                            {
                                tabName: translatedTextObject.tabPreferentialRate + ' (' + totalContPre + ') ',
                                tabContent: (
                                    <>
                                        <ExistingServiceComponent
                                            key={'preferentialRateForm'}
                                            isOpen={isOpen}
                                            selectedTab={Utils.PublicRateTab.PREFERENTIAL_RATE}
                                            updateSelectedTab={updateSelectedTab}
                                            setTotal={setTotalCont}
                                            setTotalPre={setTotalContPre}
                                            showNotification={showNotification}
                                            companyId={companyId}
                                            onChange={onChange}
                                            chosenRate={chosenRate}
                                        />
                                    </>
                                )
                            }
                        ]}
                    />
                </Grid>
            </Grid>
            <Divider />
            <Grid container justify='flex-end' mt={2} >
                <Box mt={2}>
                    <Grid container spacing={1}>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                            <CngSecondaryButton
                                onClick={() => setIsOpen(false)}>
                                {"Discard"}
                            </CngSecondaryButton>
                            <CngPrimaryButton style={{ marginLeft: '5px' }}
                                type="button"
                                onClick={submitForm}
                            >Save & continue</CngPrimaryButton>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>



        </Grid>
    );
}


export default ExistingServiceDialog;