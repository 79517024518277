import React, { useState, useEffect } from 'react'
import { Grid, Typography, Chip, Card, IconButton, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { components } from 'cng-web-lib'
import { fal as FontAwesomeRegular, faShareSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'
// Moment library
import Moment from 'moment';
import 'moment-timezone';
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChipTooltip from './ChipTooltip'
import ServiceTncDialog from './ServiceTncDialog'

FontAwesomeLibrary.add(FontAwesomeRegular)

const {
    form: {
        field: {
            CngCheckboxField
        }

    },
    CngGridItem
} = components

const useStyles = makeStyles(theme => ({
    box_line: {
        border: '1px solid #F5F5FA',
        marginBottom: theme.spacing(1.5),
        padding: theme.spacing(0.75)
    },
    box_line_blue: {
        border: '1px solid #5E81F4',
        marginBottom: theme.spacing(1.5),
        padding: theme.spacing(0.75)
    },
    checked_button: {
        marginLeft: '15px',
        padding: '3px',
        backgroundColor: '#5e81f4 !important',
        borderRadius: '10px',
        color: 'white !important',
    },
    check_button: {
        marginLeft: '15px',
        padding: '3px',
        backgroundColor: '#ececec',
        borderRadius: '10px',
        color: 'grey',
    },

    textColor: {
        color: '#8996AF'
    },
    
    alignment_field:{
        padding: '5px'
    },

    icon: {
        backgroundColor: '#8996af',
        borderRadius: 4,
        width: 20,
        height: 20,
        
    },
}))

function _formatDate(timestamp, timezone, format) {
    let moment = Moment(timestamp)

    if (timezone) {
        moment.tz(timezone)
    }

    return (moment.format(format))
}

const DATETIME_FORMAT = 'DD MMM YYYY HH:mm'

function ExistingServiceDetailsComponent({ index, details, chosenRate, showNotification, refreshList, selectedTab, onChange }) {
    const classes = useStyles()
    const [checked, setChecked] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [chipDisplay, setChipDisplay] = useState([])
    const [chipDetails, setChipDetails] = useState([])
    const [numDisplay, setNumDisplay] = useState('')

    const [isOpen, setIsOpen] = useState(false);

    const openServiceTncDialog = () => {
        setIsOpen(true);
    }
    useEffect(() => {
        if (chosenRate != undefined && chosenRate.length > 0) {
            const list = [...chosenRate]
            var indexC = list.findIndex(obj => obj.serviceId == details.serviceId)
            if (indexC >= 0) {
                setChecked(true)
            }
        }

        if (details.rateComponent != null) {
            const list = [...details.rateComponent]
            const wordList = [];
            const rateDetails = [];
            list.forEach((obj) => {
                var word = obj.componentUOMCode + '.' + obj.componentCode + '  |  ';
                var price = [...obj.rate];
                price.forEach((p, pIndex) => {
                    word += p.startRange + (' (S$ ' + p.unitPrice + ')')
                    if (pIndex > 0) {
                        word += ', '
                    }
                })
                wordList.push(word)
                rateDetails.push(obj)
            })
            if (list.length > 1) {
                setNumDisplay(list.length - 1 + ' more')
            }
            setChipDisplay(wordList)
            setChipDetails(rateDetails)
        }
    }, [details])


    //* return JSX -------------------------------------------------------------
    return (
        //* Start JSX ----------------------------------------------------------
        <Grid item xs={12} sm={12} key={'publicRateComp_' + index}>
            <Card variant='outlined' className={checked ? classes.box_line_blue : classes.box_line} spacing={1}>
                <Grid container spacing={1} >

                    <CngGridItem xs={2} sm={2}>
                        <CngCheckboxField
                            key={'checkbox_' + index}
                            value={details.serviceId}
                            onChange={(event) => {
                                onChange(event, details);
                                setChecked(!checked);
                            }}
                            checked={checked}
                            label={
                                <Typography variant='body2'
                                 gutterBottom
                                 style={{color:"#8996af", wordWrap: "anywhere", padding:"5px", margin: "revert" }}
                                 >{details.serviceCode}</Typography>

                            }
                        />

                    </CngGridItem>

                    <CngGridItem xs={3} sm={3} style={{ marginTop: 12 }}>
                        <Grid><Typography variant='body2' className={classes.alignment_field}>{details.serviceName}</Typography></Grid>
                    </CngGridItem>
                    <CngGridItem xs={5} sm={5} style={{ marginTop: 12 }}>
                        <Grid><Typography component={'span'} variant='body2'>
                            <>
                                {chipDisplay.length > 0
                                    ?
                                    <ChipTooltip
                                        title={
                                            <Grid container>
                                                <CngGridItem xs={5} sm={5} >
                                                    Rate Code
                                                    <br />
                                                    Unit of measurement
                                                    <br />
                                                    Range & unit price
                                                </CngGridItem>
                                                <CngGridItem xs={7} sm={7} >
                                                    {chipDetails[0].componentCode}
                                                    <br />
                                                    {chipDetails[0].componentUOMName}
                                                    <br />
                                                    {chipDetails[0].rate.map((p, pIndex) => {
                                                        var word = p.startRange + (' (S$ ' + p.unitPrice + ')')
                                                        if (pIndex > 0) {
                                                            word += ', '
                                                        }
                                                        return (word)
                                                    })}
                                                </CngGridItem>
                                            </Grid>
                                        }>
                                        <Chip label={<b>{chipDisplay[0]}</b>} size='small' style={{ paddingLeft: 2 }} />
                                    </ChipTooltip>

                                    : <></>
                                }
                                {numDisplay != ''
                                    ? <Chip label={<b>{numDisplay}</b>} size='small' />
                                    : <></>
                                }
                            </>
                        </Typography></Grid>
                    </CngGridItem>
                    <CngGridItem xs={2} sm={2} style={{ marginTop: 12 }}>
                        <Grid>
                            <Typography variant='body2'>{_formatDate(details.updatedDate, null, DATETIME_FORMAT)}
                                <IconButton
                                    variant='contained'
                                    color='default'
                                    size='small'
                                    className={expanded ? classes.checked_button : classes.check_button}
                                    onClick={() => setExpanded(!expanded)}
                                >
                                    {expanded
                                        ? <ExpandLessIcon />
                                        : <ExpandMoreIcon />
                                    }
                                </IconButton>

                            </Typography>

                        </Grid>

                    </CngGridItem>

                    {expanded
                        ?
                        <>
                            <CngGridItem xs={2} sm={2} style={{ marginTop: 2 }}>
                                <Grid><Typography variant='body2'>{details.serviceCode}</Typography></Grid>
                                <Grid><Typography variant='caption' className={classes.textColor}>{_formatDate(details.updatedDate, null, DATETIME_FORMAT)}</Typography></Grid>
                            </CngGridItem>
                            <CngGridItem xs={3} sm={3} style={{ marginTop: 2 }}>
                                <Grid><Typography variant='caption' className={classes.textColor}>Service Name</Typography></Grid>
                                <Grid><Typography variant='body2'>{details.serviceName}</Typography></Grid>
                                <br />
                                <Grid><Typography variant='caption' className={classes.textColor}>Tax code</Typography></Grid>
                                <Grid><Typography component={'span'} variant='body2'><Chip label={<b>{details.taxPercentage}</b>} size='small' />{details.taxName}</Typography></Grid>
                            </CngGridItem>
                            <CngGridItem xs={7} sm={7} style={{ marginTop: 2 }}>
                                <Grid><Typography variant='caption' className={classes.textColor}>Category</Typography></Grid>
                                <Grid><Typography component={'span'} variant='body2'><Chip label={<b>{details.categoryCode}</b>} size='small' />{details.categoryName}</Typography></Grid>
                                <br />
                            </CngGridItem>


                            <CngGridItem xs={2} sm={2} style={{ marginTop: 2 }}>
                                <br />
                            </CngGridItem>
                            <CngGridItem xs={3} sm={3} style={{ marginTop: 2 }}>

                                <br />
                                {details.inclTax ?
                                    <Grid><Typography variant='body2'>Price inclusive of tax</Typography></Grid>
                                    :
                                    <Grid><Typography variant='body2'>Price exclusive of tax</Typography></Grid>
                                }
                                <br />
                            </CngGridItem>
                            <CngGridItem xs={7} sm={7} style={{ marginTop: 2 }}>
                                <br />
                                <Grid><Typography variant='body2'>
                                    Service terms & conditions
                                    <Link
                                        style={{ color: 'blue', cursor: 'pointer' }}
                                        className="ml-2"
                                        color='textPrimary'
                                        underline='hover'
                                        onClick={() => openServiceTncDialog()}>
                                        View <FontAwesomeIcon icon={faShareSquare} />
                                    </Link>

                                </Typography></Grid>
                                <br />
                            </CngGridItem>

                            <CngGridItem xs={2} sm={2} style={{ marginTop: 2 }}>
                                <br />
                            </CngGridItem>
                            <CngGridItem xs={10} sm={10} style={{ marginTop: 2 }}>
                                <Grid><Typography variant='caption' className={classes.textColor}>Service Description</Typography></Grid>
                                <Grid><Typography variant='body2'>{details.serviceDesc}</Typography></Grid>
                                <br />
                            </CngGridItem>
                            <CngGridItem xs={2} sm={2} style={{ marginTop: 2 }}>
                                <br />
                            </CngGridItem>
                            <CngGridItem xs={10} sm={10} style={{ marginTop: 2 }}>
                                <Grid><Typography variant='caption' className={classes.textColor}>UOM/RATE CARD/RANGE & UNIT PRICE(MIN & MAX)</Typography></Grid>
                                <Grid><Typography component={'span'} variant='body2'>
                                    {chipDisplay.map((chip, indexChip) => {
                                        return (
                                            <ChipTooltip key={'pbRateCard_' + indexChip}
                                                title={
                                                    <Grid container>
                                                        <CngGridItem xs={5} sm={5} >
                                                            Rate Code
                                                            <br />
                                                            Unit of measurement
                                                            <br />
                                                            Range & unit price
                                                        </CngGridItem>
                                                        <CngGridItem xs={7} sm={7} >
                                                            {chipDetails[indexChip].componentCode}
                                                            <br />
                                                            {chipDetails[indexChip].componentUOMName}
                                                            <br />
                                                            {chipDetails[indexChip].rate.map((p, pInd) => {
                                                                var word = p.startRange + (' (S$ ' + p.unitPrice + ')')
                                                                if (pInd > 0) {
                                                                    word += ', '
                                                                }
                                                                return (word)
                                                            })}
                                                        </CngGridItem>
                                                    </Grid>
                                                }>
                                                <Chip label={<b>{chip}</b>} size='small' style={{ paddingLeft: 2 }} />
                                            </ChipTooltip>
                                        )
                                    })}

                                </Typography></Grid>
                                <br />
                            </CngGridItem>

                            {details.customerCompany != null && details.customerCompany.length > 0
                                ?
                                <>

                                    <CngGridItem xs={2} sm={2} style={{ marginTop: 2 }}>
                                        <br />
                                    </CngGridItem>
                                    <CngGridItem xs={10} sm={10} style={{ marginTop: 2 }}>
                                        <Grid><Typography variant='caption' color='textSecondary'>Customer</Typography></Grid>
                                        <Grid><Typography component={'span'} variant='body2'>
                                            {details.customerCompany.map((cust, cindex) => {
                                                return (
                                                    <Chip key={'cust' + cindex} label={<b>{cust.companyName}</b>} size='small' style={{ paddingLeft: 2 }} />
                                                )
                                            })}

                                        </Typography></Grid>
                                        <br />
                                    </CngGridItem>
                                </>
                                :
                                <></>}
                        </>
                        :
                        <>

                        </>
                    }

                </Grid>
                <ServiceTncDialog
                    isOpen={isOpen}
                    serviceTnc={details.serviceTnc}
                    setIsOpen={setIsOpen}
                />
            </Card>
        </Grid>

        //* End JSX ------------------------------------------------------------
    );

    //* End of function --------------------------------------------------------
}

ExistingServiceDetailsComponent.propTypes = {
    title: PropTypes.string,
}

export default ExistingServiceDetailsComponent