import { useTranslation } from 'cng-web-lib'
import SCORateMgmtKeys from 'src/constants/locale/key/SCORateMgmt'
import Namespace from 'src/constants/locale/Namespace'

const TranslationText = () => {

    const { translate } = useTranslation(Namespace.SCO_RATE_MGMT);

    // Reserved for other user story : create/edit/view page
    const moduleTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.MODULE_TITLE)
    const contractTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_TITLE)
    const contractDetailsTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_DETAILS_TITLE)

    const contractInfoSubTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_INFO_SUBTITLE)
    const contractDocSubTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_DOC_SUBTITLE)
    const contractRateCardSubTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_RATECARD_SUBTITLE)
    const contractCommentSubTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_COMMENT_SUBTITLE)
    const contractHistorySubTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_HISTORY_SUBTITLE)

    const contractStatusTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_STATUS_TITLE)

    const contractNo = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_NO)
    const issuer = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.ISSUING_COMPANY)
    const customer = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CUSTOMER_COMPANY)
    const validFrom = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.VALID_FROM)
    const validTo = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.VALID_TO)
    const timezone = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TIMEZONE)
    const status = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.STATUS)

    const contractDocumentInstructions = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_DOC_INSTRUCTIONS)
    const contractDocumentAttach = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_DOC_ATTACH)

    const contractDiscardTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.DISCARD_CONT_TITLE)
    const contractDiscardChanges = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.DISCARD_CONT)

    const rateCardUUID = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.UUID)
    const serviceCode = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SERVICE_CODE)
    const serviceName = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SERVICE_NAME)
    const serviceDescription = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SERVICE_DESC)
    const serviceTnc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SERVICE_TNC)
    const currency = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CURRENCY)
    const taxPercentage = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TAX_PERCENTAGE)
    const priceIncTax = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.PRICE_INC_TAX)
    const category = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CATEGORY)

    const rateComponent = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.RATE_COMPONENT)
    const rateComponentCode = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.RATE_COMPONENT_CODE)
    const rateComponentName = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.RATE_COMPONENT_NAME)
    const rateComponentDescription = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.RATE_COMPONENT_DESC)
    const uom = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.UOM)
    const calculationBy = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CALCULATION_BY)
    const calculationMethod = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CALCULATION_METHOD)
    
    const fromLoc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FROM_LOC)
    const toLoc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TO_LOC)
    const fromCountry = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FROM_COUNTRY)
    const toCountry = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TO_COUNTRY)
    const transportMode = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TRANSPORT_MODE)

    const diffTax = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.DIFF_TAX)
    const diffTaxCode = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.DIFF_TAX_CODE)
    const diffTaxPercent = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.DIFF_TAX_PERCENT)
    const priceIncDiffTax = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.PRICE_INC_DIFF_TAX)

  
    const rate = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.RATE)
    const startRange = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.START_RANGE)
    const unitPrice = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.UNIT_PRICE)

    const tabPublicRate = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TAB.PUBLIC_RATE)
    const tabPreferentialRate = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TAB.PREFERENTIAL_RATE)

    // END Reserved for other user story : create/edit/view page


    //Listing Page
    const tabMyContracts = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TAB.CONTRACT)
    const tabContractReceived = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.TAB.CONTRACT_REC)
    const btnCreateContract = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.BTN.CREATE_CONT)
    const searchPlaceholder = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.CONTRACT_LIST_SEARCH_PLACEHOLDER)

    //Filter Panel
    const btnApply = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.BTN.APPLY_FILTER)
    const btnClear = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.BTN.CLEAR_ALL)
    const filterTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FIELD.FILTER)
    const filterDateTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FIELD.DATE)
    const filterStatusTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FIELD.STATUS)
    const filterCustomerTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FIELD.CUSTOMER)
    const filterIssuerTitle = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FIELD.ISSUER)

    const filterLabelAll = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.ALL)
    const filterLabel15 = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.PAST_15_DAYS)
    const filterLabel30 = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.PAST_30_DAYS)
    const filterLabel60 = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.PAST_60_DAYS)
    const filterLabelDraft = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.ST_DRAFT)
    const filterLabelIssued = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.ST_ISSUED)
    const filterLabelActive = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.ST_ACTIVE)
    const filterLabelRevoked = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.ST_REVOKED)
    const filterLabelCancelled = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.ST_CANCELLED)
    const filterLabelRevocation = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.ST_REVOCATION)
    const filterErrorSelectStatus = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FILTERS.PLEASE_SELECT_STATUS)

    //Sorting
    const sortLabel = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.FIELD.SORT)
    const sortUpdatedDateDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.UPDATED_DATE_DESC)
    const sortUpdatedDateAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.UPDATED_DATE_ASC)
    const sortValidFromDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.VALID_FROM_DESC)
    const sortValidFromAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.VALID_FROM_ASC)
    const sortValidUntilDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.VALID_UNTIL_DESC)
    const sortValidUntilAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.VALID_UNTIL_ASC)
    const sortIssuerAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.ISSUER_ASC)
    const sortIssuerDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.ISSUER_DESC)
    const sortCustomerAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.CUSTOMER_ASC)
    const sortCustomerDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.CUSTOMER_DESC)
    const sortReasonAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.REASON_ASC)
    const sortReasonDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.REASON_DESC)
    const sortRemarksAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.REMARKS_ASC)
    const sortRemarksDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.REMARKS_DESC)

    const sortServiceCodeAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.SERVICE_CODE_ASC)
    const sortServiceCodeDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.SERVICE_CODE_DESC)
    const sortServiceNameAsc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.SERVICE_NAME_ASC)
    const sortServiceNameDesc = translate(Namespace.SCO_RATE_MGMT, SCORateMgmtKeys.SORT.SERVICE_NAME_DESC)

    return {
        moduleTitle,
        contractTitle,
        contractDetailsTitle,

        contractInfoSubTitle,
        contractDocSubTitle,
        contractRateCardSubTitle,
        contractCommentSubTitle,
        contractHistorySubTitle,

        contractStatusTitle,
        contractNo,
        issuer,
        customer,
        validFrom,
        validTo,
        timezone,
        status,
        contractDocumentInstructions,
        contractDocumentAttach,
        contractDiscardTitle,
        contractDiscardChanges,
        rateCardUUID,
        serviceCode,
        serviceName,
        serviceDescription,
        serviceTnc,
        currency,
        taxPercentage,
        priceIncTax,
        category,
        rateComponent,
        fromLoc,
        toLoc,
        fromCountry,
        toCountry,
        transportMode,
        rateComponentCode,
        rateComponentName,
        rateComponentDescription,
        calculationMethod,
        calculationBy,
        uom,
        diffTax,diffTaxCode,diffTaxPercent,priceIncDiffTax,
        rate,
        startRange,
        unitPrice,
        tabMyContracts, tabContractReceived, 
        tabPublicRate, tabPreferentialRate,
        searchPlaceholder,
        btnCreateContract, btnApply, btnClear, 
        filterTitle, filterDateTitle, filterStatusTitle, filterCustomerTitle,filterIssuerTitle,
        filterLabelAll, filterLabel15, filterLabel30, filterLabel60,
        filterLabelDraft, filterLabelIssued, filterLabelActive, 
        filterLabelRevoked, filterLabelCancelled, filterLabelRevocation,
        filterErrorSelectStatus,
        sortLabel,
        sortUpdatedDateDesc,
        sortUpdatedDateAsc,
        sortValidFromDesc,
        sortValidFromAsc,
        sortValidUntilDesc,
        sortValidUntilAsc,
        sortIssuerAsc,
        sortIssuerDesc,
        sortCustomerAsc,
        sortCustomerDesc,
        sortReasonAsc,
        sortReasonDesc,
        sortRemarksAsc,
        sortRemarksDesc,
        sortServiceCodeAsc,
        sortServiceCodeDesc,
        sortServiceNameAsc,
        sortServiceNameDesc,
    }
}

export default TranslationText
