import { Yup } from 'cng-web-lib'
import * as ValidationRegex from './ValidationRegex'

function SetupContract() {
      const mandatoryErrMsg = 'Required/Mandatory'
      const dateErrMsg = 'Invalid date format. Only \"DD MMM YYYY HH:mm\" format is valid.'

      const emojiNotAllowedMsg = "Emoji characters are not allowed"
      const specialCharNotAllowedMsg = "Invalid input. `~$^+=|<> special characters are not allowed"

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return Yup.object({
            contractNo: Yup.string().required(mandatoryErrMsg)
                  .test("contractNo", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                  .test("contractNo", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),
            issuingCompanyId: Yup.number().nullable().required(mandatoryErrMsg),
            customerCompanyId: Yup.number().nullable().required(mandatoryErrMsg),
            validTo: Yup.date().nullable()
                  .validFormat("DD MMM YYYY HH:mm").typeError(dateErrMsg)
                  .required(mandatoryErrMsg)
                  .when("validFrom", {
                        is: value => value && value < today,
                        then: Yup.date().min(today, "Valid to has to be greater than current date."),
                        otherwise: Yup.date().min(Yup.ref('validFrom'), "Valid to has to be greater than valid from"),
                  }),

            validFrom: Yup.date().nullable()
                  .validFormat("DD MMM YYYY HH:mm").typeError(dateErrMsg)
                  .required(mandatoryErrMsg),


            timezone: Yup.number().nullable().required(mandatoryErrMsg)
      })
}

function ProvideRate() {
      const mandatoryErrMsg = 'Required/Mandatory'
      const numberErrMsg = 'Please enter only numeric values from 1 onwards.'
      const diffTaxErrMsg = 'Please select different tax code.'

      const emojiNotAllowedMsg = "Emoji characters are not allowed"
      const specialCharNotAllowedMsg = "Invalid input. `~$^+=|<> special characters are not allowed"
      const startRangeMsg= "Start Range must be a number";
      const unitPriceMsg= "Unit Price must be a number";

      //Yup unable to retrieve parent.parent for validation check
      //So define a object to cross check taxCode with diffTaxCode
      let taxCodeAssigned = {};

      return Yup.object().shape({
            serviceItems: Yup.array().of(
                  Yup.object().shape({
                        serviceCode: Yup.string().required(mandatoryErrMsg)
                              .test("serviceCode", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                              .test("serviceCode", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),
                        serviceName: Yup.string().required(mandatoryErrMsg)
                              .test("serviceName", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                              .test("serviceName", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),
                        categoryId: Yup.number().nullable().required(mandatoryErrMsg),
                        currencyCode: Yup.string().nullable().required(mandatoryErrMsg),
                        taxCode: Yup.string().nullable().required(mandatoryErrMsg)
                              .test("taxCode", 'assign value to variable', function (value) {
                                    const { path } = this
                                    let arrayName = path.split('.')[0] //to get array index
                                    let taxCodeObj = { [arrayName]: value}
                                    Object.assign(taxCodeAssigned, taxCodeObj) //Created object {serviceItems[0] : 'SG-GST'}

                                    return true;
                              }),
                        serviceDesc: Yup.string().nullable()
                              .test("serviceDesc", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                              .test("serviceDesc", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),

                        serviceTnc: Yup.string().nullable()
                              .test("serviceTnc", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                              .test("serviceTnc", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),

                        rateComponent: Yup.array().of(
                              Yup.object().shape({
                                    componentCode: Yup.string().required(mandatoryErrMsg)
                                          .test("componentCode", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                                          .test("componentCode", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),
                                    componentName: Yup.string().required(mandatoryErrMsg)
                                          .test("componentName", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                                          .test("componentName", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),
                                    componentCalById: Yup.number().nullable().required(mandatoryErrMsg),
                                    componentUOMId: Yup.number().nullable().required(mandatoryErrMsg),
                                    componentCalMetId: Yup.number().nullable().required(mandatoryErrMsg),
                                    componentDescription: Yup.string().nullable()
                                          .test("componentDescription", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                                          .test("componentDescription", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),
                                    diffTax: Yup.boolean().nullable(),
                                    diffTaxCode: Yup.string()
                                          .when(['diffTax'], {
                                                is: true,
                                                then: Yup.string().required(mandatoryErrMsg).nullable()
                                                      .test("diffTaxCode", diffTaxErrMsg, function (value) {
                                                            const { path } = this;
                                                            let arrayName = path.split('.')[0] //to get array index, for example serviceItems[0]/serviceItems[1]

                                                            const checkTaxCode = Object.getOwnPropertyDescriptor(taxCodeAssigned, arrayName);
                                                            let same = checkTaxCode.value == value; 

                                                            return !same;
                                                      }),
                                                otherwise: Yup.string().nullable()
                                          }),
                                    rate: Yup.array(
                                          Yup.object({
                                                startRange: Yup.number().min(1, numberErrMsg).required(mandatoryErrMsg).typeError(startRangeMsg)
                                                      .test("startRange", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                                                      .test("startRange", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),  
                                                unitPrice: Yup.number().positive().integer(unitPriceMsg).min(1, numberErrMsg).required(mandatoryErrMsg).typeError(unitPriceMsg)
                                                      .test("unitPrice", emojiNotAllowedMsg, (value) => ValidationRegex.emojiRegx.test(value))
                                                      .test("unitPrice", specialCharNotAllowedMsg, (value) => ValidationRegex.specialCharNotAllowedRegx.test(value)),
                                          }),
                                    ),
                              }),
                        )

                  })
            )
      })
}

export const ContractInfoValidateSchema = [SetupContract, ProvideRate, SetupContract, SetupContract]