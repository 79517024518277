
import {
      Accordion,
      AccordionDetails,
      AccordionSummary,
      Card,
      Divider,
      Grid,
      Typography,
      IconButton
} from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AccordionFooterControlComponent from 'src/views/common/ui/AccordionFooterControlComponet'
import ProvideRateServiceRateCardDetailsComponents from './ProvideRateServiceRateCardDetailsComponents'
import { useWatch } from 'react-hook-form'

const {
      CngGridItem,
} = components


const ProvideRateServiceRateCardComponents = (props) => {

      const index = props.index;
      const rateItemList = useWatch({ name: `serviceItems[${index}].rateComponent` })

      return (
            rateItemList.map((rate, attrIndex) => {
                  let rkey = 'rate_' + attrIndex;
                  return (
                        <Grid container spacing={2} >
                              <CngGridItem xs={12} sm={12}>
                                    <Card>
                                          <Accordion expanded={true} key={rate.accordionId} >
                                                <AccordionSummary aria-controls='rateCardContent' id='rateCardComponentHeader' style={{ backgroundColor: '#f4f4fc', height: 20 }}>
                                                      <Grid container >
                                                            <CngGridItem xs={12} sm={10} style={{ alignSelf: 'center' }}>
                                                                  <Typography variant='h6'>
                                                                        Rate - {attrIndex + 1}
                                                                  </Typography>
                                                            </CngGridItem>
                                                            <CngGridItem xs={12} sm={2} >
                                                                  <Grid container justify="flex-end" >
                                                                        {rateItemList.length == 1 ? <></> :
                                                                              <IconButton aria-label="remove" disabled={false}
                                                                                    style={{ backgroundColor: 'white', padding: 10, borderRadius: 4 }}
                                                                                    onClick={() => props.handleDeleteRateCard(index, attrIndex)}>
                                                                                    <DeleteIcon />
                                                                              </IconButton>
                                                                        }
                                                                        <IconButton aria-label="copy" disabled={false}
                                                                              style={{ backgroundColor: 'white', padding: 10, borderRadius: 4, marginLeft: 5 }}
                                                                              onClick={() => props.handleCopyRateCard(index, rateItemList, attrIndex)}>
                                                                              <FileCopyIcon />
                                                                        </IconButton>
                                                                  </Grid>
                                                            </CngGridItem>

                                                            <Divider />
                                                      </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails >
                                                      <ProvideRateServiceRateCardDetailsComponents

                                                            attrIndex={attrIndex}
                                                            index={index}
                                                            rateComponent={rate}
                                                            {...props}

                                                      />
                                                </AccordionDetails>

                                          </Accordion>
                                          <AccordionFooterControlComponent
                                                style={{ marginBottom: 20 }}
                                                handleAccordionExpand={() => props.handleRateCardDetailsExpand(index, attrIndex, 'rateItemsExpanded')}
                                                expanded={rate.rateItemsExpanded}
                                                footerText={'Other details'}
                                          />
                                    </Card>
                              </CngGridItem>
                        </Grid>
                  )
            })

      )

}


export default ProvideRateServiceRateCardComponents