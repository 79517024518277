import React from 'react'
import ProvideRateComponents from './ProvideRateComponents'
import ContractDetailsComponents from './ContractDetailsComponents'

// Moment library
import Moment from 'moment';
import 'moment-timezone';

const DATETIME_FORMAT = 'DD MMM YYYY HH:mm'

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  ...ContractDetailsComponents.initialValues,
  ...ProvideRateComponents.initialValues,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function _formatDate(timestamp, timezone, format) {
  let moment = Moment(timestamp)

  if (timezone) {
    moment.tz(timezone)
  }

  return (moment.format(format))
}

function FormFields({ history, showNotification, loading, id, data }) {

  return (
    <ProvideRateComponents.FormBody
      key={'provideRateForm'}
      showNotification={showNotification}
      data={data}
      id={id}
    />
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }
  // valid date
  localData.validTo = _formatDate(serverData.validTo, null, DATETIME_FORMAT)
  localData.validFrom = _formatDate(serverData.validFrom, null, DATETIME_FORMAT)

  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}


const ProvideRateForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default ProvideRateForm
