import { Yup, components, useTranslation, constants } from 'cng-web-lib'
import React, { useContext } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import BackButton from 'src/components/button/BackButton.js'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import UpdateContractStatusDialogContext from './UpdateContractStatusDialogContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import { Box } from '@material-ui/core'

const {
  form: {
    CngViewForm,
    field: { CngSelectField, CngTextField }
  }
} = components

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function UpdateContractStatusDialogForm(props) {
  const { translate } = useTranslation(Namespace.SCO_RATE_MGMT)
  const dialogContext = useContext(UpdateContractStatusDialogContext)
  const {
    closeDialog,
    proceedAction,
    statusAction,
    form: { isSubmitting },
  } = dialogContext



  function onSubmit(data) {
    console.log(data);
    proceedAction(statusAction, data.reason, data.remarks)
  }

  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  const validationSchema = Yup.object({
    reason: Yup.number().required(requiredMessage)
  })

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: true
      }}
      renderBodySection={() => (
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}

        />
      )}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ closeDialog, isSubmitting }) {

  return (
    <Box m={1.5}>

      <Box m={1}>
        <CngSelectField
          name='reason'
          label='Reason'
          isRequired
          fetch={
            {
              url: `${SCORateMgmtApiUrls.GET_CODE_MASTER_DROPDOWN}/CEC_CON_REASON`,
              textAccessor: "name",
            }
          }
        />
      </Box>

      <Box m={1}>
        <CngTextField
          type='text'
          name='remarks'
          label='Remarks'
          multiline
          rows={2}
        />
      </Box>

      <Box display='flex' flexDirection={'row-reverse'} justifyContent={'space-between'} mt={3} mx={1}>

        <SubmitButton isSubmitting={isSubmitting}>
          Submit
        </SubmitButton>

        <BackButton onClick={closeDialog} disabled={isSubmitting} />
      </Box>
    </Box>
  )
}

const initialValues = {
  reason: '',
  remarks: ''
}

export default UpdateContractStatusDialogForm
