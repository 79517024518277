
import {
      Grid,
      Typography,
      Chip,
      Box
} from '@material-ui/core'
import React from 'react'
import { useWatch } from 'react-hook-form'
import TranslationText from '../TranslationText'
import { components, constants } from 'cng-web-lib'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import { ChipOption } from './options'
import ProvideRateServiceRateCardPriceComponents from './ProvideRateServiceRateCardPriceComponents'


const {
      form: {
            field: {
                  CngTextField,
                  CngCheckboxField,
                  CngLookupAutocompleteField,
                  CngCountryAutocompleteField
            }
      },
      CngGridItem,
} = components

const {
      filter: { EQUAL }
} = constants


const ProvideRateServiceRateCardDetailsComponents = (props) => {

      const index = props.index;
      const attrIndex = props.attrIndex;
      const styleClasses = props.styleClasses;
      const translatedTextsObject = TranslationText()

      const selectedCalculationBy = useWatch({ name: `serviceItems[${index}].rateComponent[${attrIndex}].componentCalById` })
      const selectedCalculationMethod = useWatch({ name: `serviceItems[${index}].rateComponent[${attrIndex}].componentCalMetId` })
      const selectedUOM = useWatch({ name: `serviceItems[${index}].rateComponent[${attrIndex}].componentUOMId` })
      const selectedDiffTax = useWatch({ name: `serviceItems[${index}].rateComponent[${attrIndex}].diffTaxCode` })


      return (
            <Grid container spacing={2} style={{ padding: 15 }}>
                  
                  <CngGridItem xs={12} sm={4}>
                        <CngTextField
                              name={`serviceItems[${index}].rateComponent[${attrIndex}].componentCode`}
                              label={translatedTextsObject.rateComponentCode}
                              required
                              onChange={(e) => {
                                    props.handleRateInputChange(e, index, attrIndex)
                              }}
                        />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngTextField
                              name={`serviceItems[${index}].rateComponent[${attrIndex}].componentName`}
                              label={translatedTextsObject.rateComponentName}
                              required
                              onChange={(e) => {
                                    props.handleRateInputChange(e, index, attrIndex)
                              }}
                        />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngLookupAutocompleteField
                              key={`serviceItems[${index}].rateComponent[${attrIndex}].componentCalById`}
                              name={`serviceItems[${index}].rateComponent[${attrIndex}].componentCalById`}
                              label={translatedTextsObject.calculationBy}
                              required
                              lookupProps={{
                                    url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                    label: 'name',
                                    value: 'id',
                                    filters: [
                                          {
                                                field: 'codeType',
                                                operator: EQUAL,
                                                value: 'CEC_RATE_CAL_BY'
                                          },
                                          {
                                                field: 'status',
                                                operator: EQUAL,
                                                value: true
                                          }
                                    ]
                              }}
                              renderOption={(option) => (
                                    <ChipOption id={option.data.id} chip={option.data.code} text={option.data.name} />
                              )}

                              onChange={(v, e) => {
                                    props.handleRateDropdownChange(e, index, attrIndex, 'componentCalById')
                              }}
                              textFieldProps={{
                                    InputProps: {
                                          customStartAdornment: (startAdornment, options) => {
                                                const matched = options
                                                      .map((option) => option.data)
                                                      .find((obj) => obj?.id === selectedCalculationBy)
                                                if (matched && selectedCalculationBy) {
                                                      return (
                                                            <Box>
                                                                  <div>
                                                                        <Chip label={<b>{matched.code}</b>} size='small' />
                                                                  </div>
                                                            </Box>
                                                      )
                                                }
                                                return startAdornment
                                          }
                                    }
                              }}
                        />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngLookupAutocompleteField
                              key={`serviceItems[${index}].rateComponent[${attrIndex}].componentUOMId`}
                              name={`serviceItems[${index}].rateComponent[${attrIndex}].componentUOMId`}
                              label={translatedTextsObject.uom}
                              required
                              lookupProps={{
                                    url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                    label: 'name',
                                    value: 'id',
                                    filters: [
                                          {
                                                field: 'codeType',
                                                operator: EQUAL,
                                                value: 'CEC_RATE_UOM_CTNR'
                                          },
                                          {
                                                field: 'status',
                                                operator: EQUAL,
                                                value: true
                                          }
                                    ]
                              }}
                              renderOption={(option) => (
                                    <ChipOption id={option.data.id} chip={option.data.code} text={option.data.name} />
                              )}
                              onChange={(v, e) => {
                                    props.handleRateDropdownChange(e, index, attrIndex, 'componentUOMId')
                              }}
                              textFieldProps={{
                                    InputProps: {
                                          customStartAdornment: (startAdornment, options) => {
                                                const matched = options
                                                      .map((option) => option.data)
                                                      .find((obj) => obj?.id === selectedUOM)
                                                if (matched && selectedUOM) {
                                                      return (
                                                            <Box>
                                                                  <div>
                                                                        <Chip label={<b>{matched.code}</b>} size='small' />
                                                                  </div>
                                                            </Box>
                                                      )
                                                }
                                                return startAdornment
                                          }
                                    }
                              }}
                        />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={4}>
                        <CngLookupAutocompleteField
                              key={`serviceItems[${index}].rateComponent[${attrIndex}].componentCalMetId`}
                              name={`serviceItems[${index}].rateComponent[${attrIndex}].componentCalMetId`}
                              label={translatedTextsObject.calculationMethod}
                              required
                              lookupProps={{
                                    url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                    label: 'name',
                                    value: 'id',
                                    filters: [
                                          {
                                                field: 'codeType',
                                                operator: EQUAL,
                                                value: 'CEC_RATE_CAL_MTD'
                                          },
                                          {
                                                field: 'status',
                                                operator: EQUAL,
                                                value: true
                                          }
                                    ]
                              }}
                              renderOption={(option) => (
                                    <ChipOption id={option.data.id} chip={option.data.code} text={option.data.name} />
                              )}
                              onChange={(v, e) => {
                                    props.handleRateDropdownChange(e, index, attrIndex, 'componentCalMetId')
                              }}
                              textFieldProps={{
                                    InputProps: {
                                          customStartAdornment: (startAdornment, options) => {
                                                const matched = options
                                                      .map((option) => option.data)
                                                      .find((obj) => obj?.id === selectedCalculationMethod)
                                                if (matched && selectedCalculationMethod) {
                                                      return (
                                                            <Box>
                                                                  <div>
                                                                        <Chip label={<b>{matched.code}</b>} size='small' />
                                                                  </div>
                                                            </Box>
                                                      )
                                                }
                                                return startAdornment
                                          }
                                    }
                              }}
                        />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={4} shouldHide={!props.rateComponent.rateItemsExpanded && !props.rateComponent.diffTax}>
                        <Grid container>
                              <Grid container className={styleClasses.checkboxCustom} alignContent="center" alignItems="center" direction="row" xs={12}>
                                    <Grid item xs={10}>
                                          <Typography variant='body2' >{translatedTextsObject.diffTax}</Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                                          <CngCheckboxField
                                                name={`serviceItems[${index}].rateComponent[${attrIndex}].diffTax`}
                                                onChange={(e) => {
                                                      props.handleRateCheckboxChange(e, index, attrIndex, 'diffTax')
                                                }}
                                          />
                                    </Grid>
                              </Grid>
                        </Grid>

                  </CngGridItem>

                  {props.rateComponent.diffTax ?

                        <>
                              <CngGridItem xs={12} sm={6} >
                                    <CngLookupAutocompleteField
                                          key={`serviceItems[${index}].rateComponent[${attrIndex}].diffTaxCode`}
                                          name={`serviceItems[${index}].rateComponent[${attrIndex}].diffTaxCode`}
                                          label={translatedTextsObject.diffTaxCode}
                                          required
                                          lookupProps={{
                                                url: SCORateMgmtApiUrls.GET_TAX,
                                                label: (record) => {
                                                      return `${record.countryCode + ' - ' + record.description}`
                                                },
                                                value: 'code'
                                          }}

                                          renderOption={(option) => (
                                                <ChipOption id={option.data.id} chip={(option.data.taxPercent * 100).toFixed(0) + '%'} text={option.data.countryCode + ' - ' + option.data.description} />
                                          )}
                                          onChange={(v, e) => {
                                                props.handleRateDropdownChange(e, index, attrIndex, 'diffTaxCode');
                                          }}

                                          textFieldProps={{
                                                InputProps: {
                                                      customStartAdornment: (startAdornment, options) => {
                                                            const matched = options
                                                                  .map((option) => option.data)
                                                                  .find((tax) => tax?.code === selectedDiffTax)
                                                            if (matched && selectedDiffTax) {
                                                                  return (
                                                                        <Box>
                                                                              <div>
                                                                                    <Chip label={<b>{(matched.taxPercent * 100).toFixed(0) + '%'}</b>} size='small' />
                                                                              </div>
                                                                        </Box>
                                                                  )
                                                            }
                                                            return startAdornment
                                                      }
                                                }
                                          }}
                                    />
                              </CngGridItem>

                              <CngGridItem xs={12} sm={6}>
                                    <Grid container>
                                          <Grid container className={styleClasses.checkboxCustom} alignContent="center" alignItems="center" direction="row" xs={12}>
                                                <Grid item xs={10}>
                                                      <Typography variant='body2' > {translatedTextsObject.priceIncDiffTax}</Typography>
                                                </Grid>
                                                <Grid item xs={2} style={{ textAlign: 'right' }}>
                                                      <CngCheckboxField
                                                            key={`serviceItems[${index}].rateComponent[${attrIndex}].diffInclTax`}
                                                            name={`serviceItems[${index}].rateComponent[${attrIndex}].diffInclTax`}
                                                            onChange={(e) => props.handleRateCheckboxChange(e, index, attrIndex, 'diffInclTax')}
                                                      />
                                                </Grid>
                                          </Grid>
                                    </Grid>
                              </CngGridItem>
                        </>
                        :
                        <></>

                  }

                  <CngGridItem xs={12} sm={12} shouldHide={!props.rateComponent.rateItemsExpanded}>
                        <CngTextField
                              name={`serviceItems[${index}].rateComponent[${attrIndex}].componentDescription`}
                              label={translatedTextsObject.rateComponentDescription}
                              onChange={(e) => {
                                    props.handleRateInputChange(e, index, attrIndex)
                              }}

                        />
                  </CngGridItem>
                  <ProvideRateServiceRateCardPriceComponents
                        {...props}

                  />
            </Grid>



      )

}


export default ProvideRateServiceRateCardDetailsComponents