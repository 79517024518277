
import {
      Accordion,
      AccordionDetails,
      AccordionSummary,
      Avatar,
      Card,
      Divider,
      Grid,
      Typography,
      Chip,
      makeStyles,
      Box,
      IconButton
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import TranslationText from '../TranslationText'
import { components, constants, useServices } from 'cng-web-lib'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import CommonCityMappingApiUrls from 'src/apiUrls/cec-admin/CommonCityMappingApiUrls'
import { ChipOption, ChipOptionWithEnd } from './options'
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import FooterAddComponent from './FooterAddComponent'
import ProvideRateServiceRateCardComponents from './ProvideRateServiceRateCardComponents';
import {
      faRoad,
      faPlane,
      faShip,
      faQuestion
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const {
      form: {
            adapter: {
                  useFormAdapter: { useField, useFormikContext }
            },
            field: {
                  CngTextField,
                  CngCheckboxField,
                  CngLookupAutocompleteField
            }
      },
      CngGridItem,
} = components

const customStyles = makeStyles(theme => ({
      chip: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.divider,
            fontSize: '.75em',
            minWidth: '5em'
      },
      text: {
            alignContent: 'right',
            fontWeight: 'bold',
            fontSize: '.95em'
      },
      editor: {
            border: '1px solid #EEEEEE  !important',
            padding: '2px 12px',
            borderRadius: '0 0 4px 4px',
            marginTop: '-6px'
      },
      editorTitle: {
            padding: '10px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            border: '1px solid #EEEEEE',
            borderBottom: 0
      },
      checkboxCustom: {
            border: '1px #EEEEEE solid !important',
            padding: '10px 14px',
            borderRadius: '4px',
            height: '58px'
      },
      flags: {
            height: '1.2em', width: '1.2em',
            display: 'inline-block'
      },
      label: {
            overflowWrap: 'anywhere'
      }

}))

const {
      filter: { EQUAL }
} = constants

const ProvideRateServiceComponents = (props) => {

      const { fetchRecords } = useServices();
      const index = props.index;
      const service = props.service;
      const styleClasses = customStyles();
      const translatedTextsObject = TranslationText()
      const selectedCategory = useWatch({ name: `serviceItems[${index}].categoryId` })
      const selectedTax = useWatch({ name: `serviceItems[${index}].taxCode` })
      const selectedCurrency = useWatch({ name: `serviceItems[${index}].currencyCode` })
      const serviceTnc = useWatch({ name: `serviceItems[${index}].serviceTnc` })

      const selectedTransportMode = useWatch({ name: `serviceItems[${index}].transportMode` })
      const selectedFromCountry = useWatch({ name: `serviceItems[${index}].fromCountry` })
      const selectedToCountry = useWatch({ name: `serviceItems[${index}].toCountry` })

      const [fromLoc, setFromLoc] = useState([])
      const [toLoc, setToLoc] = useState([])

      const [editorState, setEditorState] = useState(EditorState.createEmpty());
      useEffect(() => {
            const timeOutId = setTimeout(() => {
                  const plainText = editorState.getCurrentContent().getPlainText()
                  const contentRaw = convertToRaw(editorState.getCurrentContent())
                  if (plainText != '') {
                        props.handleTNCChange(contentRaw, index, 'serviceTnc')
                  }else{
                        props.handleTNCChange(null, index, 'serviceTnc')
                  }
            }, 500);

            return () => clearTimeout(timeOutId);

      }, [editorState]);

      function getIconByTransportMode(code) {
            switch (code) {
                  case 'AIR': return (
                        <FontAwesomeIcon icon={faPlane} size='sm' className='icon-circle-border mr-5' />);
                  case 'LAND': return (
                        <FontAwesomeIcon icon={faRoad} size='sm' className='icon-circle-border mr-5' />);
                  case 'SEA': return (
                        <FontAwesomeIcon icon={faShip} size='sm' className='icon-circle-border mr-5' />);
                  default: return (
                        <FontAwesomeIcon icon={faQuestion} size='sm' className='icon-circle-border mr-5' />)
            }
      }

      const getFromLocation = () => {
            const onSuccess = (response) => {
                  const options = response.content.map((loc) => {
                        return { label: loc.locCode, value: loc.locCode }
                  })
                  setFromLoc(options);
            }
            const onError = (error) => {
                  console.log("Error:[" + JSON.stringify(error) + "]")
            }

            fetchRecords.execute(
                  CommonCityMappingApiUrls.GET,
                  {
                        filters: [
                              {
                                    field: 'countryCode',
                                    operator: EQUAL,
                                    value: selectedFromCountry
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }

      const getToLocation = () => {
            const onSuccess = (response) => {
                  console.log(response)
                  const options = response.content.map((loc) => {
                        return { label: loc.locCode, value: loc.locCode }
                  })
                  setToLoc(options);
            }
            const onError = (error) => {
                  console.log("Error:[" + JSON.stringify(error) + "]")
            }

            fetchRecords.execute(
                  CommonCityMappingApiUrls.GET,
                  {
                        filters: [
                              {
                                    field: 'countryCode',
                                    operator: EQUAL,
                                    value: selectedToCountry
                              }
                        ]
                  },
                  onSuccess,
                  onError
            )
      }

      useEffect(() => {
            if(selectedFromCountry!=null && selectedFromCountry!=''){
                  getFromLocation();
            }else{
                  setFromLoc([])
            }
            
      }, [selectedFromCountry])

      useEffect(() => {
            if(selectedToCountry!=null && selectedToCountry!=''){
                  getToLocation()
            }else{
                  setToLoc([])
            }
           
      }, [selectedToCountry])


      useEffect(() => {
            if (serviceTnc != null && serviceTnc != '') {
                  const json = tryParseJSONObject(serviceTnc)
                  if (!json) {
                        //not valid json
                        const blocksFromHTML = convertFromHTML(serviceTnc)
                        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
                        setEditorState(EditorState.createWithContent(state, null))
                  } else {
                        //should be valid json
                        const contentRaw = convertFromRaw(JSON.parse(serviceTnc))
                        setEditorState(EditorState.createWithContent(contentRaw, null))
                  }
            }

      }, []);

      function tryParseJSONObject(jsonString) {
            try {
                  var o = JSON.parse(jsonString);

                  if (o && typeof o == 'object') {
                        return o;
                  }
            } catch (e) { }
            return false
      }

      return (

            <Grid container spacing={2} >
                  <CngGridItem xs={12} sm={12}>
                        <Card>
                              <Accordion expanded={true} style={{ marginBottom: 20 }} >
                                    <AccordionSummary
                                          aria-controls='rateServiceContent'
                                          id='rateServiceComponentHeader'
                                          // style={{ backgroundColor: '#eff2f04d'}}
                                          style={{ backgroundColor: '#f4f4fc', height: 20 }}
                                    >

                                          <Grid container>
                                                <CngGridItem item xs={12} sm={10} style={{ alignSelf: 'center' }} >
                                                      <Typography variant='h5' >
                                                            Service - {index + 1}
                                                      </Typography>
                                                </CngGridItem>
                                                <CngGridItem xs={12} sm={2}>
                                                      <Grid container justify="flex-end" id={'servicesItem['+{index}+']'}>
                                                            <IconButton aria-label="copy" disabled={false}
                                                                  style={{ backgroundColor: 'white', padding: 10, borderRadius: 4, marginRight: 5 }}
                                                                  onClick={() => props.handleCopyService(index)}>
                                                                  <FileCopyIcon />
                                                            </IconButton>

                                                            <IconButton aria-label="remove" disabled={false}
                                                                  id={index == 0 ? "deleteService0" : "deleteService" + index}
                                                                  style={{ backgroundColor: 'white', padding: 10, borderRadius: 4 }}
                                                                  onClick={() => props.handleDeleteService(index)}>
                                                                  <DeleteIcon />
                                                            </IconButton>


                                                      </Grid>
                                                </CngGridItem>
                                                <Divider />
                                          </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails >

                                          <Grid container spacing={2} style={{ padding: 15 }}>

                                                {/* Service Details */}
                                                <CngGridItem xs={12} sm={4}>
                                                      <CngTextField
                                                            name={`serviceItems[${index}].serviceCode`}
                                                            label={translatedTextsObject.serviceCode}
                                                            onChange={(e) => props.handleInputChange(e, index)}
                                                            required
                                                      />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={4}>
                                                      <CngTextField
                                                            name={`serviceItems[${index}].serviceName`}
                                                            label={translatedTextsObject.serviceName}
                                                            onChange={(e) => props.handleInputChange(e, index)}
                                                            required
                                                      />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={4}>
                                                      <CngLookupAutocompleteField
                                                            key={`serviceItems[${index}].categoryId`}
                                                            name={`serviceItems[${index}].categoryId`}
                                                            label={translatedTextsObject.category}
                                                            required
                                                            lookupProps={{
                                                                  url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                                  label: 'name',
                                                                  value: 'id',
                                                                  filters: [
                                                                        {
                                                                              field: 'codeType',
                                                                              operator: EQUAL,
                                                                              value: 'CEC_RATE_CATEGORY'
                                                                        }
                                                                  ]
                                                            }}
                                                            renderOption={(option) => (
                                                                  <ChipOption id={option.data.id} chip={option.data.code} text={option.data.name} />
                                                            )}
                                                            onChange={(e) => { props.handleDropdownChange(e, index, 'categoryId') }}
                                                            textFieldProps={{
                                                                  InputProps: {
                                                                        customStartAdornment: (startAdornment, options) => {
                                                                              const matched = options
                                                                                    .map((option) => option.data)
                                                                                    .find((category) => category?.id === selectedCategory)
                                                                              if (matched && selectedCategory) {
                                                                                    return (
                                                                                          <Box>
                                                                                                <div>
                                                                                                      <Chip label={<b>{matched.code}</b>} size='small' />
                                                                                                </div>
                                                                                          </Box>
                                                                                    )
                                                                              }
                                                                              return startAdornment
                                                                        }
                                                                  }
                                                            }}
                                                      />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={4}>
                                                      <CngLookupAutocompleteField
                                                            key={`serviceItems[${index}].currencyCode`}
                                                            name={`serviceItems[${index}].currencyCode`}
                                                            label={translatedTextsObject.currency}
                                                            required
                                                            lookupProps={{
                                                                  url: SCORateMgmtApiUrls.GET_CURRENCY,
                                                                  label: 'name',
                                                                  value: 'code',
                                                                  filters: [
                                                                        {
                                                                              field: 'status',
                                                                              operator: EQUAL,
                                                                              value: true
                                                                        }
                                                                  ]
                                                            }}
                                                            renderOption={(option) => (
                                                                  <ChipOptionWithEnd id={option.data.id} chip={option.data.code} text={option.data.name} end={option.data.symbol} />
                                                            )}
                                                            onChange={(e) => { props.handleDropdownChange(e, index, 'currencyCode') }}
                                                            textFieldProps={{
                                                                  InputProps: {
                                                                        customStartAdornment: (startAdornment, options) => {
                                                                              const matched = options
                                                                                    .map((option) => option.data)
                                                                                    .find((currency) => currency?.code === selectedCurrency)
                                                                              if (matched && selectedCurrency) {
                                                                                    return (
                                                                                          <Box>
                                                                                                <div>
                                                                                                      <Chip label={<b>{matched.code}</b>} size='small' />
                                                                                                </div>
                                                                                          </Box>
                                                                                    )
                                                                              }
                                                                              return startAdornment
                                                                        },
                                                                        customEndAdornment: (endAdornment, options) => {
                                                                              const matched = options
                                                                                    .map((option) => option.data)
                                                                                    .find((currency) => currency?.code === selectedCurrency)
                                                                              if (matched && selectedCurrency) {
                                                                                    return (
                                                                                          <div>
                                                                                                <Box className={styleClasses.text} color='text.secondary'>
                                                                                                      {matched.symbol}
                                                                                                </Box>
                                                                                                {endAdornment}
                                                                                          </div>
                                                                                    )
                                                                              }
                                                                              return endAdornment
                                                                        }
                                                                  }
                                                            }}
                                                      />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={4}>
                                                      <CngLookupAutocompleteField
                                                            key={`serviceItems[${index}].taxCode`}
                                                            name={`serviceItems[${index}].taxCode`}
                                                            label={translatedTextsObject.taxPercentage}
                                                            required
                                                            lookupProps={{
                                                                  url: SCORateMgmtApiUrls.GET_TAX,
                                                                  label: (record) => {
                                                                        return `${record.countryCode + ' - ' + record.description}`
                                                                  },
                                                                  value: 'code'
                                                            }}

                                                            renderOption={(option) => (
                                                                  <ChipOption id={option.data.id} chip={(option.data.taxPercent * 100).toFixed(0) + '%'} text={option.data.countryCode + ' - ' + option.data.description} />
                                                            )}
                                                            onChange={(e) => { props.handleDropdownChange(e, index, 'taxCode') }}
                                                            textFieldProps={{
                                                                  InputProps: {
                                                                        customStartAdornment: (startAdornment, options) => {
                                                                              const matched = options
                                                                                    .map((option) => option.data)
                                                                                    .find((tax) => tax?.code === selectedTax)
                                                                              if (matched && selectedTax) {
                                                                                    return (
                                                                                          <Box>
                                                                                                <div>
                                                                                                      <Chip label={<b>{(matched.taxPercent * 100).toFixed(0) + '%'}</b>} size='small' />
                                                                                                </div>
                                                                                          </Box>
                                                                                    )
                                                                              }
                                                                              return startAdornment
                                                                        }
                                                                  }
                                                            }}
                                                      />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={4}>
                                                      <Grid container>
                                                            <Grid container className={styleClasses.checkboxCustom} alignContent="center" alignItems="center" direction="row" xs={12}>
                                                                  <Grid item xs={10}>
                                                                        <Typography variant='body2' >{translatedTextsObject.priceIncTax}</Typography>
                                                                  </Grid>
                                                                  <Grid item xs={2} style={{ textAlign: 'right' }}>
                                                                        <CngCheckboxField
                                                                              name={`serviceItems[${index}].inclTax`}
                                                                              onChange={(e) => props.handleInputChange(e, index)}
                                                                        />
                                                                  </Grid>
                                                            </Grid>
                                                      </Grid>
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={4}>
                                                      <CngLookupAutocompleteField
                                                            key={`serviceItems[${index}].transportMode`}
                                                            name={`serviceItems[${index}].transportMode`}
                                                            label={translatedTextsObject.transportMode}
                                                            lookupProps={{
                                                                  url: SCOAdminApiUrls.GET_CODE_MASTER_LIST,
                                                                  label: 'name',
                                                                  value: 'code',
                                                                  filters: [
                                                                        {
                                                                              field: 'codeType',
                                                                              operator: EQUAL,
                                                                              value: 'CEC_TRANSPORT_MODE'
                                                                        },
                                                                        {
                                                                              field: 'status',
                                                                              operator: EQUAL,
                                                                              value: true
                                                                        }
                                                                  ]
                                                            }}
                                                            renderOption={(option) => (
                                                                  <>{getIconByTransportMode(option.data.code)} {option.data.name}</>
                                                            )}

                                                            onChange={(v, e) => {
                                                                  props.handleDropdownChange(e, index, 'transportMode')
                                                            }}
                                                            textFieldProps={{
                                                                  InputProps: {
                                                                        customStartAdornment: (startAdornment, options) => {
                                                                              const matched = options
                                                                                    .map((option) => option.data)
                                                                                    .find((obj) => obj?.code === selectedTransportMode)
                                                                              if (matched && selectedTransportMode) {
                                                                                    return (
                                                                                          <Box>
                                                                                                <div>
                                                                                                      {getIconByTransportMode(matched.code)}
                                                                                                </div>
                                                                                          </Box>
                                                                                    )
                                                                              }
                                                                              return startAdornment
                                                                        }
                                                                  }
                                                            }}
                                                      />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={4}>
                                                      <CngLookupAutocompleteField
                                                            name={`serviceItems[${index}].fromCountry`}
                                                            label={translatedTextsObject.fromCountry}
                                                            lookupProps={{
                                                                  url: SCOAdminApiUrls.GET_COUNTRY_LIST,
                                                                  label: 'name',
                                                                  value: 'code'
                                                            }}
                                                            renderOption={item => (<>

                                                                  <Box ml={-1.75} mr={.75}>
                                                                        <Avatar className={styleClasses.flags}
                                                                              src={`${process.env.PUBLIC_URL}/static/images/countries/${item.value}.svg`}
                                                                              alt={`${item.label} flag`}
                                                                        >{item.value}</Avatar>
                                                                  </Box>
                                                                  <Box className={styleClasses.label}>{item.label || item.value}</Box>
                                                            </>)}
                                                            onChange={(e) => {
                                                                  props.handleDropdownChange(e, index, 'fromCountry')
                                                                  props.setValue(`serviceItems[${index}].fromLoc`,'')
                                                            }}
                                                            textFieldProps={{
                                                                  InputProps: {
                                                                        customStartAdornment: (startAdornment, options) => {
                                                                              const matched = options
                                                                                    .map((option) => option.data)
                                                                                    .find((obj) => obj?.code === selectedFromCountry)
                                                                              if (matched && selectedFromCountry) {
                                                                                    return (
                                                                                          <Avatar className={styleClasses.flags}
                                                                                                src={`${process.env.PUBLIC_URL}/static/images/countries/${selectedFromCountry}.svg`}
                                                                                                alt={`${selectedFromCountry} flag`}
                                                                                          />
                                                                                    )
                                                                              }
                                                                              return startAdornment
                                                                        }
                                                                  }
                                                            }}
                                                      />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={4}>
                                                      <CngLookupAutocompleteField
                                                            name={`serviceItems[${index}].toCountry`}
                                                            label={translatedTextsObject.toCountry}
                                                            lookupProps={{
                                                                  url: SCOAdminApiUrls.GET_COUNTRY_LIST,
                                                                  label: 'name',
                                                                  value: 'code'
                                                            }}
                                                            renderOption={item => (<>
                                                                  <Box ml={-1.75} mr={.75}>
                                                                        <Avatar className={styleClasses.flags}
                                                                              src={`${process.env.PUBLIC_URL}/static/images/countries/${item.value}.svg`}
                                                                              alt={`${item.label} flag`}
                                                                        >{item.value}</Avatar>
                                                                  </Box>
                                                                  <Box className={styleClasses.label}>{item.label || item.value}</Box>
                                                            </>)}
                                                            onChange={(e) => {
                                                                  props.handleDropdownChange(e, index, 'toCountry');
                                                                  props.setValue(`serviceItems[${index}].toLoc`,'')
                                                            }}
                                                            textFieldProps={{
                                                                  InputProps: {
                                                                        customStartAdornment: (startAdornment, options) => {
                                                                              const matched = options
                                                                                    .map((option) => option.data)
                                                                                    .find((obj) => obj?.code === selectedToCountry)
                                                                              if (matched && selectedToCountry) {
                                                                                    return (
                                                                                          <Avatar className={styleClasses.flags}
                                                                                                src={`${process.env.PUBLIC_URL}/static/images/countries/${selectedToCountry}.svg`}
                                                                                                alt={`${selectedToCountry} flag`}
                                                                                          />
                                                                                    )
                                                                              }
                                                                              return startAdornment
                                                                        }
                                                                  }
                                                            }}
                                                      />
                                                </CngGridItem>


                                                <CngGridItem xs={12} sm={6}>
                                                      <CngLookupAutocompleteField
                                                            key={`serviceItems[${index}].fromLoc`}
                                                            name={`serviceItems[${index}].fromLoc`}
                                                            label={translatedTextsObject.fromLoc}
                                                            options= {fromLoc}
                                                            onChange={(e) => {
                                                                  props.handleDropdownChange(e, index, 'fromLoc');
                                                                  setFromLoc([])
                                                            }}
                                                            lookupProps={{
                                                                  url: CommonCityMappingApiUrls.GET,
                                                                  label: 'locCode',
                                                                  value: 'locCode',
                                                                  filters: [
                                                                        {
                                                                              field: 'countryCode',
                                                                              operator: EQUAL,
                                                                              value: selectedFromCountry
                                                                        }
                                                                  ]
                                                            }}
                                                      />
                                                     
                                                </CngGridItem>
                                                <CngGridItem xs={12} sm={6}>
                                                      <CngLookupAutocompleteField
                                                            key={`serviceItems[${index}].toLoc`}
                                                            name={`serviceItems[${index}].toLoc`}
                                                            label={translatedTextsObject.toLoc}
                                                            options= {toLoc}
                                                            onChange={(e) => {
                                                                  props.handleDropdownChange(e, index, 'toLoc')
                                                            }}
                                                            lookupProps={{
                                                                  url: CommonCityMappingApiUrls.GET,
                                                                  label: 'locCode',
                                                                  value: 'locCode',
                                                                  filters: [
                                                                        {
                                                                              field: 'countryCode',
                                                                              operator: EQUAL,
                                                                              value: selectedToCountry
                                                                        }
                                                                  ]
                                                            }}
                                                      />
                                                      
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={12}>
                                                      <CngTextField
                                                            name={`serviceItems[${index}].serviceDesc`}
                                                            label={translatedTextsObject.serviceDescription}
                                                            onChange={(e) => props.handleInputChange(e, index)}
                                                      />
                                                </CngGridItem>

                                                <CngGridItem xs={12} sm={12}>
                                                      <Box className={styleClasses.editorTitle}>
                                                            {translatedTextsObject.serviceTnc}
                                                      </Box>

                                                      <Editor
                                                            editorClassName={styleClasses.editor}
                                                            editorState={editorState}
                                                            onEditorStateChange={setEditorState}
                                                      />
                                                </CngGridItem>

                                                {/* Rate Card Component */}
                                                <CngGridItem xs={12} sm={12}>
                                                      <Card>
                                                            <Box pl={1.5} my={1.5}>

                                                                  <Grid container style={{ padding: 5, display: 'block' }}>

                                                                        <CngGridItem xs={12} sm={12} style={{ alignSelf: 'center' }} >
                                                                              <Typography variant='h5' >
                                                                                    Rate Card
                                                                              </Typography>
                                                                        </CngGridItem>
                                                                        <CngGridItem xs={12} sm={12} style={{ alignSelf: 'center', marginTop: 10, width: '98.5%' }} >
                                                                              <ProvideRateServiceRateCardComponents

                                                                                    index={index}
                                                                                    rateItemList={service.rateComponent}
                                                                                    styleClasses={styleClasses}
                                                                                    {...props}
                                                                              />
                                                                        </CngGridItem>

                                                                        <CngGridItem xs={12} sm={12} style={{ margin: '20px 0 10px -10px' }}>
                                                                              <FooterAddComponent
                                                                                    handleAddChild={() => props.handleAddRateCard(index, service.rateComponent)}
                                                                                    footerText='Add another rate'
                                                                              />
                                                                        </CngGridItem>

                                                                  </Grid>
                                                            </Box>
                                                      </Card>
                                                </CngGridItem>
                                          </Grid>
                                    </AccordionDetails>
                              </Accordion>
                        </Card>
                  </CngGridItem>
            </Grid>
      )
}

export default ProvideRateServiceComponents