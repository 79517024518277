import React from 'react'
import ServiceDetailsComponents from './ServiceDetailsComponents'
// Moment library
import Moment from 'moment';
import 'moment-timezone';

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id:'',
  ...ServiceDetailsComponents.initialValues
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function _formatDate(timestamp, timezone, format) {
  let moment = Moment(timestamp)

  if (timezone) {
      moment.tz(timezone)
  }

  return (moment.format(format))
}

const DATETIME_FORMAT = 'DD MMM YYYY HH:mm'

function FormFields({ history, showNotification, loading, id, data }) {

  return (
    <>
      <ServiceDetailsComponents.FormBody
        showNotification={showNotification}
        data={data}
        id={id}
      />

    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }
  // valid date
  localData.validTo = _formatDate(serverData.validTo, null, DATETIME_FORMAT)
  localData.validFrom = _formatDate(serverData.validFrom, null, DATETIME_FORMAT)

  return localData;
}

function toServerDataFormat(localData) {
  return localData;
}

const FinaliseDetailsForm = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FinaliseDetailsForm
