import React, { useState, useEffect } from 'react'
import { Checkbox, CardContent, Grid, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import TranslationText from '../../TranslationText'
import Utils from '../Utils'

function StatusFilter({
    clearAllFilterClickedTime,
    selectedTab,
    onChange
}) {
    //* Styles -----------------------------------------------------------------
    const useStyles = makeStyles({
        truncate: { 
            width: '100% !important',
            '& .MuiTypography-body1':{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }
    });

    const classes = useStyles()
    const translatedTextObject = TranslationText()

    //* ------------------------------------------------------------------------
    const DEFAULT_CHECKED_VALUES = { ALL: true }
    const [checkedValues, setCheckedValues] = useState({ ...DEFAULT_CHECKED_VALUES })

    // The 'ALL' checkbox need special handling.
    const allCheckboxChangeHandler = (event) => {
        let currentChecked = checkedValues.ALL

        // Uncheck everything else, and change the current.
        let newCheckedValues = { ...DEFAULT_CHECKED_VALUES }
        newCheckedValues.ALL = !(currentChecked)
        setCheckedValues(newCheckedValues)
    }

    // The other checkboxes
    const checkboxChangeHandler = (event) => {
        let checkboxValue = event.target.value

        let currentChecked = (checkedValues[checkboxValue])

        let newCheckedValues = { ...checkedValues }
        newCheckedValues[checkboxValue] = !(currentChecked)
        setCheckedValues(newCheckedValues)
    }

    // Monitor the Clear All clicked time. When changed, reset the value.
    useEffect(() => {
        setCheckedValues({ ...DEFAULT_CHECKED_VALUES })
    }, [clearAllFilterClickedTime])

    useEffect(() => {

        // Trigger the onChange when checkedValues are changed.
        if (onChange) {
            let selectedValues = []

            for (let [key, value] of Object.entries(checkedValues)) {
                if (value) {
                    selectedValues.push(key)
                }
            }
            onChange(selectedValues)
        }
    }, [checkedValues])

    //* return JSX -------------------------------------------------------------
    return (

        //* Start JSX ----------------------------------------------------------
        <Grid container>
            <Grid item xs={12}>
                <CardContent style={{
                    background: '#8996AF10',
                    padding: '1em',
                    marginTop: '1em',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }}>
                    {translatedTextObject.filterStatusTitle}
                </CardContent>
            </Grid>
            <Grid item xs={12}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControlLabel label={translatedTextObject.filterLabelAll}
                                control={
                                    <Checkbox
                                        checked={checkedValues.ALL}
                                        onChange={allCheckboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>
                        {selectedTab == Utils.Tab.CONTRACT ?
                            <Grid item xs={12} xl={6}>
                                <FormControlLabel className={classes.truncate} label={translatedTextObject.filterLabelDraft}
                                    disabled={(checkedValues.ALL)}
                                    control={
                                        <Checkbox value='DRFT'
                                            checked={(checkedValues.ALL) || (checkedValues.DRFT)}
                                            onChange={checkboxChangeHandler}
                                        />
                                    }
                                />
                            </Grid>
                            :
                            <></>}

                        <Grid item xs={12} xl={6}>
                            <FormControlLabel className={classes.truncate} label={translatedTextObject.filterLabelRevoked}
                                disabled={(checkedValues.ALL)}
                                control={
                                    <Checkbox value='REVK'
                                        checked={(checkedValues.ALL) || (checkedValues.REVK)}
                                        onChange={checkboxChangeHandler}
                                    />
                                } />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <FormControlLabel className={classes.truncate} label={translatedTextObject.filterLabelIssued}
                                disabled={(checkedValues.ALL)}
                                control={
                                    <Checkbox value='ISSU'
                                        checked={(checkedValues.ALL) || (checkedValues.ISSU)}
                                        onChange={checkboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <FormControlLabel className={classes.truncate} label={translatedTextObject.filterLabelCancelled}
                                disabled={(checkedValues.ALL)}
                                control={
                                    <Checkbox value='CANC'
                                        checked={(checkedValues.ALL) || (checkedValues.CANC)}
                                        onChange={checkboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <FormControlLabel className={classes.truncate} label={translatedTextObject.filterLabelActive}
                                disabled={(checkedValues.ALL)}
                                control={
                                    <Checkbox value='ACTV'
                                        checked={(checkedValues.ALL) || (checkedValues.ACTV)}
                                        onChange={checkboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <FormControlLabel className={classes.truncate} label={translatedTextObject.filterLabelRevocation}
                                disabled={(checkedValues.ALL)}
                                control={
                                    <Checkbox value='RVRQ'
                                        checked={(checkedValues.ALL) || (checkedValues.RVRQ)}
                                        onChange={checkboxChangeHandler}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Grid>
        </Grid>
        //* End JSX ------------------------------------------------------------
    );
    //* End of function --------------------------------------------------------
}

export default StatusFilter
