const Tab ={
    CONTRACT : 'CONTRACT',
    CONTRACT_REC : 'CONTRACT_REC'
}

const PublicRateTab ={
    PUBLIC_RATE : 'PUBLIC_RATE',
    PREFERENTIAL_RATE : 'PREFERENTIAL_RATE'
}

const Action = {
    accept: 'approveRevoke',
    activate: 'activate',
    cancel: 'cancel',
    delete: 'delete',
    edit: 'edit',
    issue: 'issue',
    negotiate: 'negotiate',
    reject: 'rejectRevoke',
    requestRevocation: 'requestRevoke',

    //Create Edit Page Discard & Back action
    back: 'back',
    discard: 'discard'
}

const Status = [
    {
        name: 'Draft',
        color: '#9698D64D',
        actions: [Action.edit, Action.issue, Action.delete],
        actionsWithSeq: [Action.delete, Action.issue, Action.edit],
        actionAllowedTab: [Tab.CONTRACT],
        actionAllowedParty: ['ISSUER']

    },
    {
        name: 'Issued',
        color: '#F2C05B4D',
        actions: [Action.activate, Action.negotiate, Action.cancel],
        actionsWithSeq: [Action.cancel, Action.negotiate, Action.activate],
        actionAllowedTab: [Tab.CONTRACT_REC],
        actionAllowedParty: ['CUSTOMER']

    },
    {
        name: 'Active',
        color: '#7CE7AC4D',
        actions: [Action.requestRevocation],
        actionsWithSeq: [Action.requestRevocation],
        actionAllowedTab: [Tab.CONTRACT,Tab.CONTRACT_REC],
        actionAllowedParty: ['ISSUER', 'CUSTOMER']

    },
    {
        name: 'Revocation Requested',
        color: '#5E81F44D',
        actions: [Action.accept, Action.reject],
        actionsWithSeq: [Action.reject, Action.accept],
        actionAllowedTab: [Tab.CONTRACT, Tab.CONTRACT_REC],
        actionAllowedParty: ['ISSUER', 'CUSTOMER']

    },
    {
        name: 'Revoked',
        color: '#F578B24D',
        actions: [],
        actionsWithSeq: [],
        actionAllowedTab:[],
        actionAllowedParty: []

    },
    {
        name: 'Cancelled',
        color: '#1C1D214D',
        actions: [],
        actionsWithSeq: [],
        actionAllowedTab:[],
        actionAllowedParty: []

    },
]

function getStatusById(id) {
    return Status.find((b) => b.id === id)
}

function getStatusByName(name) {
    return Status.find((b) => b.name === name)
}


const Utils = Object.freeze({
    Tab,
    PublicRateTab,
    Action,
    Status,
    getStatusById,
    getStatusByName
  })

export default Utils
  