import { Grid } from '@material-ui/core'
import React from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import TranslationText from '../TranslationText'
import { components } from 'cng-web-lib'
import SCOJobApiUrls from 'src/apiUrls/SCOJobApiUrls'
import CecAccountApiUrls from 'src/apiUrls/CecAccountApiUrls'

const {
      form: {
            field: {
                  CngTextField,
                  CngDateTimeField,
                  CngLookupAutocompleteField
            }
      },
      CngGridItem,
} = components

const initialValues = Object.freeze({
      contractId: "",
      contractNo: "",
      issuingCompanyId: "",
      customerCompanyId: "",
      validFrom: "",
      validTo: "",
      timezone: ""
})

const FormBody = props => {
      const translatedTextsObject = TranslationText()

      return (
            <Grid container style={{ padding: 15 }}>
                  <AccordionHeaderComponent title={translatedTextsObject.contractInfoSubTitle} />
                  <Grid container spacing={2} style={{ padding: 5 }}>

                        <CngGridItem xs={12} sm={4}>
                              <CngTextField
                                    name='contractNo'
                                    label={translatedTextsObject.contractNo}
                                    required
                              />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={4}>
                              <CngLookupAutocompleteField
                                    name='issuingCompanyId'
                                    label={translatedTextsObject.issuer}
                                    lookupProps={{
                                          url: CecAccountApiUrls.GET_ACCOUNT_ISSUER,
                                          label: 'displayName',
                                          value: 'id'
                                    }}
                                    required
                              />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={4}>
                              <CngLookupAutocompleteField
                                    name='customerCompanyId'
                                    label={translatedTextsObject.customer}
                                    lookupProps={{
                                          url: CecAccountApiUrls.GET_ACCOUNT_CUSTOMER,
                                          label: 'displayName',
                                          value: 'id'
                                    }}
                                    required
                              />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={4}>
                              <CngDateTimeField
                                    name='validFrom'
                                    label={translatedTextsObject.validFrom}
                                    format={props.DATETIME_FORMAT}
                                    required
                              />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={4}>
                              <CngDateTimeField
                                    name='validTo'
                                    label={translatedTextsObject.validTo}
                                    format={props.DATETIME_FORMAT}
                                    required
                              />
                        </CngGridItem>

                        <CngGridItem xs={12} sm={4}>
                              <CngLookupAutocompleteField
                                    name='timezone'
                                    label={translatedTextsObject.timezone}
                                    lookupProps={{
                                          url: SCOJobApiUrls.GETTIMEZONE,
                                          label: 'name',
                                          value: 'id'
                                    }}
                                    required
                              />
                        </CngGridItem>
                  </Grid>
            </Grid>

      )

}

const ContractDetailsComponents = Object.freeze({
      FormBody: FormBody,
      initialValues: initialValues
})

export default ContractDetailsComponents