import React, { useState } from 'react'
import { Typography, Button } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import { useHistory } from 'react-router-dom'
import pathMap from 'src/paths/PathMap_SCO_RateMgmt'
import Utils from '../landing-page/Utils'
import SCORateMgmtApiUrls from 'src/apiUrls/SCORateMgmtApiUrls'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import UpdateContractStatusDialog from '../landing-page/UpdateContractStatusDialog'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fal as FontAwesomeRegular } from '@fortawesome/pro-light-svg-icons'
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core'

FontAwesomeLibrary.add(FontAwesomeRegular)

const {
  table: { useDefaultNotification }
} = components

function ViewActionList(props) {

  const history = useHistory()
  const { details, showNotification, refreshList, selectedTab } = props
  const getPartyID = () => (JSON.parse(localStorage.getItem('userPreference')) || {}).partyId
  const partyId = getPartyID();

  let action = details.status ? Utils.getStatusByName(details.status).actionsWithSeq : []
  if (action.length > 0) {
    //check on partyId
    let returnAction = false;
    if ((Utils.getStatusByName(details.status).actionAllowedParty.includes('ISSUER') && details.issuerPartyId == partyId) ||
      (Utils.getStatusByName(details.status).actionAllowedParty.includes('CUSTOMER') && details.customerPartyId == partyId)) {
      returnAction = true;
    }

    if (!returnAction) {
      action = []
    }
  }
  const { createRecord } = useServices()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmAction, setConfirmAction] = useState(Utils.Action.delete) //need to initiable a value

  const [statusDialogOpen, setStatusDialogOpen] = useState(false)
  const [statusAction, setStatusAction] = useState(Utils.Action.issue) //need to initiable a value

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const ConfirmMessage = [
    {
      action: Utils.Action.delete,
      title: "Do you want to delete this item?",
      content: "Items that you delete can't be restored.",
      okMsg: "Yes, delete",
      cancelMsg: "No, take me back"
    }
  ]

  const StatusTitle = [
    {
      action: Utils.Action.edit,
      title: "Edit",
      label: "Edit",
      iconName: "fa-pen-to-square",
      successNotification: "Go to Edit page",
      variant: "contained",
      class: { root: 'ng-button-filled-primary' }

    },
    {
      action: Utils.Action.delete,
      title: "Delete",
      label: "Delete",
      iconName: "fa-trash",
      successNotification: "Item deleted permanently.",
      variant: "outlined",
      class: { root: 'ng-button-outline text-danger' }
    },
    {
      action: Utils.Action.issue,
      title: "Issue contract",
      label: "Issue",
      iconName: "fa-arrow-right-from-bracket",
      successNotification: "Item issued.",
      variant: "outlined",
      class: { root: 'ng-button-outline text-primary' }
    },
    {
      action: Utils.Action.activate,
      title: "Activate contract",
      label: "Activate",
      iconName: "fa-circle-check",
      successNotification: "Item activated.",
      variant: "contained",
      class: { root: 'ng-button-filled-primary' }
    },
    {
      action: Utils.Action.negotiate,
      title: "Request negotiate",
      label: "Negotiate",
      iconName: "fa-arrows-rotate",
      successNotification: "Item send for negotiation.",
      variant: "outlined",
      class: { root: 'ng-button-outline text-primary' }
    },
    {
      action: Utils.Action.cancel,
      title: "Cancel contract",
      label: "Cancel",
      iconName: "fa-ban",
      successNotification: "Item cancelled.",
      variant: "outlined",
      class: { root: 'ng-button-outline text-danger' }
    },
    {
      action: Utils.Action.requestRevocation,
      title: "Request revocation",
      label: "Request revocation",
      iconName: "fa-reply-clock",
      successNotification: "Item send for revocation.",
      variant: "contained",
      class: { root: 'ng-button-filled-primary' }
    },
    {
      action: Utils.Action.accept,
      title: "Accept revocation",
      label: "Accept",
      iconName: "fa-circle-check",
      successNotification: "Item revocation accepted.",
      variant: "contained",
      class: { root: 'ng-button-filled-primary' }
    },
    {
      action: Utils.Action.reject,
      title: "Reject revocation",
      label: "Reject",
      iconName: "fa-circle-xmark",
      successNotification: "Item revocation rejected.",
      variant: "outlined",
      class: { root: 'ng-button-outline text-danger' }
    },
  ]

  function getDialogMessageByAction(actionVal) {
    return ConfirmMessage.find((b) => b.action == actionVal)
  }

  function getDetailsByAction(actionVal) {
    return StatusTitle.find((b) => b.action == actionVal)
  }

  const deleteRecord = () => {

    setConfirmAction(Utils.Action.delete)
    setConfirmDialogOpen(true)
  }

  const confirmDialog = () => {

    setConfirmDialogOpen(false) //close the dialog first
    proceedDelete()
  }

  const proceedDelete = () => {

    function onSuccess(response) {

      if (response.errorMessages != null && response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(getDetailsByAction(Utils.Action.delete).successNotification)
        history.push(pathMap.SCO_RATE_CONTRACT_LIST_VIEW)
      }
    }

    createRecord.execute(
      SCORateMgmtApiUrls.HDR_DELETE_CONTRACT,
      { contractId: details.contractId },
      onSuccess,
      onError
    )
  }

  const updateRecordStatus = (actionVal) => {

    setStatusAction(actionVal)
    setStatusDialogOpen(true)
  }

  const confirmUpdateStatusDialog = (confirmActionVal, reason, remarks) => {
    setStatusDialogOpen(false) //close the dialog first
    proceedUpdateStatus(confirmActionVal, reason, remarks)
  }

  const proceedUpdateStatus = (confirmActionVal, reason, remarks) => {

    function onSuccess(response) {

      if (response.errorMessages != null && response.errorMessages.length > 0) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(getDetailsByAction(confirmActionVal).successNotification)
        refreshList()
      }
    }

    createRecord.execute(
      SCORateMgmtApiUrls.HDR_UPDATE_CONTRACT_STATUS,
      {
        contractId: details.contractId,
        action: confirmActionVal,
        reasonId: reason,
        remarks: remarks,
        selectedTab: selectedTab
      },
      onSuccess,
      onError
    )
  }

  const gotoEdit = () => {
    const editUrl = pathMap.SCO_RATE_CONTRACT + "/edit/" + details.contractId;
    history.push(editUrl);
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    showErrorNotification(error.message)
  }

  const handleMenuItemClick = (event, actionVal) => {

    if (actionVal == Utils.Action.delete) {
      deleteRecord();
    } else if (actionVal == Utils.Action.edit) {
      gotoEdit();
    } else {
      updateRecordStatus(actionVal)
    }

  };

  return (
    <div>

      {action.map((actionObj) => (
        <>
          <Button onClick={(event) => handleMenuItemClick(event, actionObj)}
            style={{ marginLeft: 2 }}
            variant={getDetailsByAction(actionObj).variant} classes={getDetailsByAction(actionObj).class}>
            <Typography variant="inherit"><FontAwesomeIcon icon={['fal', getDetailsByAction(actionObj).iconName]} /> {getDetailsByAction(actionObj).label}</Typography>
          </Button>
        </>
      ))}
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={confirmDialog}
        content={getDialogMessageByAction(confirmAction).content}
        okMsg={getDialogMessageByAction(confirmAction).okMsg}
        cancelMsg={getDialogMessageByAction(confirmAction).cancelMsg}
        title={getDialogMessageByAction(confirmAction).title}
      />
      <UpdateContractStatusDialog
        isDialogOpen={statusDialogOpen}
        closeDialog={() => setStatusDialogOpen(false)}
        dialogTitle={getDetailsByAction(statusAction).title}
        proceedAction={confirmUpdateStatusDialog}
        statusAction={statusAction}
      />

    </div>
  )
}

export default ViewActionList
